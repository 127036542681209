import { useEffect, useState, useMemo, useCallback } from "react";
import micImage from "../../../assets/voiceToText/mic.gif";
import micSlashImage from "../../../assets/voiceToText/mic-slash.gif";
import micAnimateImage from "../../../assets/voiceToText/mic-animate.gif";

let ignore_onend = false;
let interimTranscript = "";
let timer: any;
let recognizing = false;

export const useVoiceToText = () => {
  const [img, setImg] = useState<string>(micImage);
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState<boolean>(false);
  const recognition = useMemo(
    () =>
      (window as any).webkitSpeechRecognition &&
      new (window as any).webkitSpeechRecognition(),
    []
  );
  const complete = useCallback(() => {
    if (isListening) {
      recognition.stop();
      setTranscript(interimTranscript);
      return;
    }
  }, [isListening, recognition]);

  const start = useCallback(
    (ev: any) => {
      if (recognizing) {
        recognition.stop();
        return;
      }
      setTranscript("");
      recognition.lang = "English";
      recognition.start();
      ignore_onend = false;
    },
    [recognition]
  );

  useEffect(() => {
    if (!recognition) {
      setImg(micSlashImage);
    } else {
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.onstart = function () {
        setIsListening(true);
        setImg(micAnimateImage);
        //  timer = setTimeout(complete, 5000);
      };
      recognition.onerror = function (event: any) {
        ignore_onend = true;
        if (event.error === "no-speech") {
          setImg(micImage);
        }
      };
      recognition.onend = function () {
        setIsListening(false);
        if (ignore_onend) {
          return;
        }
        setImg(micImage);
      };
      recognition.onresult = function (event: any) {
        clearTimeout(timer);

        interimTranscript = "";
        if (typeof event.results == "undefined") {
          recognition.onend = null;
          recognition.stop();
          return;
        }
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            interimTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        setTranscript(interimTranscript);
        timer = setTimeout(complete, 900);
      };
    }
  }, [recognition, complete]);

  return {
    start,
    complete,
    transcript,
    isListening,
    img,
    recognition,
  };
};
