import React from "react";
import { IAppointmentBotMessage } from "../../../hooks/appointmentBot/bot/types";
import { BotResponse } from "../botResponse/BotResponse";
// import ReactHtmlParser from 'react-html-parser';
import { FormContainer } from "../appointmentForms/FormContainer";
import { FormField } from "../appointmentForms/FormField";

export const BookingCompleted = (
  props: IAppointmentBotMessage & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  //console.log(props);
  return (
    <BotResponse className={props.additionalClasses} message={props.utterance}>
      {/* <div className="formContainer">
        <div className="drug_description">
          Your Appointment of MRI of Bones & Joints with Dr Matthew Provencher
          on June 23rd at 3pm has been booked. Please bring valid identification
          as well as valid credit card that will accept a charge of upto $1650.
          Please arrive 30 minutes before procedure. You will need to provide
          your medical information prior to your visit.
        </div>

        <div className="drug_description booking_description_bottom">
          `You will need to provide your medical information prior to your
          visit.`
          <FormContainer>
            <FormField
              label="Fill Intake Form Now"
              className="book-appointment-button"
            />
            <FormField
              label="Fill Intake Form Later"
              utterance={"Fill Intake Form Later"}
              className="book-appointment-button"
              option={"formSubmittedMessage"}
            />
          </FormContainer>
        </div>
      </div> */}
    </BotResponse>
  );
};
