export const addAppointment = (message: any) => {
  if (message) {
    return {
      type: "ADD_APPOINTMENT",
      payload: message,
    };
  }
};

export const removeAppointment = () => {
  return {
    type: "REMOVE_APPOINTMENT",
  };
};
