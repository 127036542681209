import React from "react";
import { IStackProps, Stack ,Spinner, SpinnerSize } from "@fluentui/react";

export const DataLoader = () => {
  const rowProps: IStackProps = { horizontal: true, verticalAlign: "center" };

  const tokens = {
    spinnerStack: {
      childrenGap: 20,
    },
  };

  return (
      <div className="dataLoader-container">
        <Stack {...rowProps} tokens={tokens.spinnerStack}>
          <Spinner size={SpinnerSize.large} />
        </Stack>
      </div>
  );
};
