export const HTTP_404_ERROR = "HTTP_404_ERROR";
export const HTTP_500_ERROR = "HTTP_500_ERROR";
export const HTTP_OTHER_ERROR = "HTTP_OTHER_ERROR";
export const HTTP_CLEAR_ERROR = "HTTP_CLEAR_ERROR";

export const execute500Handler = () => {
  return {
    type: HTTP_500_ERROR,
    // payload: props,
  };
};

export const clearErrors = () => {
  return {
    type: HTTP_CLEAR_ERROR,
  };
};
