import { AppointmentBotMessageTypes } from "../../src/hooks/appointmentBot/bot/types";
import moment from "moment";
import { MMDDYYYY_FORMAT, DEFAULT_CONTACT } from "../constants/general-const";
export const structureBotMessages = (
  objResponse: any,
  serchValue: any,
  option?: any
) => {
  //console.log(serchValue);
  const validData = (data: any) => {
    if (
      data.entity &&
      Object.keys(data.entity).includes("Instructions") &&
      data.entity.Instructions.length
    ) {
      return AppointmentBotMessageTypes.PRINT_INSTRUCTIONS;
    } else {
      if (
        data.primary_provider &&
        Object.keys(data.primary_provider).length > 0
      ) {
        if (data.primary_provider.TimeSlotData.length) {
          return AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT;
        } else {
          return AppointmentBotMessageTypes.PRIMARY_PROVIDER_UNAVIALIABLE;
        }
      } else if (data.other_providers && data.other_providers.length) {
        return AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT;
      } else return AppointmentBotMessageTypes.DATA_NOT_FOUND;
    }
  };
  // console.log("option?.queryType", option?.queryType);
  // console.log("F-objResponse", objResponse);
  if (option?.queryType !== undefined && option?.queryType !== "") {
    switch (option.queryType) {
      case "askToBookProviderSlot":
        return {
          data: {
            entity: objResponse.entity,
            other_providers: objResponse.other_providers,
            associated_providers: objResponse.associated_providers
              ? objResponse.associated_providers
              : [],
            primary_provider: objResponse.primary_provider,
            nluData: objResponse.nlu_response,
            from_date: moment(new Date()).format(MMDDYYYY_FORMAT),
            service_id: objResponse.service.id,
            only_show_available_providers:
              objResponse.only_show_available_providers,
            query: objResponse.query,
            request_service_label: objResponse.request_service_label,
          },
          utterance: serchValue,
          type: AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT,
        };
      case "primaryProviderUnavialiable":
        return {
          data: { ...objResponse },
          utterance: serchValue,
          type: AppointmentBotMessageTypes.PRIMARY_PROVIDER_UNAVIALIABLE,
        };
      case "printInstructions":
        return {
          data: { ...objResponse },
          utterance: serchValue,
          type: AppointmentBotMessageTypes.PRINT_INSTRUCTIONS,
        };
      case "FindMoreSLOTS":
        return {
          data: { ...objResponse },
          utterance: serchValue,
          type: AppointmentBotMessageTypes.FIND_MORE_SLOTS,
        };
      case "appointmentBooked":
        return {
          utterance: serchValue,
          type: AppointmentBotMessageTypes.BOOKING_COMPLETED,
        };
      case "getTreatmentCost":
        return {
          utterance: serchValue,
          type: AppointmentBotMessageTypes.GET_TREATMENT_COST,
        };
      case "paymentOptionCreditCard":
        return {
          utterance: serchValue,
          type: AppointmentBotMessageTypes.PAYMENT_OPTION_CREDIT_CARD,
        };
      case "contactFormSubmitted":
        return {
          utterance: serchValue,
          type: AppointmentBotMessageTypes.BOOKING_COMPLETED,
        };
      case "formSubmittedMessage":
        return {
          utterance: serchValue,
          type: AppointmentBotMessageTypes.FORM_SUBMITTED_MSG,
        };
      case "notFound":
        return {
          Message: `We were unable to find you a suitable slot. Please call ${DEFAULT_CONTACT} to book your appointment`,
          type: AppointmentBotMessageTypes.DATA_NOT_FOUND,
        };
      case "error":
        return {
          Message: serchValue,
          type: AppointmentBotMessageTypes.DATA_NOT_FOUND,
        };
      case "DataNotFound":
        return {
          Message: `We were unable to find you a suitable slot. Please call ${DEFAULT_CONTACT} to book your appointment`,
          type: AppointmentBotMessageTypes.DATA_NOT_FOUND,
        };
      case "bookAppointmentError":
        return {
          data: objResponse,
          utterance: serchValue,
          type: AppointmentBotMessageTypes.BOOK_APPOINTMENT_ERROR,
        };
      case "rescheduleAppointmentError":
      case "deleteAppointmentError":
        return {
          utterance: serchValue,
          Message: `We were unable to find you a suitable slot. Please call ${DEFAULT_CONTACT} to book your appointment`,
          type: AppointmentBotMessageTypes.DATA_NOT_FOUND,
        };
      case "botSuccess":
        return {
          utterance: serchValue,
          type: AppointmentBotMessageTypes.BOT_SUCCESS,
        };
    }
  } else {
    switch (objResponse.type) {
      case "book_appointment":
        let routeResponse: any;
        const routeDecide = validData(objResponse.data);
        if (
          routeDecide === AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT
        ) {
          routeResponse = structureBotMessages(
            {
              ...objResponse.data,
              query: objResponse.query,
              request_service_label: objResponse.request_service_label,
            },
            objResponse.query,
            {
              queryType: AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT,
            }
          );
          return routeResponse;
        } else if (
          routeDecide ===
          AppointmentBotMessageTypes.PRIMARY_PROVIDER_UNAVIALIABLE
        ) {
          routeResponse = structureBotMessages(
            {
              ...objResponse.data,
              query: objResponse.query,
              request_service_label: objResponse.request_service_label,
            },
            objResponse.query,
            {
              queryType:
                AppointmentBotMessageTypes.PRIMARY_PROVIDER_UNAVIALIABLE,
            }
          );
          return routeResponse;
        } else if (
          routeDecide === AppointmentBotMessageTypes.PRINT_INSTRUCTIONS
        ) {
          routeResponse = structureBotMessages(
            objResponse.data,
            objResponse.query,
            {
              queryType: AppointmentBotMessageTypes.PRINT_INSTRUCTIONS,
            }
          );
          return routeResponse;
        } else if (routeDecide === AppointmentBotMessageTypes.DATA_NOT_FOUND) {
          routeResponse = structureBotMessages("", serchValue, {
            queryType: AppointmentBotMessageTypes.DATA_NOT_FOUND,
          });
          return routeResponse;
        } else {
          return {
            Message: `We were unable to find you a suitable slot. Please call ${DEFAULT_CONTACT} to book your appointment`,
            type: AppointmentBotMessageTypes.DATA_NOT_FOUND,
          };
        }
      case "fuzzy_result":
        return {
          data: { ...objResponse },
          utterance: objResponse.query,
          type: AppointmentBotMessageTypes.FUZZY_SEARCH,
        };
      case "specialty_failed":
      case "no_online_appointment":
      case "no_provider":
      case "service_failed":
      case "no_slot":
      case "change_appointment":
      case "relationship_entity":
      case "no_services":
        return {
          Message: objResponse.data.message,
          type: AppointmentBotMessageTypes.DATA_NOT_FOUND,
        };
      case "multiple_services":
        return {
          data: { ...objResponse },
          utterance: objResponse.query,
          type: AppointmentBotMessageTypes.MULTIPLE_SERVICES,
        };
      case "ask_visit_reason":
        return {
          data: { ...objResponse },
          utterance: objResponse.query,
          type: AppointmentBotMessageTypes.ASK_VISIT_REASON,
        };
      // } else if (
      //   objResponse.nluData.intent.name === "cancel_reschedule_appointment"
      // ) {
      //   return {
      //     utterance: serchValue,
      //     diseaseName: "sdf",
      //     description: "Select From Following Appointments",
      //     data: objResponse.data.contentRes,
      //     type: AppointmentBotMessageTypes.RESCHEDULE_CANCEL_APPOINTMENT,
      //   };
      // }

      default:
        return {
          Message: `We were unable to find you a suitable slot. Please call ${DEFAULT_CONTACT} to book your appointment`,
          type: AppointmentBotMessageTypes.DATA_NOT_FOUND,
        };
    }
  }
};
