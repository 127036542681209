import React, { useCallback } from "react";
// import { useAnalystLog } from "../../hooks/AnalystLog/useAnalystLog";
import { useDispatch } from "react-redux";
import { IFormFieldProps } from "./types";
import { addMessageResponse } from "../../../store/chatBot/actions";
import { structureBotMessages } from "../../../utils/structureBotMessages";
import {
  IAppointmentBotMessage,
  AppointmentBotMessageTypes,
} from "../../../hooks/appointmentBot/bot/types";
import { uuidv4 } from "../../../utils/helpers";
export const FormField = (props: IFormFieldProps) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      props.onClickSelection &&
        props.onClickSelection((ev.currentTarget as any).value);
      if (props.utterance) {
        const utterSplit = props.utterance.split("&");
        dispatch(
          addMessageResponse({
            id: uuidv4(),
            Message: utterSplit.length > 1 ? utterSplit[0] : props.utterance,
            type: AppointmentBotMessageTypes.USER,
          })
        );
        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages("", props.utterance, {
            queryType: props.option,
          });
        if (messageResponse) {
          //console.log(messageResponse);
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      }
      props.onClick && props.onClick();
    },
    [props, dispatch]
  );
  // const onCheck = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
  //     props.onClickSelection && props.onClickSelection((ev.target as any).value);
  // }, [])
  return (
    <form>
      <button className={props.className} name="Submit" onClick={onClick}>
        {props.label}
      </button>
    </form>
  );
};

// // export const SuggestionFormField = (props: IFormFieldProps) => {
// //   const { getAppointmentSearchData } = useBot();
// //   const { log } = useAnalystLog();
// //   const onClick = useCallback(
// //     (ev: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
// //       ev.preventDefault();
// //       ev.stopPropagation();
// //       props.onClickSelection &&
// //         props.onClickSelection((ev.currentTarget as any).value);
// //       //console.log("logs", log);
// //       const utterance = props.utterance + "&logId=" + log;
// //       props.utterance && getAppointmentSearchData(utterance);
// //       props.onClick && props.onClick();
// //     },
// //     [getAppointmentSearchData, props.onClick]
// //   );
//   // const onCheck = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
//   //     props.onClickSelection && props.onClickSelection((ev.target as any).value);
//   // }, [])
//   return (
//     <form>
//       <div />
//       <input
//         type="Submit"
//         name="Submit"
//         value={props.label}
//         onClick={onClick}
//       />
//     </form>
//   );
// };
