import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { IconButton } from "@fluentui/react/lib/Button";
import { withScrollAndFocus } from "../../../hoc/withScrollAndFocus";
import ProviderCard from "./ProviderCard";
import {
  convertISOdatetime,
  convertPayloadDataFormat,
  uuidv4,
} from "../../../utils/helpers";
import {
  BOOK_APPOINTMENT_STATUS,
  COST_ESTIMATES,
  COVERAGE_TYPE,
  DAYS,
  FETCH_PROVIDER_DATA_DAYS,
  HHmm_FORMAT,
  hmma_FORMAT,
  MMDDYYYY_FORMAT,
  MMDD_FORMAT,
  MMMMDoYYYYhmma_FORMAT,
  MMMMDoYYYY_FORMAT,
  REFERING_DOCTOR,
} from "../../../constants/general-const";
import { addMessageResponse } from "../../../store/chatBot/actions";
import {
  AppointmentBotMessageTypes,
  IAppointmentBotMessage,
} from "../../../hooks/appointmentBot/bot/types";
import { addAppointment } from "../../../store/appointments/actions";
import { structureBotMessages } from "../../../utils/structureBotMessages";
import { useUpcomingAppointment } from "../../../hooks/upcomingAppointment/useUpcomingAppointment";
import { useBoolean } from "@fluentui/react-hooks";
import { Mixpanel } from "../../../utils/mixpanel";
import { ConfirmBookingModal } from "../../modals/confirmBooking/ConfirmBookingModal";

type FindAlertnativeProviderWithLocProps = {
  providerLocData: any;
  utterance: any;
  serviceId: any;
  providerList: any;
  // visitReasonRequired: Boolean;
  dontFetchMoreSlots: Boolean;
};

const BaseFindAlertnativeProviderWithLoc = ({
  providerLocData,
  utterance,
  serviceId,
  providerList,
  // visitReasonRequired,
  dontFetchMoreSlots,
}: FindAlertnativeProviderWithLocProps) => {
  const userData = useSelector((state: any) => state.isLoggedIn.patientDemog);
  const formData = useRef<any>({});
  const providerLocations = useSelector(
    (state: any) => state.providersInfo.providersLocations.locations
  );
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const [activeTabs, setActiveTabs] = useState<string[]>([]);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const dispatch = useDispatch();
  const { storeUpcomingAppointment, getTimeSlots } = useUpcomingAppointment();

  const toggleShowProviders = (key: string) => {
    activeTabs.includes(key)
      ? setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== key))
      : setActiveTabs((oldArray) => [...oldArray, key]);
  };

  const formatLocations = useCallback(
    (loc: any) => {
      const foundLoc =
        providerLocations &&
        providerLocations.length &&
        providerLocations.find((itm: any) => itm.Abbreviation === loc);
      let locationDetails = "";
      if (foundLoc) {
        locationDetails = foundLoc.Description;
      } else {
        locationDetails = loc;
      }
      return locationDetails;
    },
    [providerLocations]
  );

  //Function called For Change Provider
  const handleChangeProvider = (item: any) => {
    const payloadData = {
      provider: item.Abbreviation,
      appointment_type: item.AppointmentType,
      saveAsAppointment_type: item.ForcedAppointmentType,
      duration: item.Duration,
      appointment_date_time: new Date(
        `${item.TimeSlotData[0].Available_Date} ${item.TimeSlotData[0].Start_Time}`
      ),
      department: item.TimeSlotData[0].Scheduling_Department.trim(),
      location: item.TimeSlotData[0].Scheduling_Location.trim(),
      forced: item.TimeSlotData[0].Forced,
    };
    let providerInfo = { ...item };
    formData.current = { payloadData, providerInfo };
    Mixpanel.track("Book-Appointment", {
      outcome: "Book Appointment Clicked",
      email: patientDetails.email,
      mrn: patientDetails["custom:mrn"],
      provider: item.Abbreviation,
      location: item.TimeSlotData[0].Scheduling_Location.trim(),
      dateTime: moment(
        new Date(
          `${item.TimeSlotData[0].Available_Date} ${item.TimeSlotData[0].Start_Time}`
        )
      ).format(MMMMDoYYYYhmma_FORMAT),
      visitReason: utterance,
      appointmentType: item.ForcedAppointmentType,
    });
    showModal();
  };

  //Function called when Book appointment confirmation modal is submited
  const createAppointmentPayloadWithReason = (
    visitReason: string | undefined | null
  ) => {
    // if (visitReasonRequired && visitReason) {
    //   // console.log(formData.current);
    //   const payload = convertISOdatetime(formData.current.payloadData);
    //   const arr_appt = payload.appointment_date_time.split(".");
    //   let forcedSlotInfo = {};
    //   if (formData.current.payloadData.saveAsAppointment_type) {
    //     if (
    //       !formData.current.payloadData.appointment_type?.includes(
    //         formData.current.payloadData.saveAsAppointment_type
    //       )
    //     ) {
    //       forcedSlotInfo = {
    //         forced: true,
    //       };
    //     }
    //   }
    //   const convertedPayload = convertPayloadDataFormat({
    //     ...payload,
    //     ...forcedSlotInfo,
    //     cost_estimate: COST_ESTIMATES,
    //     refer_dr: REFERING_DOCTOR,
    //     coverage_type: COVERAGE_TYPE,
    //     comments: visitReason,
    //     status: BOOK_APPOINTMENT_STATUS,
    //     patient_id: userData.Patient_ID,
    //     reason: visitReason,
    //     service_id: serviceId,
    //     appointment_date_time: arr_appt[0] + ".842073+0000",
    //   });
    //   hideModal();
    //   handleBookAppointment(
    //     formData.current.payloadData,
    //     convertedPayload,
    //     formData.current.providerInfo
    //   );
    // } else if (!visitReasonRequired) {
    const payload = convertISOdatetime(formData.current.payloadData);
    const arr_appt = payload.appointment_date_time.split(".");
    let forcedSlotInfo = {};
    if (formData.current.payloadData?.saveAsAppointment_type) {
      if (
        !formData.current.payloadData?.appointment_type?.includes(
          formData.current.payloadData?.saveAsAppointment_type
        )
      ) {
        forcedSlotInfo = {
          forced: true,
        };
      }
    }
    const convertedPayload = convertPayloadDataFormat({
      ...payload,
      ...forcedSlotInfo,
      cost_estimate: COST_ESTIMATES,
      refer_dr: REFERING_DOCTOR,
      coverage_type: COVERAGE_TYPE,
      comments: utterance,
      status: BOOK_APPOINTMENT_STATUS,
      patient_id: userData.Patient_ID,
      reason: utterance,
      service_id: serviceId,
      appointment_date_time: arr_appt[0] + ".842073+0000",
    });
    hideModal();
    handleBookAppointment(
      formData.current.payloadData,
      convertedPayload,
      formData.current.providerInfo
    );
    //   }
  };

  //(!!Cleanup Required) Need to generalize this function in the parent component itself
  const handleBookAppointment = (
    payloadData: any,
    convertedPayload: any,
    providerInfo: any
  ) => {
    if (payloadData && payloadData.appointment_date_time) {
      const userUtterance = `Book appointment with ${providerInfo.FirstName} ${
        providerInfo.LastName
      }, ${providerInfo.Suffix} (${providerInfo.SpecialtyName}) on ${moment(
        new Date(payloadData.appointment_date_time)
      ).format(MMMMDoYYYYhmma_FORMAT)}`;
      dispatch(
        addMessageResponse({
          id: uuidv4(),
          Message: userUtterance,
          type: AppointmentBotMessageTypes.USER,
        })
      );
    }
    storeUpcomingAppointment(convertedPayload)
      .then((res) => {
        let utteranceNew = "";
        let queryType = "";
        if (payloadData?.appointment_date_time) {
          utteranceNew = `Your appointment with ${providerInfo.FirstName} ${
            providerInfo.LastName
          }, ${providerInfo.Suffix} (${
            providerInfo.SpecialtyName
          }) has been booked for ${moment(
            new Date(payloadData?.appointment_date_time)
          ).format(MMMMDoYYYY_FORMAT)} at ${moment(
            new Date(payloadData?.appointment_date_time)
          ).format(hmma_FORMAT)} for ${payloadData.duration} minutes
          Your visit is to address "${utterance}"`;
          queryType = AppointmentBotMessageTypes.APPOINTMENT_BOOKED;
          dispatch(addAppointment({ ...convertedPayload }));
        }
        Mixpanel.track("Book-Appointment-Confirm", {
          outcome: "Appointment Booked",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: payloadData.provider,
          location: payloadData.location,
          dateTime: moment(new Date(payloadData.appointment_date_time)).format(
            MMMMDoYYYYhmma_FORMAT
          ),
          visitReason: payloadData.reason,
          appointmentType: payloadData.saveAsAppointment_type,
        });

        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages("", utteranceNew, {
            queryType: queryType,
          });
        if (messageResponse) {
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      })
      .catch((error) => {
        const data = {};
        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages(
            data,
            error.response && error.response.data.message
              ? error.response.data.message
              : "Something Wrong Happened Please Try Again",
            {
              queryType: AppointmentBotMessageTypes.ERROR_MESSAGE,
            }
          );
        Mixpanel.track("Book-Appointment-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: payloadData.provider,
          location: payloadData.location,
          dateTime: moment(new Date(payloadData.appointment_date_time)).format(
            MMMMDoYYYYhmma_FORMAT
          ),
          visitReason: payloadData.reason,
          appointmentType: payloadData.saveAsAppointment_type,
        });
        if (messageResponse) {
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      });
  };

  //Fetching Additional 2 months data from the to_date and passing entire time block to be shown to the user
  const handleGetAllSlotsForProvider = useCallback(async (item: any) => {
    let timeSlots, toDate: any;
    const userUtterance = `Find all time slots for ${item.FirstName} ${item.LastName}, ${item.Suffix} (${item.SpecialtyName})`;
    dispatch(
      addMessageResponse({
        id: uuidv4(),
        Message: userUtterance,
        type: AppointmentBotMessageTypes.USER,
      })
    );
    Mixpanel.track("Alternative-Slots", {
      outcome: "Find all time slots for Alternative Provider Clicked",
      email: patientDetails.email,
      mrn: patientDetails["custom:mrn"],
      provider: item.Abbreviation,
      visitReason: utterance,
      appointmentType: item.ForcedAppointmentType,
    });

    if (dontFetchMoreSlots) {
      timeSlots = [...item.TimeSlotData];
      toDate = moment(new Date(item.to_date)).format(MMDDYYYY_FORMAT);
    } else {
      const payload = {
        provider: item.Abbreviation,
        from_date: moment(new Date(item.to_date)).format(MMDDYYYY_FORMAT),
        to_date: moment(new Date(item.to_date))
          .add(FETCH_PROVIDER_DATA_DAYS, DAYS)
          .format(MMDDYYYY_FORMAT),
        current_date: moment(new Date()).format(MMDDYYYY_FORMAT),
        time: moment(new Date()).format(HHmm_FORMAT),
        appointment_type: item.AppointmentType,
        appointment_duration: item.Duration,
      };
      try {
        Mixpanel.track("More-Slots", {
          outcome: "More slots asked for Provider ",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: item.Abbreviation,
          visitReason: utterance,
          appointmentType: item.AppointmentType,
          from_date: moment(new Date(item.to_date)).format(MMDDYYYY_FORMAT),
          to_date: moment(new Date(item.to_date))
            .add(FETCH_PROVIDER_DATA_DAYS, DAYS)
            .format(MMDDYYYY_FORMAT),
          patient_id: userData.Patient_ID,
        });
        let timeblockfunction = await getTimeSlots(payload);
        timeSlots = [...item.TimeSlotData, ...timeblockfunction];
        toDate = moment(new Date(item.to_date))
          .add(FETCH_PROVIDER_DATA_DAYS, DAYS)
          .format(MMDDYYYY_FORMAT);

        Mixpanel.track("More-Slots-Confirm", {
          outcome: "More slots Provided",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: item.Abbreviation,
          visitReason: utterance,
          appointmentType: item.AppointmentType,
          from_date: moment(new Date(item.to_date)).format(MMDDYYYY_FORMAT),
          to_date: moment(new Date(item.to_date))
            .add(FETCH_PROVIDER_DATA_DAYS, DAYS)
            .format(MMDDYYYY_FORMAT),
          noOfSlotsProvided: timeblockfunction.length,
        });
      } catch (error: any) {
        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages(
            "",
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error while fetching more slots, Please try again or choose another Provider",
            {
              queryType: AppointmentBotMessageTypes.ERROR_MESSAGE,
            }
          );
        Mixpanel.track("More-Slots-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: item.Abbreviation,
          visitReason: utterance,
          appointmentType: item.AppointmentType,
        });
        if (messageResponse) {
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      }
    }
    const messageResponse: IAppointmentBotMessage | undefined =
      structureBotMessages(
        {
          provider_list: providerList,
          provider_info: { ...item },
          from_date: moment(new Date(item.from_date)).format(MMDDYYYY_FORMAT),
          to_date: toDate,
          timeSlots: timeSlots,
          service_id: serviceId,
          // visitReasonRequired: visitReasonRequired,
          dontFetchMoreSlots: dontFetchMoreSlots,
        },
        utterance,
        {
          queryType: AppointmentBotMessageTypes.FIND_MORE_SLOTS,
        }
      );
    if (messageResponse) {
      dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
    }
  }, []);

  const getFirstAvailiableSlot = (providerLoc: string) => {
    if (providerLoc !== "NoSlot") {
      let SortedArray = providerLocData[providerLoc].sort((a: any, b: any) => {
        if (a.TimeSlotData.length) {
          let da: any = new Date(
              `${a.TimeSlotData[0].Available_Date} ${a.TimeSlotData[0].Start_Time}`
            ),
            db: any = new Date(
              `${b.TimeSlotData[0].Available_Date} ${b.TimeSlotData[0].Start_Time}`
            );
          return da - db;
        }
      });
      return `${moment(
        new Date(SortedArray[0].TimeSlotData[0].Available_Date)
      ).format(MMDD_FORMAT)} at, ${moment(
        new Date(
          `${SortedArray[0].TimeSlotData[0].Available_Date} ${SortedArray[0].TimeSlotData[0].Start_Time}`
        )
      ).format(hmma_FORMAT)}`;
    } else {
      return `${moment(
        new Date(providerLocData[providerLoc][0].to_date)
      ).format(MMDD_FORMAT)}`;
    }
  };

  const renderProviderWithLoc = (providerLoc: string, index: number) => {
    return (
      <div className="providerLocContainer" key={`${providerLoc} - ${index}`}>
        <div
          className="providerContainer"
          onClick={() => toggleShowProviders(providerLoc)}
        >
          <IconButton
            iconProps={{
              iconName: activeTabs.includes(providerLoc)
                ? "CollapseContentSingle"
                : "ExploreContentSingle",
            }}
            ariaLabel="Toggle Show slots"
          />

          <h5 className="loc">
            {providerLoc !== "NoSlot"
              ? `${formatLocations(providerLoc)}(from ${getFirstAvailiableSlot(
                  providerLoc
                )})`
              : `Providers you can see (after ${getFirstAvailiableSlot(
                  providerLoc
                )})`}
          </h5>
        </div>
        {activeTabs.includes(providerLoc) ? (
          <div className="locContainer">
            {providerLocData[providerLoc]
              .sort((a: any, b: any) => {
                if (a.TimeSlotData.length) {
                  let da: any = new Date(
                      `${a.TimeSlotData[0].Available_Date} ${a.TimeSlotData[0].Start_Time}`
                    ),
                    db: any = new Date(
                      `${b.TimeSlotData[0].Available_Date} ${b.TimeSlotData[0].Start_Time}`
                    );
                  return da - db;
                }
              })
              .map((item: any, index1: any) => {
                return (
                  <ProviderCard
                    item={item}
                    key={index1}
                    keyval={`${index1}${item.FirstName}-${providerLoc}`}
                    handleGetAllSlotsForProvider={handleGetAllSlotsForProvider}
                    handleBookAppointmentWithNewProv={handleChangeProvider}
                  />
                );
              })}
          </div>
        ) : null}
      </div>
    );
  };

  if (!(providerLocData && Object.keys(providerLocData).length !== 0)) {
    return <div className="botResponseloading" style={{ display: "block" }} />;
  } else {
    let providerLockeys = [...Object.keys(providerLocData)];
    providerLockeys.push(
      providerLockeys.splice(providerLockeys.indexOf("NoSlot"), 1)[0]
    );
    return (
      <div className="anotherProviderContainer">
        {!(providerLockeys.length === 1 && providerLockeys[0] === "NoSlot") ? (
          <>
            <h4>
              The following alternative providers and appointments are
              available:
            </h4>
            <div className="anotherProviderBody">
              <div className="renderLocProviderContainer">
                {dontFetchMoreSlots
                  ? providerLockeys
                      .filter((providerLoc: string) => providerLoc !== "NoSlot")
                      .map((providerLoc: string, index) =>
                        renderProviderWithLoc(providerLoc, index)
                      )
                  : providerLockeys.map((providerLoc: string, index) =>
                      renderProviderWithLoc(providerLoc, index)
                    )}
              </div>
              {isModalOpen ? (
                <ConfirmBookingModal
                  isModalOpen={isModalOpen}
                  // askForVisitReason={visitReasonRequired}
                  handleModalSubmit={createAppointmentPayloadWithReason}
                  closeModal={hideModal}
                  selectedSlot={
                    formData.current.payloadData.appointment_date_time
                  }
                  providerInfo={formData.current.providerInfo}
                  location={formatLocations(
                    formData.current.payloadData.location
                  )}
                  userUtternace={utterance}
                />
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  }
};

export const FindAlertnativeProviderWithLoc = React.memo(
  withScrollAndFocus<any>(BaseFindAlertnativeProviderWithLoc)
);
