import React, { useState } from "react";
import { withLoaderAndNotifyOnApiLoad } from "../../hoc/withLoaderAndNotifyOnApiLoad";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import AskForUserProfile from "./AskForUserProfile";

const RegistrationPage = (props: any) => {
  const [selectedTab, setSelectedTab] = useState("signIn");
  const [askforProfile, setAskForProfile] = useState(false);

  const handleAskForUserProfile = () => {
    setAskForProfile(true);
    props.handleLoading(false);
  };

  return (
    <>
      <div
        className={
          askforProfile
            ? "registration-tab-container ask-for-profile-active"
            : "registration-tab-container"
        }
      >
        {!askforProfile ? (
          <div className="registration-text-container">
            <p className="registration-text">
              {`Welcome to our secure Primary Care & GYN appointment scheduler. Existing patients can now book, cancel, and reschedule appointments with your primary care or GYN providers whenever convenient for you. 
            You will need a`}
              <b>{` separate account from FollowMyHealth`}</b>
              {` to access this system. `}
              <b>
                {`First time users: please create your account by starting on the “Sign Up” tab below.`}
              </b>
              {` If you have any problems, or you need to schedule with one of our specialty clinics, please call us at 970-926-6340.`}
            </p>
          </div>
        ) : null}
        {askforProfile ? (
          <AskForUserProfile handleLoading={props.handleLoading} />
        ) : null}
        {!askforProfile ? (
          <div className="registration-tab">
            <div className="registration-tab-item-container">
              <div
                className={
                  selectedTab === "signIn"
                    ? "registration-tab-item-toggle1"
                    : "registration-tab-item2"
                }
                onClick={() => setSelectedTab("signIn")}
              >
                <p className="registration-tab-item-text">Sign In</p>
              </div>
              <div
                className={
                  selectedTab === "signUp"
                    ? "registration-tab-item-toggle1"
                    : "registration-tab-item1"
                }
                onClick={() => setSelectedTab("signUp")}
              >
                <p className="registration-tab-item-text">Sign Up</p>
              </div>
            </div>
            <div className="registration-tab-content">
              {selectedTab === "signUp" ? (
                <SignUp handleLoading={props.handleLoading} />
              ) : null}
              {selectedTab === "signIn" ? (
                <SignIn
                  handleLoading={props.handleLoading}
                  handleAskForUserProfile={handleAskForUserProfile}
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export const Registration = withLoaderAndNotifyOnApiLoad(RegistrationPage);
