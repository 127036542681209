import React from "react";
import { BotResponse } from "../chatMessages/botResponse/BotResponse";
import { UserResponse } from "../chatMessages/userResponse/UserReponse";
// import { RescheduleCancelAppointment } from "../chatMessages/book-cancel-appointment/RescheduleCancelAppointment";
import { AskForHealthPlan } from "../chatMessages/askForHealthPlan/AskForHealthPlan";
import { GetTreatmentCost } from "../chatMessages/getTreatmentCost/GetTreatmentCost";
import { BookingCompleted } from "../chatMessages/bookingCompleted/BookingCompleted";
import { FormSubmittedMessage } from "../chatMessages/formSubmittedMessage/FormSubmittedMessage";
import { DataNotFound } from "../chatMessages/dataNotFound/DataNotFound";
import {
  IAppointmentBotMessage,
  AppointmentBotMessageTypes,
} from "../../hooks/appointmentBot/bot/types";
import { CreditCard } from "../chatMessages/creditCard/CreditCard";
import { BotSuccessMessage } from "../chatMessages/botSucessMessage/BotSucessMessage";
import { AskToBookProviderSlot } from "../chatMessages/askToBookProviderSlot/AskToBookProviderSlot";
import { BookAppointmentError } from "../chatMessages/bookAppointmentError/BookAppointmentError";
import { FuzzySearch } from "../chatMessages/fuzzySearch/FuzzySearch";
import { AskToChooseService } from "../chatMessages/askTochooseService/AskToChooseService";
import { PrimaryProviderUnavailiable } from "../chatMessages/primaryProviderUnavailiable/PrimaryProviderUnavailiable";
import { FindMoreSlots } from "../chatMessages/findMoreSlots/FindMoreSlots";
import { PrintInstructions } from "../chatMessages/printInstructions/PrintInstructions";
import { AskForVisitReason } from "../chatMessages/askForVisitReason/AskForVisitReason";

export const CustomizeBotMessages = (
  message: IAppointmentBotMessage,
  messageIdx: number,
  conversationIdx?: number,
  dispatchData?: (
    utterance: string | null,
    request_type: string,
    service_type: string | null,
    ask_visit_reason: boolean | null,
    service?: any
  ) => void
): JSX.Element => {
  switch (message.type) {
    case AppointmentBotMessageTypes.USER:
      return <UserResponse key={message.id}>{message.Message}</UserResponse>;
    // case AppointmentBotMessageTypes.RESCHEDULE_CANCEL_APPOINTMENT:
    //   return (
    //     <RescheduleCancelAppointment
    //       key={message.id}
    //       {...message}
    //       messageIdx={messageIdx}
    //     />
    //   );
    case AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT:
      return (
        <AskToBookProviderSlot
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        />
      );
    case AppointmentBotMessageTypes.ASK_FOR_HEALTH_PLAN:
      return (
        <AskForHealthPlan
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        />
      );
    case AppointmentBotMessageTypes.GET_TREATMENT_COST:
      return (
        <GetTreatmentCost
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        />
      );
    case AppointmentBotMessageTypes.PAYMENT_OPTION_CREDIT_CARD:
      return (
        <CreditCard
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        ></CreditCard>
      );
    case AppointmentBotMessageTypes.BOOKING_COMPLETED:
      return (
        <BookingCompleted
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        />
      );
    case AppointmentBotMessageTypes.FORM_SUBMITTED_MSG:
      return (
        <FormSubmittedMessage
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        ></FormSubmittedMessage>
      );
    case AppointmentBotMessageTypes.DATA_NOT_FOUND:
      return (
        <DataNotFound key={message.id} {...message} messageIdx={messageIdx} />
      );
    case AppointmentBotMessageTypes.BOOK_APPOINTMENT_ERROR:
      return (
        <BookAppointmentError
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        />
      );
    case AppointmentBotMessageTypes.BOT_SUCCESS:
      return (
        <BotSuccessMessage
          key={message.id}
          {...message}
          messageIdx={messageIdx}
        />
      );
    case AppointmentBotMessageTypes.FUZZY_SEARCH:
      return (
        <FuzzySearch
          key={message.id}
          {...message}
          messageIdx={messageIdx}
          dispatchData={dispatchData}
        />
      );
    case AppointmentBotMessageTypes.MULTIPLE_SERVICES:
      return (
        <AskToChooseService
          key={message.id}
          {...message}
          messageIdx={messageIdx}
          dispatchData={dispatchData}
        />
      );
    case AppointmentBotMessageTypes.ASK_VISIT_REASON:
      return (
        <AskForVisitReason
          key={message.id}
          {...message}
          messageIdx={messageIdx}
          dispatchData={dispatchData}
        />
      );
    case AppointmentBotMessageTypes.PRIMARY_PROVIDER_UNAVIALIABLE:
      return (
        <PrimaryProviderUnavailiable
          key={message.id}
          {...message}
          messageIdx={messageIdx}
          dispatchData={dispatchData}
        />
      );
    case AppointmentBotMessageTypes.PRINT_INSTRUCTIONS:
      return (
        <PrintInstructions
          key={message.id}
          {...message}
          messageIdx={messageIdx}
          dispatchData={dispatchData}
        />
      );
    case AppointmentBotMessageTypes.FIND_MORE_SLOTS:
      return (
        <FindMoreSlots
          key={message.id}
          {...message}
          messageIdx={messageIdx}
          dispatchData={dispatchData}
        />
      );
    default:
      return (
        <BotResponse
          {...message}
          key={message.id}
          message={message.Message}
          description={message.SubpromptHTML}
          className="defaultBotmessage"
        >
          {/* {message.Forms &&
            buildForm(
              message.Forms.map((form: any) => ({
                label: form.Submit.Label,
                utterance: form.Submit.Utterance,
              }))
            )} */}
        </BotResponse>
      );
  }
};
