import { Client } from "../../../clients/Client";
import { IClientOperationResponse } from "../../../clients/types";
import { URL_VISIT_REASON } from "./../../../constants/url";
class AppointmentBot {
  public Client: Client;

  constructor() {
    this.Client = new Client();
  }

  // get-data-visit-reason
  public getVisitReasonList = async (payload: any) => {
    let request: IClientOperationResponse;
    var queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    request = this.Client.GetListDataWithTokens(
      `${URL_VISIT_REASON}?${queryString}`
    );
    return await request.axiosPromise;
  };
}

const appointmentBot = new AppointmentBot();

export const useBot = () => {
  return {
    getVisitReasonList: appointmentBot.getVisitReasonList,
  };
};
