import React, { useCallback, useEffect, useState } from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { IComboBoxOption } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";
import { DataLoader } from "../../../../components/dataLoader/DataLoader";
import { withScrollAndFocus } from "../../../../hoc/withScrollAndFocus";
import {
  ddddMMMMDoYYYY_FORMAT,
  SELECT_ANOTHER_TIME,
  YYYYMMDD_FORMAT,
} from "../../../../constants/general-const";
import { convertDateTimeTohhmmampm } from "../../../../utils/helpers";
import { useSelector } from "react-redux";

const moment = require("moment");

export const AnotherTimeBase: any = (props: any) => {
  const {
    onDismiss,
    onValueChange,
    providerName,
    providerList,
    availableDateTimeSlots,
    changeWeek,
    isLoading,
    nextDisable,
    prevDisable,
    appointment_type,
    duration,
  } = props;
  const [activeTabs, setActiveTabs] = useState<string[]>([]);
  const providerLocations = useSelector(
    (state: any) => state.providersInfo.providersLocations.locations
  );
  const [providerOptions, setProviderOptions] = useState<IComboBoxOption[]>();

  useEffect(() => {
    const customizeProviderList = providerList.map((item: any) => {
      return {
        text: `${item.FirstName} ${item.LastName}, ${item.Suffix} (${item.SpecialtyName})`,
        key: item.Abbreviation,
      };
    });
    setProviderOptions(customizeProviderList);
  }, [providerList]);

  const toggleShowSlot = (key: string) => {
    activeTabs.includes(key)
      ? setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== key))
      : setActiveTabs((oldArray) => [...oldArray, key]);
  };

  const formatDoctor = (provider: any) => {
    const pp =
      providerOptions?.length &&
      providerOptions.find((itm: any) => itm.key === provider);
    let providerDetails = "";
    if (pp) {
      providerDetails = pp.text;
    }
    return providerDetails;
  };

  const formatLocations = useCallback(
    (loc: any) => {
      const foundLoc =
        providerLocations &&
        providerLocations.length &&
        providerLocations.find((itm: any) => itm.Abbreviation === loc);
      let locationDetails = "";
      if (foundLoc) {
        locationDetails = foundLoc.Description;
      } else {
        locationDetails = loc;
      }
      return locationDetails;
    },
    [providerLocations]
  );

  const handleClick = (e: any, date: any, item: any) => {
    e.preventDefault();
    let formatedDate = moment(new Date(date)).format(YYYYMMDD_FORMAT);
    formatedDate = formatedDate + " " + e.target.textContent;
    let datetime = new Date(formatedDate);
    onValueChange("", "appointment_date_time", {
      appointment_date_time: datetime,
      department: item.Scheduling_Department.trim(),
      location: item.Scheduling_Location.trim(),
      forced: item.Forced,
    });
    onDismiss && onDismiss();
  };

  const renderDateTime = (dateLoc: any) => {
    const datelocsplit = dateLoc.split("|");
    const date = datelocsplit[0];
    const loc = datelocsplit[1];
    return (
      <div className="dateTimeContainer" key={dateLoc}>
        <div className="dateContainer" onClick={() => toggleShowSlot(dateLoc)}>
          <IconButton
            iconProps={{
              iconName: activeTabs.includes(dateLoc)
                ? "CollapseContentSingle"
                : "ExploreContentSingle",
            }}
            ariaLabel="Toggle Show slots"
          />

          <h5 className="date">{`${moment(new Date(date)).format(
            ddddMMMMDoYYYY_FORMAT
          )} (in ${formatLocations(loc)})`}</h5>
        </div>
        {activeTabs.includes(dateLoc) ? (
          <div className="timeContainer">
            {availableDateTimeSlots[dateLoc].map((item: any, index1: any) => {
              const formatedStartTime = convertDateTimeTohhmmampm(
                item.Start_Time
              );
              return (
                <button
                  id="time"
                  key={`${index1}${item.Start_Time}-${dateLoc}`}
                  className="timeBtn"
                  onClick={(e) => handleClick(e, date, item)}
                >
                  {formatedStartTime}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  if (
    !(
      availableDateTimeSlots && Object.keys(availableDateTimeSlots).length !== 0
    )
  ) {
    return (
      <div
        ref={props.rootRef}
        className="botResponseloading"
        style={{ display: "block" }}
      />
    );
  } else {
    return (
      <div ref={props.rootRef} className="anotherTimeContainer">
        <div className="anotherTimeHeader">
          <h4>
            {SELECT_ANOTHER_TIME} {formatDoctor(providerName)}
          </h4>
        </div>
        <div className="anotherTimeBody">
          <div className="renderDateTimeContainer">
            {isLoading ? <DataLoader /> : null}
            {Object.keys(availableDateTimeSlots).includes("noSlot") ? (
              <p className="noSlotError">{availableDateTimeSlots.noSlot}</p>
            ) : (
              Object.keys(availableDateTimeSlots).map((date: any, index) =>
                renderDateTime(date)
              )
            )}
          </div>
        </div>
        <div className="anotherTimeFooter">
          <DefaultButton
            text="<Prev Week"
            className="weekButton"
            onClick={() =>
              changeWeek("prevWeek", providerName, appointment_type, duration)
            }
            disabled={prevDisable}
          />
          <DefaultButton
            text="Next Week>"
            onClick={() =>
              changeWeek("nextWeek", providerName, appointment_type, duration)
            }
            disabled={nextDisable}
            className="weekButton"
          />
        </div>
      </div>
    );
  }
};

export const AnotherTime = React.memo(withScrollAndFocus<any>(AnotherTimeBase));
