import React from "react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import "./styles/global.scss";
import Amplify from "aws-amplify";
import awsConnection from "./config/aws";
import "@aws-amplify/ui-react/styles.css";
import { Registration } from "./pages/registration/Registration";
import { useDispatch, useSelector } from "react-redux";
import InternalServer from "./pages/error/internalServer/InternalServer";
import { Home } from "./pages/home/Home";
import { useWindowSize } from "./hooks/windowSize/useWindowSize";

initializeIcons();
Amplify.configure(awsConnection);

export const App = () => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn.status);
  const isError = useSelector((state: any) => state.httpError.showError);
  const [width, height] = useWindowSize();
  const dispatch = useDispatch();

  if (isError) {
    return (
      <div className="App" style={{ height: `100vh`, overflow: `hidden` }}>
        <InternalServer />
      </div>
    );
  } else
    return (
      <div className="App" style={{ height: height }}>
        {isLoggedIn ? <Home /> : <Registration />}
        {/* <InternalServer></InternalServer> */}
      </div>
    );
};

export default App;
