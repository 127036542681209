import React from "react";
import { BotResponse } from "../botResponse/BotResponse";

export const BookAppointmentErrorBase = (
  props: any & { messageIdx: number }
) => {
  return (
    <BotResponse
      message={props.utterance ? props.utterance : props.Message}
    ></BotResponse>
  );
};
export const BookAppointmentError = React.memo(BookAppointmentErrorBase);
