import React, { useEffect, useRef } from "react";
import { BotResponse } from "../chatMessages/botResponse/BotResponse";

const BotResponseLoadingBase = (
  props: any & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  //console.log(props);
  const unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      <div className="botResponseloading" style={{ display: "block" }}>
        Searching for Slots
      </div>
    </BotResponse>
  );
};

export const BotResponseLoading = React.memo(BotResponseLoadingBase);
