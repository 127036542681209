import React, { useRef } from "react";
import { useId } from "@fluentui/react-hooks";
import {
  DefaultButton,
  IconButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { ITextField, TextField } from "@fluentui/react/lib/TextField";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IIconProps,
} from "@fluentui/react";
import { withLoaderAndNotifyOnApiLoad } from "../../../hoc/withLoaderAndNotifyOnApiLoad";
import moment from "moment";
import {
  hmma_FORMAT,
  MMMMDoYYYY_FORMAT,
} from "../../../constants/general-const";

const BaseConfirmBookingModal = ({
  isModalOpen,
  handleModalSubmit,
  closeModal,
  selectedSlot,
  providerInfo,
  location,
  userUtternace,
}: any) => {
  const nameRef = useRef<ITextField>(null);
  const titleId = useId("title");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleModalSubmit(nameRef.current?.value);
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="modalContainer confirmBooking"
        scrollableContentClassName="modalContent"
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Confirmation</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModal}
          />
        </div>
        <div className={contentStyles.body}>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>Confirm your appointment with</h4>
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>Provider</h4>
            <p>{`${providerInfo.FirstName} ${providerInfo.LastName}, ${providerInfo.Suffix}`}</p>
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>Date</h4>
            <p>{moment(new Date(selectedSlot)).format(MMMMDoYYYY_FORMAT)}</p>
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>Time</h4>
            <p>{moment(new Date(selectedSlot)).format(hmma_FORMAT)}</p>
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>Location</h4>
            <p>{location}</p>
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>For</h4>
            <p>{userUtternace}</p>
          </div>
          {/* <div>
            {`Confirm Appointment with ${providerInfo.FirstName} ${providerInfo.LastName}, ${providerInfo.Suffix} (${providerInfo.SpecialtyName}) on  at, $ in `}
            <strong>
              <u></u>
            </strong>
            {` to address "${}"`}
          </div> */}
          {/* {askForVisitReason ? (
            <TextField
              label="Please describe the reason for your visit"
              placeholder="add your description here..."
              name="cancellation_comment"
              multiline
              rows={3}
              componentRef={nameRef}
              required
            />
          ) : null} */}
          <div className="modalActionButtonsContainer">
            <DefaultButton
              text="Confirm"
              className="modalSaveButton"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  appointmentValueContainer: {
    marginBottom: 15,
    "& h4": {
      marginBottom: 4,
    },
    "& p": {
      margin: 0,
    },
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const ConfirmBookingModal: any = withLoaderAndNotifyOnApiLoad(
  BaseConfirmBookingModal
);
