import { IAppointmentBotMessage } from "../../hooks/appointmentBot/bot/types";
export const addMessageResponse = (
  message: IAppointmentBotMessage | undefined
) => {
  if (message) {
    return {
      type: "ADD_RESPONSE",
      payload: message,
    };
  }
};
export const emptyMessageResponse = () => {
  return {
    type: "EMPTY_MESSAGES",
  };
};
