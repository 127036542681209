import React, { useState } from "react";
import { IAppointmentBotMessage } from "../../../hooks/appointmentBot/bot/types";
import { BotResponse } from "../botResponse/BotResponse";
// import ReactHtmlParser from "react-html-parser";
import { FormContainer } from "../appointmentForms/FormContainer";
import { FormField } from "../appointmentForms/FormField";

export const GetTreatmentCost = (
  props: IAppointmentBotMessage & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  const [isBreakdownCostShow, setBreakdown] = useState(false);

  const handleToggle = () => {
    setBreakdown(!isBreakdownCostShow);
  };

  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      <div className="formContainer">
        <div className="drug_description drug_description_top">
          An MRI of Bones & Joints can cost between $620 and $1650.
          <FormContainer>
            <FormField
              onClick={handleToggle}
              label="Review Breakdown of Cost"
              className="book-appointment-button"
            />
          </FormContainer>
        </div>

        {isBreakdownCostShow && (
          <div
            className={
              isBreakdownCostShow
                ? "cost_table_container"
                : "cost_table_container_hidden"
            }
          >
            <p>
              Our system has charged $620 to $1650 for MRI of the knee. the cost
              breakdown, together with your health plan coverage (United Health
              plan <b>9118772604,</b> Group Number <b>WAXHLP</b> member{" "}
              <b>111000999</b>) is below:{" "}
            </p>
            <table className="cost_table">
              <thead>
                <tr>
                  <th>Cost Item</th>
                  <th colSpan={2}>Cost Your Coverage</th>
                  <th>Your Co-Pay</th>
                </tr>
                <tr>
                  <td>Equipment & Technician</td>
                  <td>$645</td>
                  <td>100%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Contrast Agent</td>
                  <td>$125</td>
                  <td>100%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Facility</td>
                  <td>$120</td>
                  <td>100%</td>
                  <td>$20</td>
                </tr>
                <tr>
                  <td>Doctor</td>
                  <td>$580</td>
                  <td>100%</td>
                  <td>$20</td>
                </tr>
              </thead>
            </table>
            <p>
              Please note that your annual deductible is $5000, and your have
              paid $1,268 out of pocket this year, so your insurance plan only
              once you have spend $5000 our of pocket.
            </p>
          </div>
        )}
        <div className="drug_description drug_description_bottom">
          How can you plan to pay for the procedure?
        </div>
        <FormContainer className="drug_description_button">
          <FormField
            label="Credit Card"
            utterance={"Credit Card"}
            option={"paymentOptionCreditCard"}
            className="book-appointment-button"
          />
          <FormField
            label="Check or eCheck"
            className="book-appointment-button"
          />
          <FormField label="Cash" className="book-appointment-button" />
          <FormField
            label="Review Payment Plan Options"
            className="book-appointment-button"
          />
        </FormContainer>
      </div>
    </BotResponse>
  );
};
