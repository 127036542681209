import mixpanel from "mixpanel-browser";

let env_check = process.env.NODE_ENV === "production";

if (process.env.REACT_APP_MIXPANEL_TOKEN && env_check) {
  console.log(process.env.REACT_APP_MIXPANEL_TOKEN);
  console.log(process.env.NODE_ENV);
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    api_host: `${process.env.REACT_APP_OPEN_API_ENDPOINT}/user/mix_panel`,
    ignore_dnt: true,
  });
}

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (newid: string, id?: string) => {
    if (env_check) mixpanel.alias(newid, id);
  },
  track: (name: string, props?: any) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
