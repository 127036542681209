import { HTTP_500_ERROR, HTTP_CLEAR_ERROR } from "./actions";

const initialState = {
  showError: false,
  errorMessage: "",
};

const httpError = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case HTTP_500_ERROR:
      //   action.props.history.push("/500");
      return { ...state, showError: true };
    case HTTP_CLEAR_ERROR:
      //console.log(action.type);
      //   action.props.history.push("/500");
      return { ...initialState };
    default:
      return state;
  }
};
export default httpError;
