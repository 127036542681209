import { hmma_FORMAT, MMDDYYYY_FORMAT } from "../../../constants/general-const";

var moment = require("moment");
export default function PastCard(props: any) {
  const { item } = props;
  return (
    <>
      <div className="pastCard-container">
        <div className="pastCard-description-container">
          <p className="pastCard-description">
            {`On ${moment(item.appointment_date_time).format(
              MMDDYYYY_FORMAT
            )} at ${moment(item.appointment_date_time).format(
              hmma_FORMAT
            )} for ${item.duration} Mins, with ${item.provider_first_name} ${
              item.provider_last_name
            }, ${item.specialty}
             at ${item.location}`}
          </p>
          <p className="pastCard-reason">{`Reason ${item.comments}`}</p>
        </div>
        <div className="pastCard-results-container">
          <button className="viewLinkBtn">View Notes</button>
          <button className="viewLinkBtn">View Results</button>
        </div>
      </div>
    </>
  );
}
