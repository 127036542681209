import React from "react";
import { Routes, Route } from "react-router-dom";
import BookAppointment from "./pages/bookAppointment/BookAppointment";
import YourAppointments from "./pages/yourAppointments/YourAppointments";

export const Router = () => {
  return (
    <Routes>
      {/* <Route path="/profile" element={<PatientProfile />} /> */}
      <Route path="/" element={<BookAppointment />} />
      <Route path="/your-appointments" element={<YourAppointments />} />
    </Routes>
  );
};
