import { CancelTokenSource } from "axios";
// import { ICallCenterCouponProps } from "../../components/Coupon/types";

export enum AppointmentBotMessageTypes {
  EMERGENCY_SYMPTOMS = "EmergencySymptoms",
  PAYMENT_OPTION_CREDIT_CARD = "SelectedCreditCard",
  GET_MRI = "Get_Mri",
  TYPES_OF_MRI = "TypesOfMri",
  ASK_TO_BOOK_PROVIDER_SLOT = "askToBookProviderSlot",
  HANDLE_URGENT_CARE_REQUEST = "handleUrgentCareRequest",
  BOOK_APPOINTMENT_ERROR = "bookAppointmentError",
  PRIMARY_PROVIDER_UNAVIALIABLE = "primaryProviderUnavialiable",
  PRINT_INSTRUCTIONS = "printInstructions",
  RESCHEDULE_APPOINTMENT_ERROR = "rescheduleAppointmentError",
  APPOINTMENT_BOOKED = "appointmentBooked",
  ASK_FOR_HEALTH_PLAN = "AskForHealthPlan",
  GET_TREATMENT_COST = "GetTreatmentCost",
  BOOKING_COMPLETED = "BookingCompleted",
  FORM_SUBMITTED_MSG = "FormSubmittedMsg",
  RESCHEDULE_CANCEL_APPOINTMENT = "cancel_reschedule_appointment",
  BOT_SUCCESS = "botSuccess",
  EMERGENCY_MSG = "EmergencyMsg",
  USER = "user",
  DEFAULT = "default",
  DATA_NOT_FOUND = "DataNotFound",
  ERROR_MESSAGE = "error",
  NOT_FOUND = "notFound",
  AVAILABLE_DOCTORS = "AvailableDoctorTypes",
  COUPON = "CouponIntent",
  COUPON_SEARCH = "CouponSearch",
  ACCOUNT = "Account",
  LOG_DOCTOR_VISIT_SCHEDULED = "LogDoctorVisitScheduled",
  LOG = "Log",
  CALL_CENTER = "CallCenter",
  CALL_CENTER_COUPON = "CallCenterCoupon",
  INDIVIDUAL_PROVIDER_LOOKUP = "IndividualProviderLookup",
  BOOK_APPOINTMENT = "ProviderBookAppointment",
  MEDICATIONS = "Medications",
  TREATMENTS = "Treatments",
  PROCEDURES = "Procedures",
  CONDITIONS_TEXT = "CONDITIONS_TEXT",
  PROCEDURE_TEXT = "ProcedureText",
  SUGGESTIONS = "Suggestions",
  LOG_ACTIVITY = "LogActivity",
  FUZZY_SEARCH = "FuzzySearch",
  MULTIPLE_SERVICES = "MultipleServices",
  ASK_VISIT_REASON = "AskVisitReason",
  FIND_MORE_SLOTS = "FindMoreSLOTS",
}

export interface IUtteranceRequestMetadata {
  id?: string;
  canceled: boolean;
  cancelToken?: CancelTokenSource;
}

export interface ISavedConversation {
  messages: IAppointmentBotMessage[];
  className?: string;
  displayMessagesInOrder?: boolean;
}

export interface IAppointmentBotMessage {
  id?: string;
  additionalClasses?: string;
  message?: string;
  description?: string;
  Message?: string;
  Prompt?: string;
  doctorType?: string;
  whattheydo?: string;
  doctortreatments?: [any];
  treatments?: [any];
  dialogState?: string;
  slotToElicit?: string;
  doctorservices?: [any];
  type: AppointmentBotMessageTypes | string;
  intentName?: string;
  log?: {};
  entityName?: [any];
  entityValue?: string;
  _log?: {
    type: string;
    value: string;
    utterance: string;
  };
  SubpromptHTML?: any;
  Forms?: {
    Submit: {
      Label: string;
      Utterance?: string;
    };
  }[];
  slots?: any;
  goodRx?: any;
  conditionname?: string;
  fuzzyChoices?: {
    name: string;
    slug: string;
    type: string;
    fuzzyscore: number;
  }[];
  drugname?: string;
  dosage?: string;
  quantity?: string;
  utterance?: any;
  voicefile?: string;
  pharmacy?: string;
  coupon?: any;
  errorMessage?: string;
  errorType?: string;
  doctorvisits?: any;
  logClassName?: string;
  // callCenterCoupon?:ICallCenterCouponProps,
  Provider?: IHealthcareProvider[];
  drugInfo?: any;
  diseaseName?: string;
  treatmentName?: string;
  doctorInfo?: any;
  medicationInfo?: any;
  treatmentInfo?: any;
  data?: any;
}

export interface IHealthcareProvider {
  Healthcare_Provider_Taxonomy_Code_1: string;
  NPI: string;
  Provider_Business_Practice_Location_Address_City_Name: string;
  Provider_Business_Practice_Location_Address_Country_Code: string;
  Provider_Business_Practice_Location_Address_Fax_Number: string;
  Provider_Business_Practice_Location_Address_Postal_Code: string;
  Provider_Business_Practice_Location_Address_State_Name: string;
  Provider_Business_Practice_Location_Address_Telephone_Number: string;
  Provider_Credential_Text: string;
  Provider_First_Line_Business_Practice_Location_Address: string;
  Provider_First_Name: string;
  Provider_Last_Name: string;
  Provider_Middle_Name: string;
  Provider_Name_Prefix_Text: string;
  Provider_Name_Suffix_Text: string;
  Provider_Second_Line_Business_Practice_Location_Address: string;
  class1: string;
  class2: string;
  class3: string;
  class4: string;
  class5: string;
  class6: string;
  class7: string;
  class8: string;
  class9: string;
  class10: string;
  [key: string]: any;
}
