export const URL_POST_APPOINTMENT = "/appointment/save";
export const URL_LIST_APPOINTMENTS = "/appointment/";
export const URL_PUT_APPOINTMENT = "/appointment/";
export const URL_VISIT_REASON = "/visit_reason/";

// allscripts urls
export const URL_GET_PATIENT_DEMOGRAPHY = "/patient/pm_details/";
export const URL_GET_LOCATION_LIST = "/provider/locations";
export const URL_GET_TIME_SLOTS = "/provider/time-slot";

//nluURL
export const URL_GET_PROVIDER_SLOTS = "/provider/search";
export const URL_USER_LOGIN = "/user/login";
export const URL_GET_PROFILE_INFO = "/profiles/search";
export const URL_USER_REGISTER = "/user/v2/register";
export const URL_USER_FORGOT_PASSWORD = "/user/forgot_password";
export const URL_USER_CONFIRM_FORGOT_PASSWORD = "/user/confirm_forgot_password";
export const URL_USER_CONFIRM_SIGNUP = "/user/confirm_signup";
