import * as React from "react";
import { hmma_FORMAT, MMMMDo_FORMAT } from "../../../constants/general-const";
import { FormContainer } from "../appointmentForms/FormContainer";
import { FormField } from "../appointmentForms/FormField";

var moment = require("moment");
export default function ProviderCard(props: any) {
  const {
    item,
    keyval,
    handleBookAppointmentWithNewProv,
    handleGetAllSlotsForProvider,
  } = props;
  return (
    <div className="provider-card-container" key={keyval}>
      <p className="provider-card-username">{`${item.FirstName} ${item.LastName} (${item.Suffix})`}</p>
      <div className="provider-card-timing-container">
        <div className="provider-card-modal-buttons">
          <div className="formContainer">
            <FormContainer className="appointment-button-container">
              {item.TimeSlotData.length ? (
                <FormField
                  label={`Book Appointment on ${moment(
                    new Date(item.TimeSlotData[0].Available_Date)
                  ).format(MMMMDo_FORMAT)} at, ${moment(
                    new Date(
                      `${item.TimeSlotData[0].Available_Date} ${item.TimeSlotData[0].Start_Time}`
                    )
                  ).format(hmma_FORMAT)}`}
                  className="book-appointment-button"
                  onClick={() => handleBookAppointmentWithNewProv(item)}
                />
              ) : null}
              <FormField
                label={`Find All Time Slots
              with ${item.FirstName} ${item.LastName}`}
                className="book-appointment-button"
                onClick={() => handleGetAllSlotsForProvider(item)}
              />
            </FormContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
