import React, { useState } from "react";
import { useId } from "@fluentui/react-hooks";
import {
  DefaultButton,
  IconButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IIconProps,
  DatePicker,
  defaultDatePickerStrings,
} from "@fluentui/react";
import { withLoaderAndNotifyOnApiLoad } from "../../../hoc/withLoaderAndNotifyOnApiLoad";
import { useUpcomingAppointment } from "../../../hooks/upcomingAppointment/useUpcomingAppointment";
import { useSelector } from "react-redux";
import {
  ALLSCRIPTS_APPOINTMENT_STATUS,
  CANCEL_APPOINTMENT_COMMENT,
  CANCEL_REASON,
  CANCEL_REASON_FOR_STATUS_CHANGE,
  hmma_FORMAT,
  MMMMDoYYYYhmma_FORMAT,
  PRO_PM_TOKEN_NAME,
} from "../../../constants/general-const";
import { Mixpanel } from "../../../utils/mixpanel";
import moment from "moment";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "100%" },
  dropdownOptionText: { overflow: "visible", whiteSpace: "normal" },
};

export const BaseCancelAppointmentsModal: any = (props: any) => {
  const patientId = useSelector(
    (state: any) => state.isLoggedIn.patientDemog.Patient_ID
  );
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const PRO_PM_TOKEN = sessionStorage.getItem(PRO_PM_TOKEN_NAME);
  const { isModalOpen, appointmentData, handleDeleted, closeModal } = props;
  const { cancelAppointment } = useUpcomingAppointment();
  const [errorMsg, setErrorMsg] = useState("");

  const titleId = useId("title");

  const [formData, setFormData] = useState({ ...appointmentData });

  const onValueChange = (event: any) => {
    const values = { ...formData, [event.target.name]: event.target.value };
    setFormData(values);
  };

  const onDropdownValueChange = (event: any, item: any) => {
    const values = { ...formData, [event.target.id]: item.key };
    setFormData(values);
  };

  const handleSubmit = () => {
    props.handleLoading(true);
    setErrorMsg("");
    const convertedPayload = {
      id: { S: formData.allscripts_appointment_id },
      allscripts_appointment_id: {
        N: parseInt(formData.allscripts_appointment_id),
      },
      patient_id: { S: patientId },
      token: { S: PRO_PM_TOKEN },
      cancellation_comment: { S: CANCEL_APPOINTMENT_COMMENT },
      reason_for_status_change: { N: CANCEL_REASON_FOR_STATUS_CHANGE },
      allscripts_appointment_status: { S: ALLSCRIPTS_APPOINTMENT_STATUS },
    };
    cancelAppointment(convertedPayload)
      .then((res) => {
        props.handleNotification({
          type: "success",
          message: "Data Updated Successfully",
        });
        Mixpanel.track("Cancel-Success", {
          outcome: "Cancelled Appointment",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: formData.provider,
          dateTime: moment(new Date(formData.appointment_date_time)).format(
            MMMMDoYYYYhmma_FORMAT
          ),
          visitReason: formData.reason,
          appointmentType: formData.appointment_type,
        });
        handleDeleted({
          id: formData.allscripts_appointment_id,
          allscripts_appointment_id: parseInt(
            formData.allscripts_appointment_id
          ),
          patient_id: patientId,
          token: PRO_PM_TOKEN,
          cancellation_comment: CANCEL_APPOINTMENT_COMMENT,
          reason_for_status_change: CANCEL_REASON_FOR_STATUS_CHANGE,
          allscripts_appointment_status: ALLSCRIPTS_APPOINTMENT_STATUS,
        });
        closeModal();
      })
      .catch((error) => {
        if (error.response && error.response.status === 504) {
          setErrorMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error Occured while cancelling the appointment please try again later"
          );
        } else {
          props.handleNotification({
            type: "warning",
            message:
              error.response && error.response.data.message
                ? error.response.data.message
                : "Error Occured while cancelling the appointment please try again later",
          });
        }
        Mixpanel.track("Cancel-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: formData.provider,
          dateTime: moment(new Date(formData.appointment_date_time)).format(
            MMMMDoYYYYhmma_FORMAT
          ),
          visitReason: formData.reason,
          appointmentType: formData.appointment_type,
        });
      })
      .finally(() => {
        props.handleLoading(false);
      });
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="modalContainer"
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Cancel Appointment</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModal}
          />
        </div>
        <div className={contentStyles.body}>
          {errorMsg ? <p className="form-error-text">{errorMsg}</p> : null}
          <div className={contentStyles.appointmentValueContainer}>
            <h4> You are about to cancel your appointment with</h4>
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>Provider</h4>
            <p>{formData.provider_full_name}</p>
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>On</h4>
            <DatePicker
              disabled
              style={{ marginTop: 10 }}
              value={new Date(formData.appointment_date_time)}
              strings={defaultDatePickerStrings}
            />
          </div>
          <div className={contentStyles.appointmentValueContainer}>
            <h4>At</h4>
            <p>{moment(formData.appointment_date_time).format(hmma_FORMAT)}</p>
          </div>
          <Dropdown
            placeholder="Select an option"
            label="Reason for cancellation"
            options={CANCEL_REASON}
            id="reason_for_cancellation"
            onChange={onDropdownValueChange}
            styles={dropdownStyles}
          />
          <TextField
            label="Additional details for cancellation"
            placeholder="add your comments here..."
            onChange={onValueChange}
            name="cancellation_comment"
            multiline
            rows={3}
          />
          <div className="modalActionButtonsContainer">
            <DefaultButton
              text="Cancel Appointment"
              className="modalSaveButton"
              onClick={handleSubmit}
            />
            {/* <DefaultButton
              text="Close"
              onClick={closeModal}
              className="modalCancelButton"
            /> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  appointmentValueContainer: {
    marginBottom: 15,
    "& h4": {
      marginBottom: 4,
    },
    "& p": {
      margin: 0,
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeading: {
    fontWeight: "normal",
    "@media (max-width:320px)": {
      fontSize: "1.5rem",
    },
  },
  modalButton: {
    backgroundColor: "#6DBA6D",
    color: "#fff",
    fontSize: "1.2rem",
    borderRadius: 8,
    border: "none",
    padding: "20px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#64A964",
    },
    "@media (max-width:320px)": {
      padding: "8px",
      fontSize: "1rem",
    },
  },
  modalButtonsContainer: {
    paddingTop: 20,
  },
  modalSaveButton: {
    backgroundColor: "#6DBA6D",
    color: "#fff",
    fontSize: "1.2rem",
    border: "none",
    marginRight: 20,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#64A964",
    },
  },
  modalCancelButton: {
    backgroundColor: "transparent",
    color: "#201F1E",
    fontSize: "1.2rem",
    "&:hover": {
      color: "#201F1E",
      backgroundColor: "#e1e0e0",
    },
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const CancelAppointmentsModal: any = withLoaderAndNotifyOnApiLoad(
  BaseCancelAppointmentsModal
);
