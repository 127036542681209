import React from "react";
import { ActionButton, IIconProps, IButtonStyles } from "@fluentui/react";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { NavBar } from "../../components/navBar/NavBar";
import { useWindowSize } from "../../hooks/windowSize/useWindowSize";
import { Router } from "../../routes";
import { userLogout } from "../../store/user/actions";
import { Mixpanel } from "../../utils/mixpanel";

const SignOut: IIconProps = {
  iconName: "SignOut",
  styles: {
    root: {
      color: "white",
      selector: {
        ".ms-Button-icon:hover &": {
          color: "#595d8f",
        },
      },
    },
  },
};

const ButtonStyle: IButtonStyles = {
  label: {
    color: "white",
    fontWeight: 600,
  },
  labelHovered: {
    color: "#595d8f",
  },
  rootHovered: {
    backgroundColor: "rgb(243, 242, 241)",
    color: "#595d8f",
    fontWeight: 600,
  },
};

export const Home = (props: any) => {
  const dispatch = useDispatch();
  const [width, height] = useWindowSize();
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  return (
    <div className="homeContainer" style={{ height: height }}>
      <div className="topHeader">
        <NavBar />
        <ActionButton
          styles={ButtonStyle}
          iconProps={SignOut}
          name="SignOut"
          className="signOutButton"
          onClick={async () => {
            Mixpanel.track("Sign-Out", {
              outcome: "User Sign Out Clicked",
              email: patientDetails.email,
              mrn: patientDetails["custom:mrn"],
            });
            await Auth.signOut({ global: true });
            window.location.reload();
            sessionStorage.removeItem("token");
            dispatch(userLogout());
          }}
          text={width <= 620 ? "" : "Sign Out"}
        ></ActionButton>
      </div>
      <div className="routes">
        <Router />
      </div>
    </div>
  );
};
