import React, { useEffect, useRef, useState } from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import ChatRobotImage from "../../assets/visits/CMM_Icon_OnlineScheduling.png";
import { useBot } from "../../hooks/appointmentBot/bot/useBot";
import { Search } from "../search/Search";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../utils/mixpanel";

type DefaultBotMessageBaseProps = {
  dispatchData: (
    utterance: string | null,
    request_type: string,
    service_type: string | null,
    ask_visit_reason: boolean | null,
    service?: any
  ) => void;
};

const DefaultBotMessageBase = ({
  dispatchData,
}: DefaultBotMessageBaseProps) => {
  const unmounted = useRef(false);
  const patientDetails = useSelector(
    (state: any) => state.isLoggedIn.patientDemog
  );
  const loggedInPatientDetails = useSelector(
    (state: any) => state.isLoggedIn.info
  );
  const { getVisitReasonList } = useBot();
  const [listOfReasons, setListOfReasons] = useState([]);
  useEffect(() => {
    getVisitReasonList({
      age: patientDetails.Age,
      gender: patientDetails.Sex,
      patient_id: patientDetails.Patient_ID,
    })
      .then((res: any) => {
        if (unmounted.current) return;
        Mixpanel.track("Visit-Reason", {
          outcome: "Shown Visit Reason List For Below Patient",
          patientNumber: patientDetails.Patient_Number,
          email: loggedInPatientDetails.email,
          mrn: loggedInPatientDetails["custom:mrn"],
        });
        setListOfReasons(res?.response);
      })
      .catch((error) => {
        Mixpanel.track("Visit-Reason-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          patientNumber: patientDetails.Patient_Number,
          email: loggedInPatientDetails.email,
          mrn: loggedInPatientDetails["custom:mrn"],
        });
      });
  }, [getVisitReasonList]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <div className="default-chat-bot-section">
      <img
        src={ChatRobotImage}
        alt="chat bot avatar"
        className="chat-robot-image"
      />
      <div className="default-chat-bot-buttons-container">
        <div className="default-chat-bot-buttons-message">
          {`Find`} <b>{patientDetails.First_Name}</b>
          {` an appointment slot for:`}
        </div>
        <div className="default-chat-bot-buttons">
          {listOfReasons && listOfReasons.length
            ? listOfReasons.map(
                (reason: { id: number; name: string }, index) => {
                  return (
                    <div key={reason.id}>
                      <DefaultButton
                        key={index}
                        text={reason.name}
                        allowDisabledFocus
                        className="book-appointment-button"
                        onClick={() =>
                          dispatchData(
                            null,
                            "service_selection",
                            "single_service",
                            null,
                            reason
                          )
                        }
                      />
                    </div>
                  );
                }
              )
            : null}
        </div>
        <span className="default-chat-bot-buttons-message2">
          {`OR search for slots by reason: `}{" "}
        </span>
        <Search dispatchData={dispatchData} />
      </div>
    </div>
  );
};
export const DefaultBotMessage = React.memo(DefaultBotMessageBase);
