import { MutableRefObject } from "react";

export const convertPayloadDataFormat = (payload: any) => {
  type Payload = typeof payload;
  let payloadKeys = Object.keys(payload);

  const newPayload = {} as any;

  for (const key of payloadKeys) {
    // let value = payload[key as keyof Payload];
    // if (typeof value !== "undefined" && value) {
    newPayload[key] = { S: payload[key as keyof Payload] };
    // }
  }

  return newPayload;
};
export const convertServerDataToPayload = (payload: any) => {
  type Payload = typeof payload;
  let payloadKeys = Object.keys(payload);
  const newPayload = {} as any;

  for (const key of payloadKeys) {
    if (
      typeof payload[key as keyof Payload]["S"] !== "undefined" &&
      payload[key as keyof Payload]["S"]
    ) {
      newPayload[key] = payload[key as keyof Payload]["S"];
    } else if (
      typeof payload[key as keyof Payload]["N"] !== "undefined" &&
      payload[key as keyof Payload]["N"]
    ) {
      newPayload[key] = payload[key as keyof Payload]["N"];
    } else if (
      typeof payload[key as keyof Payload]["BOOL"] !== "undefined" &&
      payload[key as keyof Payload]["BOOL"]
    ) {
      newPayload[key] = payload[key as keyof Payload]["BOOL"];
    }
  }
  return newPayload;
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
}

export const scrollToObject = (ref: MutableRefObject<any>) => {
  ref.current?.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
  });
};

export const convertDateTimeTohhmmampm = (str: string) => {
  // let  = str.length === 5 ? +str.substring(0, 2) : +str.substring(0, 1);
  let Hour = +str.substring(0, 2);
  let Minutes = str.substring(2);
  // //console.log(Hour);
  // //console.log(Minutes);
  // let Minutes = str.length === 4 ? str.substring(2) : str.substring(1);
  var h = Hour % 12 || 12;
  var ampm = Hour < 12 || Hour === 24 ? " AM" : " PM";
  const convertedDateTime = h + Minutes + ampm;
  return convertedDateTime;
};

export const convertISOdatetime = (payload: any) => {
  var isoDate = new Date(
    payload.appointment_date_time.getTime() -
      payload.appointment_date_time.getTimezoneOffset() * 60000
  ).toISOString();
  return {
    ...payload,
    appointment_date_time: isoDate,
  };
};
