import React, { useEffect, useState } from "react";
import { TextField, MaskedTextField } from "@fluentui/react/lib/TextField";
import { ITextFieldProps, DefaultButton } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import moment from "moment";
import {
  setUserStatus,
  setUserInfo,
  setUserDemog,
  setUserVerificationSent,
} from "../../store/user/actions";
import { initialState } from "./type";
import { usePatientProfile } from "./../../hooks/patientProfile/usePatientProfile";
import jwt_decode from "jwt-decode";
import {
  COUNTRY_CODE,
  MMDDYYYY_FORMAT,
  MMDDYYYY_WITH_HYPHEN_FORMAT,
} from "./../../constants/general-const";
import { TextFieldToolTipLabel } from "../../components/textFieldToolTipLabel/TextFieldToolTipLabel";
import { Mixpanel } from "../../utils/mixpanel";

function SignUp({ handleLoading }: any) {
  const [verification, setVerification] = useState(false);
  const [disableVerify, setDisableVerify] = useState(true);
  const isVerificationSent = useSelector(
    (state: any) => state.userSensitiveInfo.verificationSent
  );
  const [askForMRN, setAskForMRN] = useState(false);
  const dispatch = useDispatch();
  const { userRegister, userLoginInfo, userConfirmSignUP } =
    usePatientProfile();
  const [formData, setFormData] = useState<initialState>({ email: "" });
  const { getPatientDemography } = usePatientProfile();
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState({
    minutes: 0,
    seconds: 59,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const onValueChange = (event: any) => {
    if (event.target.name === "phone") {
      const formattedPhoneNumber = formatPhoneNumber(event.target.value);
      setFormData({ ...formData, phone: formattedPhoneNumber });
    } else if (event.target.name === "dob") {
      if (moment(event.target.value, MMDDYYYY_FORMAT, true).isValid()) {
        setErrorMsg("");
        setFormData({
          ...formData,
          dob: new Date(event.target.value),
        });
      } else {
        setErrorMsg("Please Enter Date of Birth in Required Pattern");
        setFormData({
          ...formData,
          dob: null,
        });
      }
    } else {
      const values = { ...formData, [event.target.name]: event.target.value };
      setFormData(values);
      if (event.target.name === "code" && event.target.value.length > 5) {
        setDisableVerify(false);
      } else if (
        event.target.name === "code" &&
        event.target.value.length <= 5
      ) {
        setDisableVerify(true);
      }
    }
  };
  function formatPhoneNumber(value: any) {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
  const formValidator = () => {
    let valid = true;
    if (!formData?.first_name) {
      valid = false;
      Mixpanel.track("Sign-up-Failed", {
        outcome: "Invalid First Name Entered",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg("Please enter Valid First Name");
      return;
    }
    if (!formData?.last_name) {
      valid = false;
      Mixpanel.track("Sign-up-Failed", {
        outcome: "Invalid Last Name Entered",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg("Please enter Valid Last Name");
      return;
    }
    if (!validator.isEmail(formData.email)) {
      valid = false;
      Mixpanel.track("Sign-up-Failed", {
        outcome: "Invalid Email Entered",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg("Please enter Valid Email");
      return;
    }
    if (!formData?.phone || formData.phone.length < 14) {
      valid = false;
      Mixpanel.track("Sign-up-Failed", {
        outcome: "Invalid Phone Entered",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg("Please enter Valid Phone");
      return;
    }
    if (!moment(formData?.dob, MMDDYYYY_FORMAT, true).isValid()) {
      valid = false;
      Mixpanel.track("Sign-up-Failed", {
        outcome: "Invalid Dob Entered",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg("Please Enter Date of Birth in Required Pattern");
      return;
    }
    if (!formData?.password || formData.password.length < 8) {
      valid = false;
      Mixpanel.track("Sign-up-Failed", {
        outcome: "Invalid Password Entered",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg("Password should be Minimum 8 characters");
      return;
    }
    if (formData?.confirm_password !== formData?.password) {
      valid = false;
      Mixpanel.track("Sign-up-Failed", {
        outcome: "Confirm Password and Login Password not same",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg("Confirm Password should be same with login password");
      return;
    }
    return valid;
  };

  const handleSubmit = async () => {
    Mixpanel.track("Sign-up", {
      outcome: "SignUp clicked",
      email: formData?.email?.toLowerCase(),
    });
    if (formValidator()) {
      handleLoading(true);
      setErrorMsg("");
      const phone = formData.phone?.replace(/[- )(]/g, "");
      let payload;
      if (formData.mrn) {
        payload = {
          first_name: { S: formData.first_name },
          last_name: { S: formData.last_name },
          phone: { S: phone },
          email: { S: formData.email?.toLowerCase() },
          patient_number: { N: +formData.mrn },
          password: { S: formData.password },
          dob: formData.dob
            ? {
                S: moment(new Date(formData.dob)).format(
                  MMDDYYYY_WITH_HYPHEN_FORMAT
                ),
              }
            : "",
          country_code: { S: COUNTRY_CODE },
        };
      } else {
        payload = {
          first_name: { S: formData.first_name },
          last_name: { S: formData.last_name },
          phone: { S: phone },
          email: { S: formData.email?.toLowerCase() },
          password: { S: formData.password },
          dob: formData.dob
            ? {
                S: moment(new Date(formData.dob)).format(
                  MMDDYYYY_WITH_HYPHEN_FORMAT
                ),
              }
            : "",
          country_code: { S: COUNTRY_CODE },
        };
      }
      userRegister({
        ...payload,
      })
        .then((res) => {
          if (res.data && res.data.cognito_response) {
            setErrorMsg(
              `Verification code has been sent to ${res.data.cognito_response.CodeDeliveryDetails.Destination}`
            );

            if (formData.email && formData.password) {
              dispatch(
                setUserVerificationSent({
                  email: formData.email,
                  password: formData?.password,
                  verificationCodeSent: true,
                  sentDestination:
                    res.data.cognito_response.CodeDeliveryDetails.Destination,
                })
              );
            }
            Mixpanel.track("Sign-up-Success", {
              outcome: "Sign Up success, Code sent for Verification",
              email: formData?.email?.toLowerCase(),
            });
            setVerification(!verification);
          } else {
            Mixpanel.track("Sign-up-Failed", {
              outcome: "No Verification code info recieved",
              email: formData?.email?.toLowerCase(),
            });
            setErrorMsg("Sign-up Failed Please Try Again");
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            if (error.response.data.status_code === "provide_patient_number") {
              setAskForMRN(true);
            }
          }
          setErrorMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : "Sign-up Failed Please Try Again"
          );
          Mixpanel.track("Sign-up-Failed", {
            outcome:
              error.response && error.response.data.mp_outcome
                ? error.response.data.mp_outcome
                : "Error Occured without message",
            email: formData?.email?.toLowerCase(),
          });
        })
        .finally(() => handleLoading(false));
    }
  };
  const handleAskForMRN = () => {
    if (formData?.mrn && formData.mrn.length < 3) {
      setErrorMsg("Please Enter a valid MRN ");
      return;
    } else {
      Mixpanel.track("MRN-Provided", {
        outcome: "User has provided the mrn",
        email: formData?.email?.toLowerCase(),
      });
      setAskForMRN(false);
      handleSubmit();
    }
  };

  const signInUser = async () => {
    try {
      setErrorMsg("");
      Mixpanel.track("Sign-in", {
        outcome: "Sign up complete, trying to sigin user now",
        email: formData?.email?.toLowerCase(),
      });
      const userLoggedIn = await userLoginInfo({
        email: { S: formData?.email?.toLowerCase() },
        password: { S: formData?.password },
      });
      let token =
        userLoggedIn.data?.authorization?.AuthenticationResult?.IdToken;
      let securityTokenProPM = userLoggedIn.data?.tokens?.practice_management;
      let securityTokenProEHR = userLoggedIn.data?.tokens?.professional_ehr;

      if (token && securityTokenProPM) {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("PRO_PM_TOKEN", securityTokenProPM);
        sessionStorage.setItem("PRO_EHR_TOKEN", securityTokenProEHR);
        setPatientDemog(token);
      } else {
        setErrorMsg("User Verified, Please Try Sign-In!");
        Mixpanel.track("Sign-in-Failed", {
          outcome: "Missing Tokens",
          email: formData?.email?.toLowerCase(),
        });
      }
    } catch (error: any) {
      Mixpanel.track("Sign-in-Failed", {
        outcome:
          error.response && error.response.data.mp_outcome
            ? error.response.data.mp_outcome
            : "Error Occured without message",
        email: formData?.email?.toLowerCase(),
        status: error.response.status,
      });
      setErrorMsg("User Verified, Please Try Sign-In!");
      handleLoading(false);
    }
  };

  const setPatientDemog = (token: any) => {
    if (token) {
      let decodeUser: any = jwt_decode(token);
      Mixpanel.track("Sign-in-Sucess", {
        outcome: "Success Sign in",
        email: formData?.email?.toLowerCase(),
      });
      Mixpanel.people.set({
        $email: formData?.email?.toLowerCase(),
        MRN: decodeUser["custom:mrn"],
      });
      Mixpanel.identify(decodeUser["custom:mrn"]);
      getPatientDemography(decodeUser["custom:mrn"])
        .then((response) => {
          dispatch(setUserDemog({ ...response.pm }));
          if (decodeUser) {
            dispatch(setUserInfo(decodeUser));
          }
          dispatch(setUserStatus(true));
        })
        .catch((error) => {
          Mixpanel.track("PatientDemography-Failed", {
            outcome:
              error.response && error.response.data.mp_outcome
                ? error.response.data.mp_outcome
                : "Error Occured without message",
            email: formData?.email?.toLowerCase(),
            mrn: decodeUser["custom:mrn"],
          });
          setErrorMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : "We encountered an issue Please Retry"
          );
          handleLoading(false);
        });
    } else {
      setErrorMsg("User Verified, Please Try Sign-In!");
      Mixpanel.track("Sign-in-Failed", {
        outcome: "Missing Tokens",
        email: formData?.email?.toLowerCase(),
      });
    }
  };

  const handleVerification = async () => {
    handleLoading(true);
    if (formData?.email && formData?.code) {
      try {
        setErrorMsg("");
        await userConfirmSignUP({
          email: { S: formData.email.toLowerCase() },
          code: { S: formData.code },
        });
        Mixpanel.track("User-Confirmed", {
          outcome: "Sign Up steps complete, User Verified",
          email: formData?.email?.toLowerCase(),
        });
        setVerification(!verification);
        signInUser();
      } catch (err: any) {
        err.response && err.response.data.message
          ? setErrorMsg(err.response.data.message)
          : setErrorMsg("Something wrong happened please try again");
        Mixpanel.track("User-Confirmed-Failed", {
          outcome:
            err.response && err.response.data.mp_outcome
              ? err.response.data.mp_outcome
              : "Error Occured without message",
          email: formData?.email?.toLowerCase(),
        });
        handleLoading(false);
      }
    } else {
      handleLoading(false);
      setErrorMsg("Please Enter a Valid Verify Code");
      Mixpanel.track("User-Verify-Failed", {
        outcome: "Verify Clicked Without Code",
        email: formData?.email?.toLowerCase(),
      });
    }
  };
  const handleResend = async () => {
    try {
      if (validator.isEmail(formData.email)) {
        handleLoading(true);
        const user = await Auth.resendSignUp(formData.email);
        handleLoading(false);
        setIsTimerActive(true);
        setTimeout(() => {
          setIsTimerActive(false);
          setTimer({
            minutes: 0,
            seconds: 59,
          });
        }, 60000);
        Mixpanel.track("Verify-Code-Resend", {
          outcome: "Verification code resent",
          email: formData?.email?.toLowerCase(),
        });
        setErrorMsg(
          `Code resent successfully to ${user.CodeDeliveryDetails.Destination}`
        );
      }
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        setErrorMsg(error.response.data.message);
        Mixpanel.track("Verify-Code-Resend-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: formData?.email?.toLowerCase(),
        });
      } else {
        setErrorMsg("please try again");
        Mixpanel.track("Verify-Code-Resend-Failed", {
          outcome: "Error Occured without message",
          email: formData?.email?.toLowerCase(),
        });
      }
      handleLoading(false);
    }
  };

  useEffect(() => {
    if (isTimerActive) {
      let myInterval = setInterval(() => {
        if (timer.seconds > 0) {
          setTimer({
            ...timer,
            seconds: timer.seconds - 1,
          });
        }
        if (timer.seconds === 0) {
          if (timer.minutes === 0) {
            clearInterval(myInterval);
          } else {
            setTimer({
              minutes: timer.minutes - 1,
              seconds: 59,
            });
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [isTimerActive, timer]);
  useEffect(() => {
    // console.log(isVerificationSent);
    if (isVerificationSent.verificationCodeSent) {
      Mixpanel.track("Sign-up", {
        outcome: "Sign Up Clicked, user was not verified earlier asked again",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg(
        `Verification code was sent to ${isVerificationSent.sentDestination}, Please enter the code or try resend`
      );
      const values = {
        ...formData,
        email: isVerificationSent.email,
        password: isVerificationSent.password,
      };
      setFormData(values);
      setVerification(true);
    }
  }, []);
  const labelId: string = useId("label");
  const onRenderLabel = (
    props: ITextFieldProps | undefined,
    toolTipDescription: string
  ) => {
    return (
      <TextFieldToolTipLabel
        id={labelId}
        toolTipDescription={toolTipDescription}
        {...props}
      />
    );
  };

  return (
    <>
      <div className="sign-up-container">
        {errorMsg ? <p className="form-error-text">{errorMsg}</p> : null}
        <form className="sign-up-form">
          {!verification && !askForMRN ? (
            <>
              <div className="username-fields-container">
                <TextField
                  label="First Name"
                  placeholder="First Name"
                  className="firstName-field"
                  name="first_name"
                  value={formData?.first_name}
                  onChange={onValueChange}
                  required
                />
                <TextField
                  label="Last Name"
                  placeholder="Last Name"
                  className="lastName-field"
                  name="last_name"
                  value={formData?.last_name}
                  onChange={onValueChange}
                  required
                />
              </div>
              <div className="email-field-container">
                <TextField
                  label="Email"
                  placeholder="Enter email address"
                  name="email"
                  value={formData?.email}
                  onChange={onValueChange}
                  required
                />
              </div>
              <div className="date-field-container">
                <TextField
                  label="Mobile Phone No."
                  placeholder="(000) 000-0000"
                  name="phone"
                  value={formData?.phone}
                  className="phone-number-field"
                  onChange={onValueChange}
                  required
                />
                <div className="date-picker-container">
                  <MaskedTextField
                    label="Date of birth"
                    placeholder="MM/DD/YYYY"
                    style={{ width: "100%" }}
                    name="dob"
                    mask="99/99/9999"
                    maskChar=""
                    onChange={onValueChange}
                    required
                  />
                </div>
              </div>
              <div className="password-fields-container">
                <TextField
                  label="Password"
                  placeholder="Enter password"
                  type="password"
                  canRevealPassword
                  revealPasswordAriaLabel="Show password"
                  className="password-field"
                  name="password"
                  value={formData?.password}
                  onChange={onValueChange}
                  required
                  onRenderLabel={(props) =>
                    onRenderLabel(
                      props,
                      `Password should be Minimum 8 characters`
                    )
                  }
                />
                <TextField
                  label="Confirm Password"
                  placeholder="Confirm password"
                  type="password"
                  canRevealPassword
                  revealPasswordAriaLabel="Show password"
                  className="confirm-password-field"
                  name="confirm_password"
                  value={formData?.confirm_password}
                  onChange={onValueChange}
                  required
                />
              </div>
            </>
          ) : null}
          {askForMRN && !verification ? (
            <div className="medical-field-container">
              <TextField
                label="Medical Record Number"
                placeholder="Medical Record Number"
                className="medical-record-field"
                name="mrn"
                value={formData?.mrn}
                onChange={onValueChange}
                required
                onRenderLabel={(props) =>
                  onRenderLabel(
                    props,
                    `The system requires that you are an existing patient with Medical
                  Record Number (MRN) in addition to having a mobile phone and
                  email. Your MRN can be found on patient paperwork, past visit
                  summary or you can contact Colorado Mountain Medial on
                  970-926-6340 to request it.`
                  )
                }
              />
              <DefaultButton
                text="Verify"
                className="verify-mrn"
                onClick={handleAskForMRN}
              />
            </div>
          ) : null}
          {verification && !askForMRN ? (
            <>
              <div className="verification-code-field-container">
                <TextField
                  placeholder="Enter verification code"
                  className="verification-field"
                  name="code"
                  value={formData?.code}
                  onChange={onValueChange}
                />
                <DefaultButton
                  text="Verify"
                  className="send-again-button"
                  disabled={disableVerify}
                  onClick={handleVerification}
                />
                <DefaultButton
                  disabled={isTimerActive}
                  text={
                    isTimerActive
                      ? `${
                          timer.minutes >= 10
                            ? timer.minutes
                            : `0${timer.minutes}`
                        } : ${
                          timer.seconds >= 10
                            ? timer.seconds
                            : `0${timer.seconds}`
                        }`
                      : "Resend Code"
                  }
                  className="send-again-button"
                  onClick={handleResend}
                />
              </div>
            </>
          ) : null}
          {!verification && !askForMRN ? (
            <div className="sign-up-button-container">
              <DefaultButton
                text="Register"
                disabled={verification}
                className={
                  verification ? "sign-up-button-toggle" : "sign-up-button"
                }
                onClick={handleSubmit}
              />
            </div>
          ) : null}
        </form>
      </div>
    </>
  );
}

export default SignUp;
