import React, { useRef } from "react";
import { useId } from "@fluentui/react-hooks";
import {
  DefaultButton,
  IconButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { ITextField, TextField } from "@fluentui/react/lib/TextField";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IIconProps,
} from "@fluentui/react";
import { withLoaderAndNotifyOnApiLoad } from "../../../hoc/withLoaderAndNotifyOnApiLoad";

interface AskForVisitReasonModalProps {
  isModalOpen: boolean;
  handleModalSubmit: (visitReason: string | undefined | null) => void;
  closeModal: () => void;
  userUtternace: string;
}

const BaseAskForVisitReasonModal = ({
  isModalOpen,
  handleModalSubmit,
  closeModal,
  userUtternace,
}: any) => {
  const nameRef = useRef<ITextField>(null);
  const titleId = useId("title");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleModalSubmit(nameRef.current?.value);
  };

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="modalContainer confirmBooking"
        scrollableContentClassName="modalContent"
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Visit Reason</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModal}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={contentStyles.body}>
            <div className={contentStyles.appointmentValueContainer}>
              <p>{`What is the reason for your ${userUtternace} visit?`} </p>
            </div>
            {/* <div className={contentStyles.appointmentValueContainer}>
            <h4>For</h4>
            <p></p>
          </div> */}

            <TextField
              // label="Please describe the reason for your visit"
              placeholder="e.g. fever, high cholesterol etc."
              name="visit_reason"
              // multiline
              // rows={3}
              componentRef={nameRef}
              required
              autoComplete="off"
            />
            <div className="modalActionButtonsContainer">
              <DefaultButton
                type="submit"
                text="Submit"
                className="modalSaveButton"
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  appointmentValueContainer: {
    marginBottom: 15,
    "& h4": {
      marginBottom: 4,
    },
    "& p": {
      margin: 0,
    },
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const AskForVisitReasonModal: any = withLoaderAndNotifyOnApiLoad(
  BaseAskForVisitReasonModal
);
