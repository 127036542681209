import {
  Callout,
  Stack,
  IStackTokens,
  IconButton,
  IButtonStyles,
  IStackStyles,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";

const stackTokens: IStackTokens = {
  childrenGap: 4,
  maxWidth: 300,
};
const labelCalloutStackStyles: Partial<IStackStyles> = {
  root: { padding: 20 },
};
const iconButtonStyles: Partial<IButtonStyles> = { root: { marginBottom: -3 } };
const iconProps = { iconName: "Info" };

export const TextFieldToolTipLabel = (props: any): JSX.Element => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const descriptionId: string = useId("description");
  const iconButtonId: string = useId("iconButton");

  return (
    <>
      <Stack horizontal verticalAlign="center" tokens={stackTokens}>
        <span id={props.id} className={props.required ? "requiredLabel" : ""}>
          {props.label}
        </span>
        <IconButton
          id={iconButtonId}
          iconProps={iconProps}
          title="Info"
          ariaLabel="Info"
          onClick={toggleIsCalloutVisible}
          styles={iconButtonStyles}
        />
      </Stack>
      {isCalloutVisible && (
        <Callout
          target={"#" + iconButtonId}
          setInitialFocus
          onDismiss={toggleIsCalloutVisible}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
        >
          <Stack
            tokens={stackTokens}
            horizontalAlign="start"
            styles={labelCalloutStackStyles}
          >
            <span id={descriptionId}>{props.toolTipDescription}</span>
          </Stack>
        </Callout>
      )}
    </>
  );
};
