import React, { useState, useCallback } from "react";
import { useId } from "@fluentui/react-hooks";
import {
  DefaultButton,
  IconButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IIconProps,
} from "@fluentui/react";
import { withLoaderAndNotifyOnApiLoad } from "../../../hoc/withLoaderAndNotifyOnApiLoad";
import { convertPayloadDataFormat } from "../../../utils/helpers";
import TimeSlot from "./TimeSlot";
import { useUpcomingAppointment } from "../../../hooks/upcomingAppointment/useUpcomingAppointment";
import { useSelector } from "react-redux";
import {
  COVERAGE_TYPE,
  MMMMDoYYYYhhmmssa_FORMAT,
  MMMMDoYYYYhmma_FORMAT,
  PRO_PM_TOKEN_NAME,
  REFERING_DOCTOR,
  RESCHEDULE_ALLSCRIPTS_APPOINTMENT_STATUS,
  RESCHEDULE_REASON_FOR_STATUS_CHANGE,
} from "../../../constants/general-const";
import { Mixpanel } from "../../../utils/mixpanel";
import moment from "moment";

export const BaseRescheduleAppointmentsModal: any = (props: any) => {
  const patientId = useSelector(
    (state: any) => state.isLoggedIn.patientDemog.Patient_ID
  );
  const PRO_PM_TOKEN = sessionStorage.getItem(PRO_PM_TOKEN_NAME);
  // const patientDetails = useSelector(
  //   (state: any) => state.isLoggedIn.patientDemog
  // );

  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);

  const {
    isModalOpen,
    closeModal,
    changeWeek,
    handleUpdated,
    appointmentData,
  } = props;
  const titleId = useId("title");
  const { rescheduleAppointment } = useUpcomingAppointment();
  const [formData, setFormData] = useState({ ...appointmentData });
  const [confirmSlot, setConfirmSlot] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const convertISOdatetime = (payload: any) => {
    var isoDate = new Date(
      payload.appointment_date_time.getTime() -
        payload.appointment_date_time.getTimezoneOffset() * 60000
    ).toISOString();
    return {
      ...payload,
      appointment_date_time: isoDate,
    };
  };

  const handleSubmit = () => {
    props.handleLoading(true);
    setErrorMsg("");
    const payload = convertISOdatetime(formData);
    const arr_appt = payload.appointment_date_time.split(".");
    let forcedSlotInfo = {};
    if (formData?.save_as_appointment_type) {
      if (
        !formData?.appointment_type.includes(formData?.save_as_appointment_type)
      ) {
        forcedSlotInfo = {
          forced: { S: true },
        };
      }
    }
    const convertedPayload = convertPayloadDataFormat({
      id: formData.allscripts_appointment_id,
      location: formData.location_abbreviation,
      provider: formData.provider_abbr,
      department: formData.department,
      appointment_type: formData.appointment_type,
      duration: formData.duration,
      patient_id: patientId,
      token: PRO_PM_TOKEN,
      refer_dr: REFERING_DOCTOR,
      coverage_type: COVERAGE_TYPE,
      comments: formData.comments,
      appointment_date_time: arr_appt[0] + ".842073+0000",
      allscripts_appointment_status: RESCHEDULE_ALLSCRIPTS_APPOINTMENT_STATUS,
      save_as_appointment_type: formData.save_as_appointment_type,
      forced: formData.forced,
    });
    rescheduleAppointment({
      ...convertedPayload,
      ...forcedSlotInfo,
      reason_for_status_change: { N: +RESCHEDULE_REASON_FOR_STATUS_CHANGE },
      allscripts_appointment_id: {
        N: parseInt(formData.allscripts_appointment_id),
      },
    })
      .then((res) => {
        props.handleNotification({
          type: "success",
          message: "Data Updated Successfully",
        });

        Mixpanel.track("Reschedule-Success", {
          outcome: "Reschedule Appointment",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: formData.provider,
          originalAppointmentSlot: moment(
            new Date(formData.previous_appointment_date_time)
          ).format(MMMMDoYYYYhmma_FORMAT),
          NewAppointmentSlot: moment(
            new Date(formData.appointment_date_time)
          ).format(MMMMDoYYYYhmma_FORMAT),
          visitReason: formData.reason,
          appointmentType: formData.appointment_type,
        });
        handleUpdated({ ...formData });
        closeModal();
      })
      .catch((error) => {
        if (error.response && error.response.status === 504) {
          setErrorMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error Occured while rescheduling the appointment please try again later"
          );
        } else {
          props.handleNotification({
            type: "warning",
            message:
              error.response && error.response.data.message
                ? error.response.data.message
                : "Error Occured while rescheduling the appointment please try again later",
          });
        }
        Mixpanel.track("Reschedule-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: formData.provider,
          originalAppointmentSlot: moment(
            new Date(formData.previous_appointment_date_time)
          ).format(MMMMDoYYYYhmma_FORMAT),
          newAppointmentSlot: moment(
            new Date(formData.appointment_date_time)
          ).format(MMMMDoYYYYhmma_FORMAT),
          visitReason: formData.reason,
          appointmentType: formData.appointment_type,
        });
      })
      .finally(() => {
        props.handleLoading(false);
      });
  };

  const onValueChange = useCallback(
    (event: any, field: string, item?: any) => {
      console.log(item);
      if (field === "appointment_date_time") {
        setFormData({
          previous_appointment_date_time: formData.appointment_date_time,
          ...formData,
          ...item,
        });
        setConfirmSlot(`Click Confirm to change Slot
         on ${moment(item.appointment_date_time).format(
           MMMMDoYYYYhhmmssa_FORMAT
         )}`);
      }
    },
    [formData]
  );

  const changeWeekWithProvider = (selectedOption: string) => {
    changeWeek(
      selectedOption,
      formData.provider,
      formData.duration,
      formData.appointment_type,
      formData.allscripts_appointment_id
    );
  };

  return (
    <div className="appointmentModalContainer">
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="modalContainer"
        className={contentStyles.appointmentModal}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Re-schedule</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeModal}
          />
        </div>
        <div className={contentStyles.body}>
          {/* <div className={contentStyles.appointmentValueContainer}>
            <h3>Appointment Type</h3>
            <p>{formData.appointment_type}</p>
          </div> */}
          {errorMsg ? <p className="form-error-text">{errorMsg}</p> : null}
          <div className={contentStyles.appointmentValueSubContainer}>
            {/* <div className={contentStyles.appointmentValueContainer}>
              <h3>Department</h3>
              <p>{formData.department}</p>
            </div> */}
            <div className={contentStyles.appointmentValueContainer}>
              <h3>Provider</h3>
              <p>{formData.provider_full_name}</p>
            </div>
          </div>
          <div className={contentStyles.appointmentValueSubContainer}>
            <div className={contentStyles.appointmentValueContainer}>
              <h3>Duration</h3>
              <p>{formData.duration} minutes</p>
            </div>
            <div className={contentStyles.appointmentValueContainer}>
              <h3>Reason</h3>
              <p>{formData.comments}</p>
            </div>
            {/* <div className={contentStyles.appointmentValueContainer}>
              <h3>Cost</h3>
              <p>${formData.cost_estimate}</p>
            </div> */}
          </div>
          <form className={contentStyles.rescheduleForm}>
            <h3>Select Slot</h3>
            <TimeSlot
              onValueChange={onValueChange}
              availableDateTimeSlots={props.availableDateTimeSlots}
              // providersDetails={props.providersDetails}
              changeWeek={changeWeekWithProvider}
            />
            {confirmSlot ? (
              <div className={contentStyles.confirmSlotcontainer}>
                {confirmSlot}
              </div>
            ) : null}
            <div className="modalActionButtonsContainer">
              <DefaultButton
                text="Confirm"
                className="modalSaveButton"
                onClick={handleSubmit}
              />
              {/* <DefaultButton
                text="Close"
                onClick={closeModal}
                className="modalCancelButton"
              /> */}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  appointmentValueSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      width: "40%",
      maxWidth: "48%",
    },
  },
  appointmentModal: {
    "& > .modalContainer": {
      "@media (min-width:1024px)": {
        // maxWidth: "calc(100% - 70%)",
        width: "24%",
      },
    },
  },
  appointmentValueContainer: {
    marginBottom: 15,
    "& h3": {
      marginBottom: 2,
      fontSize: 15,
    },
    "& p": {
      margin: 0,
      fontSize: 13,
    },
  },

  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    width: "100%",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  confirmSlotcontainer: {
    padding: "10px",
    fontSize: 14,
    fontWeight: FontWeights.semibold,
  },
  rescheduleForm: {},
});
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const RescheduleAppointmentsModal: any = withLoaderAndNotifyOnApiLoad(
  BaseRescheduleAppointmentsModal
);
