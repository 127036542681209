import React, { useEffect, useRef } from "react";
import { BotResponse } from "../botResponse/BotResponse";
import { structureBotMessages } from "../../../utils/structureBotMessages";
import {
  AppointmentBotMessageTypes,
  IAppointmentBotMessage,
} from "../../../hooks/appointmentBot/bot/types";
import { useDispatch } from "react-redux";
import { addMessageResponse } from "../../../store/chatBot/actions";
import { uuidv4 } from "../../../utils/helpers";

const PrintInstructionsBase = (
  props: any & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  //console.log(props);
  const unmounted = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.data.primary_provider &&
      Object.keys(props.data.primary_provider).length > 0
    ) {
      if (props.data.primary_provider.TimeSlotData.length) {
        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages(props.data, props.utterance, {
            queryType: AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT,
          });
        if (messageResponse) {
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      } else {
        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages(props.data, props.utterance, {
            queryType: AppointmentBotMessageTypes.PRIMARY_PROVIDER_UNAVIALIABLE,
          });
        if (messageResponse) {
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      }
    } else {
      const numberOfOtherProviders = props.data.other_providers.length;
      for (let i = 0; i < numberOfOtherProviders; i++) {
        if (props.data.other_providers[i].TimeSlotData.length) {
          const messageResponse: IAppointmentBotMessage | undefined =
            structureBotMessages(props.data, props.utterance, {
              queryType: AppointmentBotMessageTypes.ASK_TO_BOOK_PROVIDER_SLOT,
            });
          if (messageResponse) {
            dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
          }
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      <div className="drug_description">
        {props.data.entity.Instructions.replace(
          /(?:https?|ftp):\/\/[\n\S]+/g,
          ""
        )}
        {props.data.entity.Instructions.match(/\bhttps?:\/\/\S+/gi) ? (
          <a
            style={{ wordBreak: "break-word" }}
            href={props.data.entity.Instructions.match(/\bhttps?:\/\/\S+/gi)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.data.entity.Instructions.match(/\bhttps?:\/\/\S+/gi)}
          </a>
        ) : null}
      </div>
    </BotResponse>
  );
};

export const PrintInstructions = React.memo(PrintInstructionsBase);
