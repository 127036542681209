import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { withLoaderAndNotifyOnApiLoad } from "../../hoc/withLoaderAndNotifyOnApiLoad";
import { useUpcomingAppointment } from "../../hooks/upcomingAppointment/useUpcomingAppointment";
import { IServerAppointments } from "./types";
import PastCard from "./pastCard/PastCard";
import { MMDoYYYYHHmmss_FORMAT } from "../../constants/general-const";
import { Mixpanel } from "../../utils/mixpanel";

const PastAppointmentList = (props: any) => {
  const patientId = useSelector(
    (state: any) => state.isLoggedIn.patientDemog.Patient_ID
  );
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const { ListOfUpcomingAppointments } = useUpcomingAppointment();
  const [pastAppointments, setPastAppointments] = useState<
    IServerAppointments[]
  >([]);

  useEffect(() => {
    let cancel = false;
    ////console.log("check Appointmemt");
    props.handleLoading(true);
    ListOfUpcomingAppointments(
      `${patientId}?filter_string=past&date_time=${moment(new Date()).format(
        MMDoYYYYHHmmss_FORMAT
      )}`
    )
      .then((response) => {
        // //console.log(response);
        if (cancel) return;
        if (response && response.items && response.items.length) {
          Mixpanel.track("Past-Appointment-Success", {
            outcome: "Past Appointment shown",
            numberOfAppointments: response.items.length,
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
          setPastAppointments(response.items);
        } else {
          Mixpanel.track("Past-Appointment-Success", {
            outcome: "Past Appointment Empty",
            numberOfAppointments: response.items.length,
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
          setPastAppointments([]);
        }
      })
      .catch((error) => {
        Mixpanel.track("Past-Appointment-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
        });
        setPastAppointments([]);
        props.handleNotification({
          type: "warning",
          message:
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error Occured while fecthing the records please try again",
        });
      })
      .finally(() => {
        props.handleLoading(false);
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <div>
      {pastAppointments && pastAppointments.length ? (
        pastAppointments.map((item: any) => {
          return <PastCard key={item.allscripts_appointment_id} item={item} />;
        })
      ) : (
        <span className="noAppointmentMessage">{`You currently don't have any Past Appointments.`}</span>
      )}
    </div>
  );
};

export const PastAppointments =
  withLoaderAndNotifyOnApiLoad(PastAppointmentList);
