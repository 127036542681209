import * as React from "react";
import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";
import { useNavigate } from "react-router-dom";
import { INavLinkGroup, INavStyles, Nav } from "@fluentui/react";

export const NavBar: React.FunctionComponent = () => {
  let navigate = useNavigate();
  return (
    <Nav
      styles={navStyles}
      className="navBar"
      ariaLabel="Nav Drawer"
      onLinkClick={(event, element) => {
        event?.preventDefault();
        if (element) navigate(element.url);
      }}
      groups={navLinkGroups}
    />
  );
};

const navStyles: Partial<INavStyles> = {
  root: {
    width: "100%",
    // height: 350,
    boxSizing: "border-box",
    // border: "1px solid #eee",
    // overflowY: "auto",
    padding: "0 20px",
  },
  groupContent: {
    display: "flex",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  navItems: {
    display: "flex",

    "@media(max-width: 600px)": {
      padding: "0px 10px",
    },
  },
  navItem: {
    marginRight: "10px",
  },
  linkText: {
    color: "white",
    fontWeight: 600,
    fontSize: "16px",
    wordWrap: "break-word",
    selectors: {
      ":hover": {
        color: "#595d8f",
      },
      ".ms-Nav-compositeLink:hover &": {
        // color: "#595d8f",
      },
    },
  },

  link: {
    // "@media(max-width: 600px)": {
    //   padding: "0px",
    // },
    selectors: {
      ".ms-Nav-compositeLink:hover &": {
        backgroundColor: "#dedfee",
      },
    },
  },
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: "Book",
        url: "/",
        key: "key1",
        isExpanded: true,
        target: "_blank",
        // icon: "Add",
      },
      {
        key: "key2",
        name: "Visits",
        className: "navItem",
        url: "/your-appointments",
        target: "_blank",
        // iconProps: { iconName: "Suitcase" },
      },
    ],
  },
];
