export const findProvider = (provider: any, providerNameList: any) => {
  const providerInfo =
    providerNameList.length &&
    providerNameList.find((itm: any) => itm.Abbreviation === provider);

  return providerInfo;
};

export const formatLocations = (loc: any, providerLocations: any) => {
  const foundLoc =
    providerLocations &&
    providerLocations.length &&
    providerLocations.find((itm: any) => itm.Abbreviation === loc);
  let locationDetails = "";
  if (foundLoc) {
    locationDetails = foundLoc.Description;
  } else {
    locationDetails = loc;
  }
  return locationDetails;
};

export const checkGender = (gender: string | undefined | null) => {
  switch (gender) {
    case "M":
      return "his";
    case "F":
      return "her";
    default:
      return "[his/her]";
  }
};

export const convertISOdatetime = (payload: any) => {
  var isoDate = new Date(
    payload.appointment_date_time.getTime() -
      payload.appointment_date_time.getTimezoneOffset() * 60000
  ).toISOString();
  return {
    ...payload,
    appointment_date_time: isoDate.split(".")[0] + ".842073+0000",
  };
};

export const createProviderLocData = (providerList: any) => {
  let providerLocData: { [key: string]: Array<any> } = {};
  for (const provider of providerList) {
    let pushedLocations: any = {};
    if (provider.TimeSlotData.length) {
      for (let timeslot of provider.TimeSlotData) {
        if (!pushedLocations[timeslot.Scheduling_Location.trim()]) {
          providerLocData[timeslot.Scheduling_Location.trim()] =
            providerLocData[timeslot.Scheduling_Location.trim()] || [];
          providerLocData[timeslot.Scheduling_Location.trim()].push({
            ...provider,
            TimeSlotData: provider.TimeSlotData.filter(
              (t: any) =>
                t.Scheduling_Location.trim() ===
                timeslot.Scheduling_Location.trim()
            ),
          });
          pushedLocations[timeslot.Scheduling_Location.trim()] = true;
        }
      }
    } else {
      providerLocData["NoSlot"] = providerLocData["NoSlot"] || [];
      providerLocData["NoSlot"].push({
        ...provider,
      });
    }
  }
  return providerLocData;
};
