import React from "react";
import { BotResponse } from "../botResponse/BotResponse";
import { IAppointmentBotMessage } from "../../../hooks/appointmentBot/bot/types";

export const DataNotFound = (
  props: IAppointmentBotMessage & { messageIdx: number }
) => {
  // //console.log(props);
  return (
    <BotResponse
      message={props.utterance ? props.utterance : props.Message}
    ></BotResponse>
  );
};
