import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TextField, DefaultButton, MaskedTextField } from "@fluentui/react";
import { MMDDYYYY_FORMAT } from "../../constants/general-const";
import { setUserDemog, setUserStatus } from "../../store/user/actions";
import { usePatientProfile } from "../../hooks/patientProfile/usePatientProfile";
import { initialState } from "./type";
import { Mixpanel } from "../../utils/mixpanel";

function AskForUserProfile({ handleLoading }: any) {
  const [formData, setFormData] = useState<initialState>({ email: "" });
  const unmounted = useRef(false);
  const [errorMsg, setErrorMsg] = useState("");
  const onValueChange = (event: any) => {
    if (event.target.name === "dob") {
      if (moment(event.target.value, MMDDYYYY_FORMAT, true).isValid()) {
        setErrorMsg("");
        setFormData({
          ...formData,
          dob: new Date(event.target.value),
        });
      } else {
        setErrorMsg("Please Enter Date of Birth in Required Pattern");
        setFormData({
          ...formData,
          dob: null,
        });
      }
    } else {
      const values = { ...formData, [event.target.name]: event.target.value };
      setFormData(values);
    }
  };
  const { getUserProfileInfo } = usePatientProfile();
  const [askForUserDetails, setAskForUserDetails] = useState(false);
  const dispatch = useDispatch();
  const LoggedInpatient = useSelector(
    (state: any) => state.isLoggedIn.loggedInUserDemog
  );
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const handleSubmit = async () => {
    Mixpanel.track("Ask-User-Profile", {
      outcome: "User Clicked Find Dependent",
      email: patientDetails.email,
      mrn: patientDetails["custom:mrn"],
    });
    if (formValidator()) {
      handleLoading(true);
      let payload = {
        patient_id: LoggedInpatient.Patient_ID,
        patient_first_name: LoggedInpatient.First_Name,
        patient_last_name: LoggedInpatient.Last_Name,
        contact_first_name: formData?.first_name,
        contact_last_name: formData?.last_name,
        contact_dob: formData?.dob
          ? moment(new Date(formData?.dob)).format(MMDDYYYY_FORMAT)
          : "",
      };
      getUserProfileInfo(payload)
        .then((response) => {
          Mixpanel.track("Ask-User-Profile-Success", {
            outcome: "User dependent Profile Success",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
          dispatch(setUserDemog({ ...response.patient_details }));
          dispatch(setUserStatus(true));
        })
        .catch((err) => {
          handleLoading(false);
          Mixpanel.track("Ask-User-Profile-Failed", {
            outcome:
              err.response && err.response.data.mp_outcome
                ? err.response.data.mp_outcome
                : "Error Occured without message",
            error: err.response.data.status_code,
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
          setErrorMsg(
            err.response && err.response.data.message
              ? err.response.data.message
              : "Something Wrong Happened, Please Try Again"
          );
        });
    }
  };

  const handleCancelDependentLookup = () => {
    setAskForUserDetails(false);
  };

  const onProfileChange = (event: React.MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).innerText === "Yourself") {
      Mixpanel.track("Ask-User-Profile", {
        outcome: "User Clicked Yourself",
        email: patientDetails.email,
        mrn: patientDetails["custom:mrn"],
      });
      handleLoading(true);
      dispatch(setUserDemog({ ...LoggedInpatient }));
      dispatch(setUserStatus(true));
    } else {
      Mixpanel.track("Ask-User-Profile", {
        outcome: "User Clicked Dependent",
        email: patientDetails.email,
        mrn: patientDetails["custom:mrn"],
      });
      setAskForUserDetails(true);
    }
  };

  const formValidator = () => {
    let valid = true;
    if (!formData.first_name) {
      valid = false;
      Mixpanel.track("Ask-User-Profile-Failed", {
        outcome: "InValid First Name",
        email: patientDetails.email,
        mrn: patientDetails["custom:mrn"],
      });
      setErrorMsg("Please enter Valid First Name");
      return;
    }
    if (!formData.last_name) {
      valid = false;
      Mixpanel.track("Ask-User-Profile-Failed", {
        outcome: "InValid Last Name",
        email: patientDetails.email,
        mrn: patientDetails["custom:mrn"],
      });
      setErrorMsg("Please enter Valid Last Name");
      return;
    }
    if (!moment(formData?.dob, MMDDYYYY_FORMAT, true).isValid()) {
      valid = false;
      Mixpanel.track("Ask-User-Profile-Failed", {
        outcome: "InValid Date of Birth",
        email: patientDetails.email,
        mrn: patientDetails["custom:mrn"],
      });
      setErrorMsg("Please Enter Date of Birth in Required Pattern");
      return;
    }
    return valid;
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <>
      <div
        className="askForProfile-container"
        style={askForUserDetails ? { border: "2px solid #d7e2e9" } : {}}
      >
        <form className="askForProfile-form">
          {!askForUserDetails ? (
            <div className="askforProfile-header">
              <span className="askforProfile-headerText">
                {`Who are you seeking medical service for?`}
              </span>
              <div className="profile-button-container">
                <DefaultButton
                  text="Yourself"
                  data="self"
                  id={"self"}
                  className="profile-button"
                  onClick={onProfileChange}
                />
                <DefaultButton
                  text="Dependent"
                  data="someOneElse"
                  className="profile-button"
                  onClick={onProfileChange}
                />
              </div>
            </div>
          ) : null}
          {askForUserDetails ? (
            <>
              <span className="anotherUserHeader">
                {`Please provide the following information for the `}
                <b>{`dependent patient`}</b>
              </span>
              <p className="form-error-text">{errorMsg ? errorMsg : ""}</p>
              <div className="username-fields-container">
                <TextField
                  label="First Name"
                  placeholder="First Name"
                  className="firstName-field"
                  name="first_name"
                  value={formData?.first_name}
                  onChange={onValueChange}
                  required
                  autoComplete="off"
                />
                <TextField
                  label="Last Name"
                  placeholder="Last Name"
                  className="lastName-field"
                  name="last_name"
                  value={formData?.last_name}
                  onChange={onValueChange}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="date-field-container">
                <div className="date-picker-container">
                  <MaskedTextField
                    label="Date of birth"
                    placeholder="MM/DD/YYYY"
                    style={{ width: "100%" }}
                    name="dob"
                    mask="99/99/9999"
                    maskChar=""
                    onChange={onValueChange}
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="profile-search-button-container">
                <DefaultButton
                  text="Find Dependent"
                  className="profile-search-button"
                  onClick={handleSubmit}
                />
                <DefaultButton
                  text="Cancel"
                  className="profile-cancel-button"
                  onClick={handleCancelDependentLookup}
                />
              </div>
            </>
          ) : null}
        </form>
      </div>
    </>
  );
}

export default AskForUserProfile;
