import React from "react";
import { BotResponse } from "./../botResponse/BotResponse";
import { DefaultButton } from "@fluentui/react";
import { DEFAULT_CONTACT } from "../../../constants/general-const";

export const FuzzySearchBase = (props: any) => {
  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      {props.data.data.length ? (
        <>
          <div className="drug_description">{`Did you mean:`}</div>
          <div className="formContainer">
            <div className="appointment-button-container">
              {props.data.data.map((response: any, index: number) => {
                return (
                  <DefaultButton
                    key={index}
                    text={response?.Name}
                    allowDisabledFocus
                    className="book-appointment-button"
                    onClick={() =>
                      props.dispatchData(response?.Name, "query", null)
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className="drug_description">{`If none of the above, please enter a more specific visit reason for "${props.utterance}", or call ${DEFAULT_CONTACT}`}</div>
        </>
      ) : (
        <div className="drug_description">{`We were unable to determine the right appointment for "${props.utterance}". Please provide a different visit reason, or call ${DEFAULT_CONTACT}`}</div>
      )}
    </BotResponse>
  );
};

export const FuzzySearch = React.memo(FuzzySearchBase);
