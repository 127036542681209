import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpcomingAppointment } from "../../hooks/upcomingAppointment/useUpcomingAppointment";
import { Conversation } from "../../components/conversation/Conversation";
import { DefaultBotMessage } from "../../components/defaultBotMessage/DefaultBotMessage";
import { emptyMessageResponse } from "../../store/chatBot/actions";
import { removeAppointment } from "../../store/appointments/actions";
import { addMessageResponse } from "../../store/chatBot/actions";
import {
  AppointmentBotMessageTypes,
  IAppointmentBotMessage,
} from "../../hooks/appointmentBot/bot/types";
import { structureBotMessages } from "../../utils/structureBotMessages";
import { uuidv4 } from "../../utils/helpers";
import {
  DEFAULT_CONTACT,
  IS_COMPLEX_PATIENT,
} from "../../constants/general-const";
import { setProvidersLocations } from "../../store/providerInfo/actions";
import { Mixpanel } from "../../utils/mixpanel";

export default function BookAppointment() {
  const unmounted = useRef(false);
  const dispatch = useDispatch();
  const { getProviderSlotsInfo, getLocationList } = useUpcomingAppointment();
  const userData = useSelector((state: any) => state.isLoggedIn);
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const dispatchData = useCallback(
    (
      utterance: string | null,
      request_type: string,
      service_type: string | null,
      ask_visit_reason: boolean | null,
      service?: any
    ) => {
      dispatch(
        addMessageResponse({
          id: uuidv4(),
          Message:
            utterance && request_type === "query"
              ? utterance
              : service.label
              ? service.label
              : service.name,
          type: AppointmentBotMessageTypes.USER,
        })
      );
      let payload = {};
      if (service) {
        payload = {
          age: userData.patientDemog.Age,
          gender: userData.patientDemog.Sex,
          query: utterance,
          is_complex_patient: IS_COMPLEX_PATIENT,
          primary_provider_abbreviation:
            userData.patientDemog.Usual_Provider_Abbr,
          service: service.id,
          request_type: request_type,
          service_type: service_type,
          ask_visit_reason: ask_visit_reason,
          account_type: userData.patientDemog.Account_Type_Abbr,
          patient_id: userData.patientDemog.Patient_ID,
        };
      } else {
        payload = {
          age: userData.patientDemog.Age,
          gender: userData.patientDemog.Sex,
          query: utterance,
          request_type: request_type,
          is_complex_patient: IS_COMPLEX_PATIENT,
          primary_provider_abbreviation:
            userData.patientDemog.Usual_Provider_Abbr,
          account_type: userData.patientDemog.Account_Type_Abbr,
          patient_id: userData.patientDemog.Patient_ID,
          service_type: service_type,
          ask_visit_reason: ask_visit_reason,
        };
      }
      getProviderSlotsInfo({
        ...payload,
      })
        .then((providerResponse) => {
          if (providerResponse.data) {
            const messageResponse: IAppointmentBotMessage | undefined =
              structureBotMessages(
                providerResponse,
                utterance
                  ? utterance
                  : service.label
                  ? service.label
                  : service.name
              );
            if (messageResponse) {
              dispatch(
                addMessageResponse({ id: uuidv4(), ...messageResponse })
              );
            }
          } else {
            Mixpanel.track("Find-Slot-Failed", {
              outcome: "Find Slot Returned no data",
              email: patientDetails.email,
              mrn: patientDetails["custom:mrn"],
              searchTerm: utterance
                ? utterance
                : service.label
                ? service.label
                : service.name,
            });
            const messageResponse: IAppointmentBotMessage | undefined =
              structureBotMessages("", "", {
                queryType: AppointmentBotMessageTypes.NOT_FOUND,
              });
            if (messageResponse) {
              dispatch(
                addMessageResponse({ id: uuidv4(), ...messageResponse })
              );
            }
          }
        })
        .catch(function (e) {
          Mixpanel.track("Find-Slot-Failed", {
            outcome:
              e.response && e.response.data && e.response.data.mp_outcome
                ? e.response.data.mp_outcome
                : "Error Occured without message",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
            searchTerm: utterance,
          });
          const messageResponse: IAppointmentBotMessage | undefined =
            structureBotMessages(
              "",
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : `We were unable to find you a suitable slot. Please call ${DEFAULT_CONTACT}  to book your appointment`,
              {
                queryType: AppointmentBotMessageTypes.NOT_FOUND,
              }
            );
          if (messageResponse) {
            dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
          }
        });
    },
    [getProviderSlotsInfo, dispatch, userData]
  );

  useEffect(() => {
    getLocationList()
      .then((res: any) => {
        if (res.locations && res.locations.length) {
          dispatch(setProvidersLocations({ ...res }));
        } else {
          Mixpanel.track("Find-Locations-Failed", {
            outcome: "No Locations Found",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
        }
      })
      .catch((error) => {
        Mixpanel.track("Find-Locations-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
        });
      });
    return () => {
      unmounted.current = true;
      dispatch(emptyMessageResponse());
      dispatch(removeAppointment());
    };
  }, []);

  return (
    <>
      <div className="bookAppointment-main-container">
        <div className="chat-bot-container">
          <div className="chat-bot-header">
            <span className="chat-bot-header-text">
              *If you are experiencing a medical emergency, do not use this
              service, call 911 immediately.
            </span>
          </div>
          <div className="chat-bot-body">
            <div className="chat-bot-content-container">
              <DefaultBotMessage dispatchData={dispatchData} />
              <Conversation dispatchData={dispatchData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
