import React, { FunctionComponent } from "react";
import { IMessageProps } from "../types";
import { ChatMessage } from "../ChatMessage";
import UserAvatar from "../../userAvatar/UserAvatar";
// import ChatBotImage from "../../../assets/visits/chat-bot.png";
import ChatBotImage from "../../../assets/patientProfile/patient-profile-default.png";

export const UserResponse: FunctionComponent<IMessageProps> = ({
  children,
  className,
}) => {
  return (
    <ChatMessage className={`${className || ""} frozen-text-container`}>
      <div className="frozen-text">{children}</div>
      {/* <img alt="user avatar" className="chat-image" src={ChatBotImage} /> */}
      <UserAvatar />
    </ChatMessage>
  );
};
