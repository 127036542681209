const awsConnection = {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "us-east-1",
  
      // Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  
      // Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  
      // Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
    },
    API: {
      endpoints: [
        {
          name: "allscripts",
          endpoint: process.env.REACT_APP_API_ENDPOINT,
        },
      ],
    },
  };
  
  export default awsConnection;