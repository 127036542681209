import React from "react";
import { IAlertProps } from "./types";

export const AlertMessage = (alertProps: IAlertProps) => {
  const { message, type, bgColor } = alertProps;

  return (
    <div className="alertMessage-container">
      <div
        className="alertMessage"
        style={
          type === "warning"
            ? { backgroundColor: "rgb(225, 194, 194)" }
            : { backgroundColor: "#cae1c8" }
        }
      >
        <p>{message}</p>
      </div>
    </div>
  );
};
