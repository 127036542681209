import React, { useEffect } from "react";
import { useVoiceToText } from "../../hooks/appointmentBot/voiceToText/useVoiceToText";
import { IMicrophoneProps } from "./types";

export const MicrophoneBase = (props: IMicrophoneProps) => {
  const { onListen } = props;
  const { complete, start, img, transcript, isListening, recognition } =
    useVoiceToText();

  useEffect(() => {
    onListen(transcript, isListening);
  }, [onListen, isListening, transcript]);

  useEffect(() => {
    //console.log("without Array");
  });
  useEffect(() => {
    //console.log("with Array");
  }, []);
  return (
    <img
      alt="Allow voice recognition"
      className={`botMic${!recognition ? " disabled" : ""}`}
      src={img}
      onClick={recognition && (isListening ? complete : start)}
    />
  );
};
export const Microphone = React.memo(MicrophoneBase);
