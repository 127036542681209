let initialState: object = {
  providerDetails: {},
  providersLocations: {},
};

const providersInfo = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case "ADD_PROVIDERS_INFO":
      return { ...state, providerDetails: action.payload };
    case "ADD_PROVIDERS_LOCATIONS":
      return { ...state, providersLocations: action.payload };
    case "REMOVE_PROVIDERS_INFO":
      return { ...initialState };
    default:
      return state;
  }
};
export default providersInfo;
