import React, { useState } from "react";
import { UpcomingAppointments } from "../../components/upcomingAppointments/UpcomingAppointments";
import { PastAppointments } from "../../components/pastAppointments/PastAppointments";

export default function YourAppointments() {
  const [selectedTab, setSelectedTab] = useState("upcoming");

  const toggleTab = (value: any) => {
    setSelectedTab(value);
  };

  return (
    <>
      <div className="visits-main-container">
        <div className="card-section">
          <div className="card-section-tab">
            <div className="card-section-tab-items">
              <div
                className={
                  selectedTab === "upcoming"
                    ? "upcoming-tab-toggle"
                    : "upcoming-tab"
                }
                onClick={() => toggleTab("upcoming")}
              >
                <p>Upcoming</p>
              </div>
              <div
                className={
                  selectedTab === "past" ? "past-tab-toggle" : "past-tab"
                }
                onClick={() => toggleTab("past")}
              >
                <p>Past</p>
              </div>
            </div>
          </div>
          <div className="card-section-tab-content">
            {selectedTab === "upcoming" ? <UpcomingAppointments /> : null}
            {selectedTab === "past" ? <PastAppointments /> : null}
          </div>
        </div>
      </div>
    </>
  );
}
