import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to sessionStorage for web
import storage from "redux-persist/lib/storage/session";
import { composeEnhancers } from "./utils";
import botMessages from "./chatBot/reducer";
import appointments from "./appointments/reducer";
import httpError from "./error/reducer";
import isLoggedIn, { userSensitiveInfo } from "./user/reducer";
import providersInfo from "./providerInfo/reducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "botMessages",
    "httpError",
    "appointments",
    "providersInfo",
    "userSensitiveInfo",
  ],
};

const rootReducer = combineReducers({
  botMessages,
  appointments,
  isLoggedIn,
  providersInfo,
  httpError,
  userSensitiveInfo,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// configure middlewares
const middlewares = [thunk];
// rehydrate state on app start
const initialState = {};
// create store
const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);
export let persistor = persistStore(store);
export default store;
