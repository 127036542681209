import { IAppointmentBotMessage } from "../../hooks/appointmentBot/bot/types";

let messages: IAppointmentBotMessage[] = [];

const botMessages = (state = [...messages], action: any) => {
  switch (action.type) {
    case "ADD_RESPONSE":
      //console.log(action.type);
      return [...state, action.payload];
    case "EMPTY_MESSAGES":
      //console.log(state);
      return [];
    default:
      return state;
  }
};
export default botMessages;
