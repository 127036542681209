export const setUserStatus = (payload: any) => {
  if (payload) {
    return {
      type: "UPDATE_USER_STATUS",
      payload: payload,
    };
  }
};

export const setUserId = (payload: any) => {
  if (payload) {
    return {
      type: "UPDATE_USER_ID",
      payload: payload,
    };
  }
};

export const setUserInfo = (payload: any) => {
  if (payload) {
    return {
      type: "UPDATE_USER_INFO",
      payload: payload,
    };
  }
};

interface setUserVerificationSentPayload {
  email: String;
  password: String;
  verificationCodeSent: Boolean;
  sentDestination: String;
}

export const setUserVerificationSent = (
  payload: setUserVerificationSentPayload
) => {
  if (payload) {
    return {
      type: "UPDATE_VERIFICATION_SENT",
      payload: payload,
    };
  }
};

export const setUserDemog = (payload: any) => {
  if (payload) {
    return {
      type: "UPDATE_USER_DEMOGRAPHICS",
      payload: payload,
    };
  }
};

export const setLoggedInUserDemog = (payload: any) => {
  if (payload) {
    return {
      type: "UPDATE_LOGGEDINUSER_DEMOGRAPHICS",
      payload: payload,
    };
  }
};

export const userLogout = () => {
  return {
    type: "LOGOUT_USER",
  };
};
