import React from "react";
import {
  DefaultButton,
  FontIcon,
  mergeStyles,
  mergeStyleSets,
} from "@fluentui/react";
import { useDispatch } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
import { clearErrors } from "../../../store/error/actions";
import { userLogout } from "../../../store/user/actions";
import { Auth } from "aws-amplify";

const InternalServer = (props: any) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const onclick = () => {
    //console.log("error");
    // window.location.reload();
    Auth.signOut({ global: true }).then(() => {
      sessionStorage.removeItem("token");
      dispatch(userLogout());
      dispatch(clearErrors());
    });
    // navigate("/");
  };
  return (
    <article className="internalServer">
      <FontIcon
        aria-label="warning"
        iconName="Warning"
        className="errorWarning"
      />

      <h1 className="errorHeader">We’ll be back soon!</h1>
      <div className="errorTextContainer">
        <p className="errorText">
          {`Sorry for the inconvenience. The scheduling system is undergoing maintenance updates. It will be available again within the next 2 hours.`}
          {/* <a href="mailto:support@brightvista.com?subject=500 Internel Server error Occured">
          support@brightvista.com
        </a>
        {` and`} <br /> {`we can help you out!!`} */}
        </p>
        {/* <DefaultButton
        className="errorHomeButton"
        text="Go Back"
        onClick={onclick}
        allowDisabledFocus
      /> */}
      </div>
    </article>
  );
};
export default InternalServer;

// const iconClass = mergeStyles({
//   fontSize: 50,
//   height: 50,
//   width: 50,
//   margin: "0 25px",
// });

// const classNames = mergeStyleSets({
//   deepSkyBlue: [{ color: "deepskyblue" }, iconClass],
// });
