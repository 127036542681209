import React from "react";
import { BotResponse } from "../botResponse/BotResponse";
import { DefaultButton } from "@fluentui/react";
import { DEFAULT_CONTACT } from "../../../constants/general-const";

const AskToChooseServiceBase = (props: any) => {
  console.log(props);
  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      {props.data.data.service.length ? (
        <>
          <div className="drug_description">{`Please choose a service for "${
            props.data.request_service_label
              ? props.data.request_service_label
              : props.data.query
          }"`}</div>
          <div className="formContainer">
            <div className="choose-service-button-container">
              {props.data.data.service.map((serv: any, index: number) => {
                return (
                  <DefaultButton
                    key={serv.id}
                    text={serv.label ? serv.label : serv.name}
                    allowDisabledFocus
                    className="choose-service-button"
                    onClick={() =>
                      props.dispatchData(
                        props.data.query,
                        "service_selection",
                        "multiple_services",
                        props.data.data.entity &&
                          Object.keys(props.data.data.entity).length &&
                          props.data.data.entity.hasOwnProperty(
                            "AskVisitReason"
                          )
                          ? props.data.data.entity.AskVisitReason
                          : null,
                        serv
                      )
                    }
                  />
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="drug_description">{`We were unable to determine the right appointment for "${props.utterance}". Please provide a different visit reason, or call ${DEFAULT_CONTACT}`}</div>
      )}
    </BotResponse>
  );
};

export const AskToChooseService = React.memo(AskToChooseServiceBase);
