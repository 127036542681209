import React, { FunctionComponent } from "react";
import { ChatMessage } from "../ChatMessage";
import { IBotResponseProps } from "../types";
import ChatRobotImage from "../../../assets/visits/CMM_Icon_OnlineScheduling.png";

export const BotResponse: FunctionComponent<IBotResponseProps> = ({
  description,
  message,
  children,
  className,
}) => {
  return (
    <div className="botResponseBoxContainer">
      <img
        src={ChatRobotImage}
        alt="chat bot avatar"
        className="chat-robot-image"
      />
      <ChatMessage className={`${className || ""} botResponseBox`}>
        {message ? <h3 className="chat_message">{message}</h3> : null}
        <div className="chat_details">
          {description ? (
            <div className="chat_description">{description}</div>
          ) : null}
          {children}
        </div>
      </ChatMessage>
    </div>
  );
};
