import * as React from "react";

var moment = require("moment");
export default function UpcomingCard(props: any) {
  const { item, handleSchedule, handleCancel } = props;
  return (
    <>
      <div className="upcoming-card-container">
        <div className="upcoming-card-date-container">
          <p className="upcoming-week-day">
            {moment(item.appointment_date_time).format("ddd")}
          </p>
          <p className="upcoming-date">
            {moment(item.appointment_date_time).format("DD")}
          </p>
          <p className="upcoming-month">
            {moment(item.appointment_date_time).format("MMM")}
          </p>
        </div>
        <div className="upcoming-card-sub-container">
          <div className="upcoming-card-name-container">
            <p className="upcoming-card-username">{`${item.provider_first_name} ${item.provider_last_name}`}</p>
            <p className="upcoming-card-medicine-name">{item.specialty}</p>
            <p className="upcoming-card-tier-name">{item.location}</p>
          </div>
          <div className="upcoming-card-timing-container">
            <p className="upcoming-card-timing">
              {moment(item.appointment_date_time).format("hh:mm a")}
            </p>
            <p className="upcoming-card-duration">
              Duration: {item.duration} mins
            </p>
            {item.is_visible_cancel_btn ? (
              <div className="upcoming-card-modal-buttons">
                <p
                  className="upcoming-card-schedule-type"
                  onClick={() => {
                    handleSchedule({
                      ...item,
                      provider_full_name: `${item.provider_first_name} ${item.provider_last_name}`,
                      provider_abbr: item.provider,
                    });
                  }}
                >
                  Re-schedule
                </p>
                <p
                  className="upcoming-card-cancel"
                  onClick={() =>
                    handleCancel({
                      ...item,
                      provider_full_name: `${item.provider_first_name} ${item.provider_last_name}`,
                      provider_abbr: item.provider,
                    })
                  }
                >
                  Cancel
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Re-schedule</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <form>
            <TextField label="Textfield" />
            <TextField label="Textfield" />
            <TextField label="Textfield" />
          </form>
          <div className={contentStyles.modalFormButtonContainer}>
            <DefaultButton
              text="Submit"
              allowDisabledFocus
              className={contentStyles.modalFormButton}
            />
          </div>
        </div>
      </Modal> */}
    </>
  );
}

// const cancelIcon: IIconProps = { iconName: "Cancel" };

// const theme = getTheme();
// const contentStyles = mergeStyleSets({
//   container: {
//     display: "flex",
//     flexFlow: "column nowrap",
//     alignItems: "stretch",
//   },
//   modalFormButtonContainer: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     marginTop: 20,
//   },
//   modalFormButton: {
//     backgroundColor: "#654dee",
//     color: "#fff",
//     border: "none",
//     "&:hover, &:active": {
//       backgroundColor: "#4c3baf",
//       color: "#fff",
//     },
//   },
//   header: [
//     theme.fonts.xLargePlus,
//     {
//       flex: "1 1 auto",
//       borderTop: `4px solid #654dee`,
//       color: theme.palette.neutralPrimary,
//       display: "flex",
//       alignItems: "center",
//       fontWeight: FontWeights.semibold,
//       padding: "12px 12px 14px 24px",
//     },
//   ],
//   body: {
//     flex: "4 4 auto",
//     padding: "0 24px 24px 24px",
//     overflowY: "hidden",
//     selectors: {
//       p: { margin: "14px 0" },
//       "p:first-child": { marginTop: 0 },
//       "p:last-child": { marginBottom: 0 },
//     },
//   },
// });
// const stackProps: Partial<IStackProps> = {
//   horizontal: true,
//   tokens: { childrenGap: 40 },
//   styles: { root: { marginBottom: 20 } },
// };
// const iconButtonStyles: Partial<IButtonStyles> = {
//   root: {
//     color: theme.palette.neutralPrimary,
//     marginLeft: "auto",
//     marginTop: "4px",
//     marginRight: "2px",
//   },
//   rootHovered: {
//     color: theme.palette.neutralDark,
//   },
// };
