import React, {
  ComponentType,
  FunctionComponent,
  useEffect,
  useRef,
} from "react";
import { scrollToObject } from "../utils/helpers";
import { IHasRootRef } from "./types";

export const withScrollAndFocus = <P extends IHasRootRef>(
  Component: ComponentType<P>
): FunctionComponent<P> => {
  return (props) => {
    const ref = useRef<any>();
    useEffect(() => {
      if (props.rootRef) {
        props.rootRef.current?.focus();
        scrollToObject(props.rootRef);
      } else {
        ref.current?.focus();
        scrollToObject(ref);
      }
    }, [ref.current, props.rootRef?.current]);
    return (
      <Component {...props} rootRef={props.rootRef ? props.rootRef : ref}>
        {props.children}
      </Component>
    );
  };
};
