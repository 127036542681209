import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { BotResponse } from "../botResponse/BotResponse";
import { FormContainer } from "../appointmentForms/FormContainer";
import { FormField } from "../appointmentForms/FormField";
import { convertDateTimeTohhmmampm } from "../../../utils/helpers";
import { AnotherTime } from "../book-cancel-appointment/anotherTime/AnotherTime";
import { Iappointemet, Provider } from "./types";
import {
  COST_ESTIMATES,
  COVERAGE_TYPE,
  MMDDYYYY_FORMAT,
  REFERING_DOCTOR,
  MMDDhmma_FORMAT,
  MMMMDoYYYYhmma_FORMAT,
} from "../../../constants/general-const";
import { FindAlertnativeProviderWithLoc } from "../findAlertnativeProviderWithLoc/FindAlertnativeProviderWithLoc";
import { useBoolean } from "@fluentui/react-hooks";
import { useHandleSlots } from "../hooks/usehandleSlots";
import {
  checkGender,
  convertISOdatetime,
  createProviderLocData,
  findProvider,
  formatLocations,
} from "../../../utils/chatMessages";
import { useBookAppointment } from "../hooks/useBookAppointment";
import { Mixpanel } from "../../../utils/mixpanel";
import { ConfirmBookingModal } from "../../modals/confirmBooking/ConfirmBookingModal";

const AskToBookProviderSlotBase = (
  props: any & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  const patientId = useSelector(
    (state: any) => state.isLoggedIn.patientDemog.Patient_ID
  );
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const providerLocations = useSelector(
    (state: any) => state.providersInfo.providersLocations.locations
  );
  console.log("props ======", props);
  const unmounted = useRef(false);
  const [providerNameList, setProviderNameList] = useState<any[]>([]);
  const [bookAssociate, setBookAssociate] = useState(false);
  const [formData, setFormData] = useState<Iappointemet | undefined>();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const {
    grossData,
    availableDateTimeSlots,
    nextDisable,
    prevDisable,
    isLoading,
    noSlotError,
    isChangeSlotActive,
    isChangeAssociateSlotActive,
    changeWeek,
    setFirstAppointmentDateOption,
    handleNoSlotError,
    handleChangeAssociateSlot,
    handleChangeSlot,
    onDismissSlots,
  } = useHandleSlots(
    providerNameList,
    props.utterance ? props.utterance : props.data.request_service_label,
    props.data.only_show_available_providers
  );
  const { handleBookAppointment } = useBookAppointment(
    providerNameList,
    props.utterance ? props.utterance : props.data.request_service_label
  );

  const createAppointmentPayloadWithReason = (
    visitReason: string | undefined | null
  ) => {
    let forcedSlotInfo = {};
    let comments = "";
    let providerToBeBooked: Provider = {
      appointment_date_time: new Date(),
    };

    // Set Provider Info for payload
    if (
      bookAssociate &&
      moment(formData?.associated_provider?.appointment_date_time).isValid()
    ) {
      providerToBeBooked = {
        provider: formData?.associated_provider?.provider,
        appointment_date_time: formData?.associated_provider
          ?.appointment_date_time as Date,
        appointment_type: formData?.associated_provider?.appointment_type,
        department: formData?.associated_provider?.department,
        duration: formData?.associated_provider?.duration,
        location: formData?.associated_provider?.location,
        forced: formData?.associated_provider?.forced,
        saveAsAppointment_type:
          formData?.associated_provider?.saveAsAppointment_type,
      };
    } else {
      providerToBeBooked = {
        provider: formData?.provider,
        appointment_date_time: formData?.appointment_date_time as Date,
        appointment_type: formData?.appointment_type,
        department: formData?.department,
        duration: formData?.duration,
        location: formData?.location,
        forced: formData?.forced,
        saveAsAppointment_type: formData?.saveAsAppointment_type,
      };
    }
    // Set forced slot info
    if (providerToBeBooked.saveAsAppointment_type) {
      if (
        !providerToBeBooked?.appointment_type?.includes(
          providerToBeBooked?.saveAsAppointment_type
        )
      ) {
        forcedSlotInfo = {
          forced: true,
        };
      }
    }
    // // Set visit reason comments
    // if (props.data.entity.VisitReasonRequired && visitReason) {
    //   comments = visitReason;
    // } else if (!props.data.entity.VisitReasonRequired) {
    //   comments = props.utterance;
    // }
    //create payload
    const payload = {
      ...providerToBeBooked,
      ...forcedSlotInfo,
      comments: props.utterance
        ? props.utterance
        : props.data.request_service_label,
      cost_estimate: COST_ESTIMATES,
      refer_dr: REFERING_DOCTOR,
      coverage_type: COVERAGE_TYPE,
      patient_id: patientId,
      service_id: props.data.service_id,
    };
    const payloadWithIsoDate = convertISOdatetime(payload);
    hideModal();
    handleBookAppointment(payloadWithIsoDate);
  };

  const providerInfo: any = useMemo(
    () => findProvider(formData?.provider, providerNameList),
    [formData?.provider, findProvider]
  );

  const onValueChange = useCallback(
    (event: any, field: string, item?: any) => {
      if (field === "appointment_date_time") {
        console.log(isChangeAssociateSlotActive);
        if (isChangeAssociateSlotActive) {
          setFormData({
            ...formData,
            associated_provider: {
              ...formData?.associated_provider,
              ...item,
            },
          });
        } else {
          setFormData({
            ...formData,
            ...item,
          });
        }
      } else if (item && field !== "appointment_date_time") {
        if (isChangeAssociateSlotActive) {
          const values = {
            ...formData,
            associated_provider: {
              ...formData?.associated_provider,
              [field]: item.key,
            },
          };
        } else {
          const values = { ...formData, [field]: item.key };
          setFormData(values);
        }
      }
    },
    [formData, isChangeAssociateSlotActive]
  );

  const handleBookAppointmentModal = (providerType: string) => {
    if (providerType === "associateProvider") {
      setBookAssociate(true);
      Mixpanel.track("Book-Appointment", {
        outcome: "Book Appointment Clicked for Associate",
        email: patientDetails.email,
        mrn: patientDetails["custom:mrn"],
        provider: formData?.associated_provider?.provider,
        location: formData?.associated_provider?.location,
        dateTime: formData?.associated_provider?.appointment_date_time
          ? moment(
              new Date(formData?.associated_provider?.appointment_date_time)
            ).format(MMMMDoYYYYhmma_FORMAT)
          : "",
        visitReason: formData?.reason,
        appointmentType: formData?.associated_provider?.saveAsAppointment_type,
      });
      showModal();
      return;
    }
    setBookAssociate(false);
    Mixpanel.track("Book-Appointment", {
      outcome: "Book Appointment Clicked",
      email: patientDetails.email,
      mrn: patientDetails["custom:mrn"],
      provider: formData?.provider,
      location: formData?.location,
      dateTime: formData?.appointment_date_time
        ? moment(new Date(formData?.appointment_date_time)).format(
            MMMMDoYYYYhmma_FORMAT
          )
        : "",
      visitReason: formData?.reason,
      appointmentType: formData?.saveAsAppointment_type,
    });
    showModal();
  };

  useEffect(() => {
    if (
      props.data.primary_provider &&
      props.data.primary_provider.TimeSlotData &&
      props.data.primary_provider.TimeSlotData.length
    ) {
      // createTimeBlockGroups(props.data.primary_provider.TimeSlotData);
      const key1 = props.data.primary_provider.Abbreviation;
      const beginDate = props.data.from_date;
      const endDate = moment(
        new Date(props.data.primary_provider.to_date)
      ).format(MMDDYYYY_FORMAT);
      const data = props.data.primary_provider.TimeSlotData;
      grossData.current[key1] = { beginDate, endDate, data };
      const { appointment_date_time, department, location, forced } =
        setFirstAppointmentDateOption(
          props.data.primary_provider.TimeSlotData,
          props.data.primary_provider.Abbreviation
        );
      setFormData((prev) => ({
        ...prev,
        appointment_type: props.data.primary_provider.AppointmentType,
        saveAsAppointment_type:
          props.data.primary_provider.ForcedAppointmentType,
        provider: props.data.primary_provider.Abbreviation,
        duration: props.data.primary_provider.Duration,
        gender: props.data.primary_provider.Gender,
        title: props.data.primary_provider.Title,
        appointment_date_time,
        department,
        location,
        forced,
      }));

      Mixpanel.track("Find-Slot", {
        outcome: "Usual Provider Slot Shown",
        email: patientDetails.email,
        mrn: patientDetails["custom:mrn"],
        provider: props.data.primary_provider.Abbreviation,
        earliestSlot: appointment_date_time
          ? moment(new Date(appointment_date_time)).format(
              MMMMDoYYYYhmma_FORMAT
            )
          : "",
        searchTerm: props.utterance
          ? props.utterance
          : props.data.request_service_label,
      });
      const providersWithSpeciality = [
        ...props.data.other_providers,
        ...props.data.associated_providers,
        props.data.primary_provider,
      ];
      setProviderNameList(providersWithSpeciality);

      // Adding Associated Providers to form data
      if (
        props.data.associated_providers &&
        props.data.associated_providers.length
      ) {
        const associatedProviders: any = props.data.associated_providers
          .filter((provider: any) => provider.TimeSlotData.length)
          .sort((a: any, b: any) => {
            let da: any = new Date(a.TimeSlotData[0].Available_Date),
              db: any = new Date(b.TimeSlotData[0].Available_Date);
            return da - db;
          });
        const numberOfAssociatedProviders =
          props.data.associated_providers.length;
        let noslotsFound = true;
        for (let i = 0; i < numberOfAssociatedProviders; i++) {
          if (associatedProviders[i].TimeSlotData.length) {
            noslotsFound = false;
            let formatedDate =
              moment(
                new Date(associatedProviders[i].TimeSlotData[0].Available_Date)
              ).format(MMDDYYYY_FORMAT) +
              " " +
              convertDateTimeTohhmmampm(
                associatedProviders[i].TimeSlotData[0].Start_Time
              );
            setFormData((prev) => ({
              ...prev,
              associated_provider: {
                appointment_type: associatedProviders[i].AppointmentType,
                saveAsAppointment_type:
                  associatedProviders[i].ForcedAppointmentType,
                provider: associatedProviders[i].Abbreviation,
                firstName: associatedProviders[i].FirstName,
                lastName: associatedProviders[i].LastName,
                duration: associatedProviders[i].Duration,
                appointment_date_time: new Date(formatedDate),
                department:
                  associatedProviders[
                    i
                  ].TimeSlotData[0].Scheduling_Department.trim(),
                location:
                  associatedProviders[
                    i
                  ].TimeSlotData[0].Scheduling_Location.trim(),
                forced: associatedProviders[i].TimeSlotData[0].Forced,
                suffix: associatedProviders[i].Suffix,
                gender: associatedProviders[i].Gender,
                title: associatedProviders[i].Title,
              },
            }));
            Mixpanel.track("Find-Slot", {
              outcome: "Associate Provider Slot Shown",
              email: patientDetails.email,
              mrn: patientDetails["custom:mrn"],
              provider: associatedProviders[i].Abbreviation,
              earliestSlot: moment(new Date(formatedDate)).format(
                MMMMDoYYYYhmma_FORMAT
              ),
              searchTerm: props.utterance
                ? props.utterance
                : props.data.request_service_label,
            });
            const key1 = associatedProviders[i].Abbreviation;
            const beginDate = props.data.from_date;
            const endDate = moment(
              new Date(associatedProviders[i].to_date)
            ).format(MMDDYYYY_FORMAT);
            const data = associatedProviders[i].TimeSlotData;
            grossData.current[key1] = { beginDate, endDate, data };
            break;
          }
        }
      }
    } else {
      const numberOfOtherProviders = props.data.other_providers.length;
      const numberOfAssociatedProviders =
        props.data.associated_providers.length;
      const emptyProviders = props.data.other_providers
        .filter((provider: any) => !provider.TimeSlotData.length)
        .concat(
          props.data.associated_providers.filter(
            (provider: any) => !provider.TimeSlotData.length
          )
        );
      // console.log(emptyProviders);
      const otherProviders: any = props.data.other_providers
        .filter((provider: any) => provider.TimeSlotData.length)
        .sort((a: any, b: any) => {
          let da: any = new Date(a.TimeSlotData[0].Available_Date),
            db: any = new Date(b.TimeSlotData[0].Available_Date);
          return da - db;
        });
      // console.log(otherProviders);
      const sortedProviderList = [...otherProviders, ...emptyProviders];
      // console.log(sortedProviderList);
      let noslotsFound = true;
      for (let i = 0; i < numberOfOtherProviders; i++) {
        if (sortedProviderList[i].TimeSlotData.length) {
          noslotsFound = false;

          const key1 = sortedProviderList[i].Abbreviation;
          const beginDate = props.data.from_date;
          const endDate = moment(
            new Date(sortedProviderList[i].to_date)
          ).format(MMDDYYYY_FORMAT);
          const data = sortedProviderList[i].TimeSlotData;
          grossData.current[key1] = { beginDate, endDate, data };
          const { appointment_date_time, department, location, forced } =
            setFirstAppointmentDateOption(
              sortedProviderList[i].TimeSlotData,
              sortedProviderList[i].Abbreviation
            );

          setFormData((prev) => ({
            ...prev,
            appointment_type: sortedProviderList[i].AppointmentType,
            saveAsAppointment_type: sortedProviderList[i].ForcedAppointmentType,
            provider: sortedProviderList[i].Abbreviation,
            duration: sortedProviderList[i].Duration,
            gender: sortedProviderList[i].Gender,
            title: props.data.primary_provider.Title,
            appointment_date_time,
            department,
            location,
            forced,
          }));

          Mixpanel.track("Find-Slot", {
            outcome: "Other Provider Slot Shown",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
            provider: sortedProviderList[i].Abbreviation,
            earliestSlot: moment(new Date(appointment_date_time)).format(
              MMMMDoYYYYhmma_FORMAT
            ),
            searchTerm: props.utterance
              ? props.utterance
              : props.data.request_service_label,
          });
          break;
        }
      }
      if (noslotsFound) {
        setFormData((prev) => ({
          ...prev,
          provider: props.data.primary_provider.Abbreviation,
          appointment_type: props.data.primary_provider.AppointmentType,
          saveAsAppointment_type:
            props.data.primary_provider.ForcedAppointmentType,
        }));
        handleNoSlotError(true);
      }

      // Set other provider List
      if (
        props.data.primary_provider &&
        Object.keys(props.data.primary_provider).length
      ) {
        const providersWithSpeciality = [
          ...props.data.other_providers,
          ...props.data.associated_providers,
          props.data.primary_provider,
        ];
        setProviderNameList(providersWithSpeciality);
      } else {
        const providersWithSpeciality = [
          ...props.data.other_providers,
          ...props.data.associated_providers,
        ];
        setProviderNameList(providersWithSpeciality);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  if (noSlotError) {
    return (
      <BotResponse
        className={props.additionalClasses}
        message={props.Message}
        description={props.SubpromptHTML}
      >
        <div className="drug_description">
          {formData?.provider
            ? `There is no slot available for ${providerInfo.FirstName} ${providerInfo.LastName}, ${providerInfo.Suffix} (${providerInfo.SpecialtyName}) , Please choose from Available Providers or call 970-926-6340 to book your appointment`
            : `No Slot available. Please choose from Available Providers`}
        </div>
        <div className="formContainer">
          {providerNameList && providerNameList.length ? (
            <FindAlertnativeProviderWithLoc
              providerLocData={createProviderLocData(providerNameList)}
              providerList={providerNameList}
              utterance={
                props.utterance
                  ? props.utterance
                  : props.data.request_service_label
              }
              serviceId={props.data.service_id}
              // visitReasonRequired={props.data.entity.VisitReasonRequired}
              dontFetchMoreSlots={props.data.is_show_available_providers}
            />
          ) : null}
        </div>
      </BotResponse>
    );
  } else if (!(formData?.provider && formData?.appointment_date_time)) {
    return <div className="botResponseloading" style={{ display: "block" }} />;
  } else {
    const appointmentDateTime = moment(
      new Date(formData.appointment_date_time)
    );

    let associateAppointmentDateTime = null;
    if (formData.associated_provider) {
      associateAppointmentDateTime = moment(
        new Date(formData.associated_provider.appointment_date_time)
      );
    }
    return (
      <BotResponse
        className={props.additionalClasses}
        message={props.Message}
        description={props.SubpromptHTML}
      >
        <div className="drug_description">
          {`${providerInfo.FirstName} ${providerInfo.LastName}, ${
            providerInfo.Suffix
          } is available ${appointmentDateTime.format(MMDDhmma_FORMAT)} in `}
          <strong>
            <u>{formatLocations(formData.location, providerLocations)}</u>
          </strong>
          {formData.associated_provider &&
            `. If you would like to be seen sooner, ${
              formData.associated_provider.firstName
            } 
              ${formData.associated_provider.lastName}, ${checkGender(
              formData.gender
            )} ${formData.associated_provider.title}, can see you on 
              ${associateAppointmentDateTime?.format(
                MMDDhmma_FORMAT
              )} in ${formatLocations(
              formData.associated_provider?.location,
              providerLocations
            )}`}
        </div>
        <div className="formContainer">
          <FormContainer className="appointment-button-container">
            <FormField
              label={`Book ${providerInfo.FirstName.slice(0, 1)}. ${
                providerInfo.LastName
              } ${appointmentDateTime.format(MMDDhmma_FORMAT)}`}
              className="book-appointment-button"
              onClick={() => handleBookAppointmentModal("defaultProvider")}
            />
            {formData.associated_provider &&
              formData.associated_provider.firstName && (
                <FormField
                  label={`Book ${formData.associated_provider.firstName.slice(
                    0,
                    1
                  )}. ${
                    formData.associated_provider.lastName
                  } ${associateAppointmentDateTime?.format(MMDDhmma_FORMAT)}`}
                  className="book-appointment-button"
                  onClick={() =>
                    handleBookAppointmentModal("associateProvider")
                  }
                />
              )}
            <FormField
              label={`View all ${providerInfo.FirstName.slice(0, 1)}. ${
                providerInfo.LastName
              } slots`}
              className={
                isChangeSlotActive
                  ? "toggle_button_onclick"
                  : "book-appointment-button toggle_button"
              }
              onClick={() => handleChangeSlot(providerInfo.Abbreviation)}
            />
            {formData.associated_provider &&
              formData.associated_provider.firstName &&
              Object.keys(formData.associated_provider).length && (
                <FormField
                  label={`View all ${formData.associated_provider.firstName.slice(
                    0,
                    1
                  )}. ${formData.associated_provider.lastName} slots`}
                  className={
                    isChangeAssociateSlotActive
                      ? "toggle_button_onclick"
                      : "book-appointment-button toggle_button"
                  }
                  onClick={() => {
                    formData.associated_provider &&
                      formData.associated_provider.provider &&
                      handleChangeAssociateSlot(
                        formData.associated_provider.provider
                      );
                  }}
                />
              )}
            {providerNameList && providerNameList.length ? (
              <FindAlertnativeProviderWithLoc
                providerLocData={createProviderLocData(providerNameList)}
                providerList={providerNameList}
                utterance={
                  props.utterance
                    ? props.utterance
                    : props.data.request_service_label
                }
                serviceId={props.data.service_id}
                // visitReasonRequired={props.data.entity.VisitReasonRequired}
                dontFetchMoreSlots={props.data.only_show_available_providers}
              />
            ) : null}
            {(isChangeSlotActive ||
              (isChangeAssociateSlotActive &&
                formData.associated_provider)) && (
              <AnotherTime
                onDismiss={onDismissSlots}
                onValueChange={onValueChange}
                providerName={
                  isChangeAssociateSlotActive
                    ? formData.associated_provider?.provider
                    : formData.provider
                }
                appointment_type={
                  isChangeAssociateSlotActive
                    ? formData.associated_provider?.appointment_type
                    : formData.appointment_type
                }
                duration={
                  isChangeAssociateSlotActive
                    ? formData.associated_provider?.duration
                    : formData.duration
                }
                providerList={providerNameList}
                changeWeek={changeWeek}
                isLoading={isLoading}
                availableDateTimeSlots={availableDateTimeSlots}
                nextDisable={nextDisable}
                prevDisable={prevDisable}
              />
            )}
          </FormContainer>
          {isModalOpen ? (
            <ConfirmBookingModal
              isModalOpen={isModalOpen}
              // askForVisitReason={props.data.entity.VisitReasonRequired}
              handleModalSubmit={createAppointmentPayloadWithReason}
              closeModal={hideModal}
              selectedSlot={
                bookAssociate
                  ? formData.associated_provider?.appointment_date_time
                  : formData.appointment_date_time
              }
              providerInfo={
                bookAssociate
                  ? {
                      FirstName: formData.associated_provider?.firstName,
                      LastName: formData.associated_provider?.lastName,
                      Suffix: formData.associated_provider?.suffix,
                    }
                  : providerInfo
              }
              location={
                bookAssociate
                  ? formatLocations(
                      formData.associated_provider?.location,
                      providerLocations
                    )
                  : formatLocations(formData.location, providerLocations)
              }
              userUtternace={
                props.utterance
                  ? props.utterance
                  : props.data.request_service_label
              }
            />
          ) : null}
        </div>
      </BotResponse>
    );
  }
};

export const AskToBookProviderSlot = React.memo(AskToBookProviderSlotBase);
