import React from "react";
import { IAppointmentBotMessage } from "../../../hooks/appointmentBot/bot/types";
import { BotResponse } from "../botResponse/BotResponse";
// import ReactHtmlParser from 'react-html-parser';
import { FormContainer } from "../appointmentForms/FormContainer";
import { FormField } from "../appointmentForms/FormField";

export const AskForHealthPlan = (
  props: IAppointmentBotMessage & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      <div className="formContainer plan-container">
        <div className="drug_description">
          will you be paying for the treatment through a health plan ?
        </div>
        <FormContainer className="plan-button-container">
          <FormField
            label="I have a plan"
            className="book-appointment-button"
          />
          <FormField
            label="I don't have a health plan"
            utterance={"I don't have a health plan"}
            option={"getTreatmentCost"}
            className="book-appointment-button"
          />
        </FormContainer>
      </div>
    </BotResponse>
  );
};
