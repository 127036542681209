import React, { ComponentType, FunctionComponent, useState } from "react";
import { AlertMessage } from "../components/alertMessage/AlertMessage";
import { IAlertProps } from "../components/alertMessage/types";
import { DataLoader } from "../components/dataLoader/DataLoader";
import { ILoading } from "./types";

export const withLoaderAndNotifyOnApiLoad = <P extends ILoading>(
  Component: ComponentType<P>
): FunctionComponent<P> => {
  return (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState<IAlertProps>({
      type: "success",
      message: "",
    });
    const setLoader = (status: boolean, ev: object) => {
      setIsLoading(status);
    };
    const setAlert = (
      status: { type: string; message: string },
      ev: object
    ) => {
      setShowAlert(true);
      setAlertInfo({ type: status.type, message: status.message });
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    };
    return (
      <>
        <Component
          {...props}
          handleLoading={setLoader}
          handleNotification={setAlert}
        ></Component>
        {showAlert ? (
          <AlertMessage type={alertInfo.type} message={alertInfo.message} />
        ) : null}
        {isLoading ? <DataLoader /> : null}
      </>
    );
  };
};
