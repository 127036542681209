import React, { useState, useRef, useCallback } from "react";
import moment from "moment";
import {
  DAYS,
  FETCH_PROVIDER_DATA_DAYS,
  HHmm_FORMAT,
  MMDDYYYY_FORMAT,
  NO_SLOT_PICK_ANOTHER_MESSAGE,
} from "../../../constants/general-const";
import { useUpcomingAppointment } from "../../../hooks/upcomingAppointment/useUpcomingAppointment";
import { convertDateTimeTohhmmampm } from "../../../utils/helpers";
import { findProvider } from "../../../utils/chatMessages";
import { Mixpanel } from "../../../utils/mixpanel";
import { useSelector } from "react-redux";

export function useHandleSlots(
  providerNameList: any,
  utterance: string,
  only_show_available_providers: boolean
) {
  const [endDate, setEndDate] = useState<any>(
    moment(new Date()).endOf("week").format(MMDDYYYY_FORMAT)
  );
  const [startDate, setStartDate] = useState<any>(
    moment(new Date()).startOf("week").format(MMDDYYYY_FORMAT)
  );
  const [availableDateTimeSlots, setAvailableDateTimeSlots] = useState<object>({
    noSlot: NO_SLOT_PICK_ANOTHER_MESSAGE,
  });
  const [isChangeSlotActive, setIsChangeSlotActive] = useState(false);
  const [isChangeAssociateSlotActive, setIsChangeAssociateSlotActive] =
    useState(false);
  const [nextDisable, setNextDisable] = useState(false);
  const [prevDisable, setPrevDisable] = useState(true);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [noSlotError, setNoSlotError] = useState<Boolean>(false);
  const grossData = useRef<any>({});
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const userData = useSelector((state: any) => state.isLoggedIn.patientDemog);
  const { getTimeSlots } = useUpcomingAppointment();

  const createTimeBlockGroups = (timeblock: any) => {
    const groups = timeblock.reduce((groups: any, item: any) => {
      let key = item.Available_Date + `|` + item.Scheduling_Location.trim();
      const group = groups[key] || [];
      group.push(item);
      groups[key] = group;
      return groups;
    }, {});
    if (Object.keys(groups).length === 0) {
      setAvailableDateTimeSlots({ noSlot: NO_SLOT_PICK_ANOTHER_MESSAGE });
    } else {
      setAvailableDateTimeSlots(groups);
    }
  };

  const setFirstAppointmentDateOption = (timeblock: any, provider: any) => {
    setPrevDisable(true);
    setLoading(false);
    let firstDateOption = {
      appointment_date_time: new Date(),
      department: "",
      location: "",
      forced: false,
    };
    if (timeblock.length) {
      setNoSlotError(false);
      let formatedDate =
        moment(new Date(timeblock[0].Available_Date)).format(MMDDYYYY_FORMAT) +
        " " +
        convertDateTimeTohhmmampm(timeblock[0].Start_Time);
      let dateTime = new Date(formatedDate);
      firstDateOption = {
        appointment_date_time: dateTime,
        department: timeblock[0].Scheduling_Department.trim(),
        location: timeblock[0].Scheduling_Location.trim(),
        forced: timeblock[0].Forced,
      };
    } else {
      setNoSlotError(true);
    }
    return firstDateOption;
  };

  const firstWeekData = useCallback((provider: string) => {
    const firstStartDate = moment(
      new Date(grossData.current[provider].data[0].Available_Date)
    )
      .startOf("week")
      .format(MMDDYYYY_FORMAT);
    const firstEndDate = moment(
      new Date(grossData.current[provider].data[0].Available_Date)
    )
      .endOf("week")
      .format(MMDDYYYY_FORMAT);
    setStartDate(firstStartDate);
    setEndDate(firstEndDate);

    let nextWeekStartDate = moment(new Date(firstStartDate))
      .add(7, DAYS)
      .format(MMDDYYYY_FORMAT);
    let lastDate = moment(new Date(grossData.current[provider].endDate)).format(
      MMDDYYYY_FORMAT
    );

    if (
      new Date(nextWeekStartDate).getTime() >= new Date(lastDate).getTime() &&
      only_show_available_providers
    ) {
      setNextDisable(true);
    }

    const currWeekData = grossData.current[provider].data.filter(
      (data: any) =>
        new Date(data.Available_Date).getTime() <=
          new Date(firstEndDate).getTime() &&
        new Date(data.Available_Date).getTime() >=
          new Date(firstStartDate).getTime()
    );
    createTimeBlockGroups(currWeekData);
  }, []);

  const showNextWeekData = (
    providerAbbr: string,
    nextWeekStartDate: string,
    nextWeekEndDate: string,
    providerInfo: any
  ) => {
    // Fetching Availiable Data From Cache
    const currWeekData = grossData.current[providerAbbr].data.filter(
      (data: any) =>
        new Date(data.Available_Date).getTime() <=
          new Date(nextWeekEndDate).getTime() &&
        new Date(data.Available_Date).getTime() >=
          new Date(nextWeekStartDate).getTime()
    );
    if (!(currWeekData && currWeekData.length)) {
      setAvailableDateTimeSlots({
        noSlot: `There is no slot available for ${providerInfo.FirstName} ${providerInfo.LastName}, ${providerInfo.Suffix} (${providerInfo.SpecialtyName})  for ${utterance} for the week of ${nextWeekStartDate}. 
        Please choose another week, or select another provider if ${providerInfo.FirstName} ${providerInfo.LastName}'s availability doesn't work for you.`,
      });
      return;
    }
    createTimeBlockGroups(currWeekData);
  };

  const changeWeek = useCallback(
    async (
      selectedOption: string,
      providerAbbr: string,
      appointment_type: string,
      duration: string
    ) => {
      if (providerAbbr) {
        const providerInfo = findProvider(providerAbbr, providerNameList);
        if (selectedOption === "prevWeek") {
          //Find first date in the entire time block tto check if prev week exists
          let today = moment(
            new Date(grossData.current[providerAbbr].data[0].Available_Date)
          ).format(MMDDYYYY_FORMAT);
          let prevWeekEndDate = moment(new Date(endDate))
            .subtract(7, DAYS)
            .format(MMDDYYYY_FORMAT);
          let prevWeekStartDate = moment(new Date(startDate))
            .subtract(7, DAYS)
            .format(MMDDYYYY_FORMAT);
          setStartDate(prevWeekStartDate);
          setEndDate(prevWeekEndDate);
          const currWeekData = grossData.current[providerAbbr].data.filter(
            (data: any) =>
              new Date(data.Available_Date).getTime() <=
                new Date(prevWeekEndDate).getTime() &&
              new Date(data.Available_Date).getTime() >=
                new Date(prevWeekStartDate).getTime()
          );
          if (currWeekData && currWeekData.length) {
            createTimeBlockGroups(currWeekData);
          } else {
            setAvailableDateTimeSlots({
              noSlot: `There is no slot available for ${providerInfo.FirstName} ${providerInfo.LastName}, ${providerInfo.Suffix} (${providerInfo.SpecialtyName}) for ${utterance} for the week of ${prevWeekStartDate}. 
              Please choose another week, or select another provider if ${providerInfo.FirstName} ${providerInfo.LastName}'s availability doesn't work for you.`,
            });
          }
          if (
            new Date(today).getTime() >=
              new Date(prevWeekStartDate).getTime() &&
            new Date(today).getTime() < new Date(prevWeekEndDate).getTime()
          ) {
            setPrevDisable(true);
          }
          setNextDisable(false);
        } else if (selectedOption === "nextWeek") {
          let lastDate = moment(
            new Date(grossData.current[providerAbbr].endDate)
          ).format(MMDDYYYY_FORMAT);
          let nextWeekEndDate = moment(new Date(endDate))
            .add(7, DAYS)
            .format(MMDDYYYY_FORMAT);
          let nextWeekStartDate = moment(new Date(startDate))
            .add(7, DAYS)
            .format(MMDDYYYY_FORMAT);
          setStartDate(nextWeekStartDate);
          setEndDate(nextWeekEndDate);
          //Check if Next week is starting with date which is not in our cached data OR Next week end date is not in our cahced data then
          // this means we don't have enough data availiable we need to pull from server
          if (
            new Date(nextWeekStartDate).getTime() >=
              new Date(lastDate).getTime() ||
            new Date(lastDate).getTime() <= new Date(nextWeekEndDate).getTime()
          ) {
            if (!only_show_available_providers) {
              setLoading(true);
              const toDate = moment(new Date(lastDate))
                .add(FETCH_PROVIDER_DATA_DAYS, DAYS)
                .format(MMDDYYYY_FORMAT);
              // console.log(formData.appointment_type);
              const payload = {
                provider: providerAbbr,
                from_date: lastDate,
                to_date: toDate,
                current_date: moment(new Date()).format(MMDDYYYY_FORMAT),
                time: moment(new Date()).format(HHmm_FORMAT),
                appointment_type: appointment_type,
                appointment_duration: duration,
                patient_id: userData.Patient_ID,
              };
              try {
                Mixpanel.track("More-Slots", {
                  outcome: "More slots asked for Provider",
                  email: patientDetails.email,
                  mrn: patientDetails["custom:mrn"],
                  provider: providerAbbr,
                  visitReason: utterance,
                  appointmentType: appointment_type,
                  from_date: lastDate,
                  to_date: toDate,
                });
                setNextDisable(true);
                let timeblockfunction = await getTimeSlots(payload);
                setLoading(false);
                Mixpanel.track("More-Slots-Confirm", {
                  outcome: "More slots Provided",
                  email: patientDetails.email,
                  mrn: patientDetails["custom:mrn"],
                  provider: providerAbbr,
                  visitReason: utterance,
                  appointmentType: appointment_type,
                  from_date: lastDate,
                  to_date: toDate,
                  noOfSlotsProvided: timeblockfunction.length,
                });
                if (timeblockfunction.length) {
                  const timeblock = timeblockfunction;
                  setNextDisable(false);
                  // Adding Fetched Data into Cache and updating end date with the last date we fetched data for
                  grossData.current[providerAbbr].data = [
                    ...grossData.current[providerAbbr].data,
                    ...timeblock,
                  ];
                  grossData.current[providerAbbr].endDate = toDate;
                }
                //We are done fetching the data from server show data on the basis of what we have
                showNextWeekData(
                  providerAbbr,
                  nextWeekStartDate,
                  nextWeekEndDate,
                  providerInfo
                );
              } catch (error: any) {
                Mixpanel.track("More-Slots-Failed", {
                  outcome:
                    error.response && error.response.data.mp_outcome
                      ? error.response.data.mp_outcome
                      : "Error Occured without message",
                  email: patientDetails.email,
                  mrn: patientDetails["custom:mrn"],
                });
                setAvailableDateTimeSlots({
                  noSlot:
                    error.response && error.response.data.message
                      ? error.response.data.message
                      : "Error while fetching more slots, Please try again or choose another Provider",
                });
                setNextDisable(false);
                setLoading(false);
              }
            } else {
              setNextDisable(true);
              if (
                !(
                  new Date(nextWeekStartDate).getTime() <=
                    new Date(lastDate).getTime() &&
                  new Date(lastDate).getTime() <=
                    new Date(nextWeekEndDate).getTime()
                )
              ) {
                setAvailableDateTimeSlots({
                  noSlot: NO_SLOT_PICK_ANOTHER_MESSAGE,
                });
                return;
              }
              //We can only show on the basis of what we have as only_show_available_providers flag is true
              showNextWeekData(
                providerAbbr,
                nextWeekStartDate,
                nextWeekEndDate,
                providerInfo
              );
            }
          } else {
            //show data we have data for this week in the cache
            showNextWeekData(
              providerAbbr,
              nextWeekStartDate,
              nextWeekEndDate,
              providerInfo
            );
          }
          setPrevDisable(false);
        }
      }
    },
    [providerNameList, endDate, startDate, utterance, getTimeSlots]
  );

  const handleNoSlotError = (isError: Boolean) => {
    setNoSlotError(isError);
  };

  const handleChangeSlot = (keyProvider: string) => {
    //Call to form complete week formation for current selected provider button
    setIsChangeAssociateSlotActive(false);
    firstWeekData(keyProvider);

    Mixpanel.track("Alternative-Slots", {
      outcome: "Looking for Alternative Slots",
      email: patientDetails.email,
      mrn: patientDetails["custom:mrn"],
      provider: keyProvider,
    });
    setIsChangeSlotActive(
      (currentIsChangeSlotActive) => !currentIsChangeSlotActive
    );
  };

  const handleChangeAssociateSlot = (keyProvider: string) => {
    //Call to form complete week formation for current selected provider button
    setIsChangeSlotActive(false);
    firstWeekData(keyProvider);

    Mixpanel.track("Alternative-Slots", {
      outcome: "Looking for Alternative Slots of Assosciate",
      email: patientDetails.email,
      mrn: patientDetails["custom:mrn"],
      provider: keyProvider,
    });
    setIsChangeAssociateSlotActive(
      (currentIsChangeAssociateSlotActive) =>
        !currentIsChangeAssociateSlotActive
    );
  };

  const onDismissSlots = useCallback(() => {
    setIsChangeSlotActive(false);
    setIsChangeAssociateSlotActive(false);
  }, []);

  return {
    grossData,
    availableDateTimeSlots,
    nextDisable,
    prevDisable,
    isLoading,
    isChangeSlotActive,
    isChangeAssociateSlotActive,
    noSlotError,
    firstWeekData,
    changeWeek,
    handleNoSlotError,
    setFirstAppointmentDateOption,
    handleChangeAssociateSlot,
    handleChangeSlot,
    onDismissSlots,
  };
}
