import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BotResponse } from "../botResponse/BotResponse";
import moment from "moment";
import {
  COST_ESTIMATES,
  COVERAGE_TYPE,
  MMDDhmma_FORMAT,
  MMDDYYYY_FORMAT,
  MMDD_FORMAT,
  MMMMDoYYYYhmma_FORMAT,
  REFERING_DOCTOR,
} from "../../../constants/general-const";
import { FindAlertnativeProviderWithLoc } from "../findAlertnativeProviderWithLoc/FindAlertnativeProviderWithLoc";
import { convertDateTimeTohhmmampm } from "../../../utils/helpers";
import { useHandleSlots } from "../hooks/usehandleSlots";
import { Provider } from "../askToBookProviderSlot/types";
import {
  checkGender,
  convertISOdatetime,
  createProviderLocData,
  formatLocations,
} from "../../../utils/chatMessages";
import { useSelector } from "react-redux";
import { FormContainer } from "../appointmentForms/FormContainer";
import { FormField } from "../appointmentForms/FormField";
import { useBoolean } from "@fluentui/react-hooks";
import { AnotherTime } from "../book-cancel-appointment/anotherTime/AnotherTime";
import { useBookAppointment } from "../hooks/useBookAppointment";
import { Mixpanel } from "../../../utils/mixpanel";
import { ConfirmBookingModal } from "../../modals/confirmBooking/ConfirmBookingModal";

const PrimaryProviderUnavailiableBase = (
  props: any & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  const [associateProviderInfo, setAssociateProviderInfo] =
    useState<Provider>();
  const [providerNameList, setProviderNameList] = useState<any[]>([]);
  const [bookAssociate, setBookAssociate] = useState(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const {
    grossData,
    availableDateTimeSlots,
    nextDisable,
    prevDisable,
    isLoading,
    isChangeAssociateSlotActive,
    changeWeek,
    handleChangeAssociateSlot,
    onDismissSlots,
  } = useHandleSlots(
    providerNameList,
    props.utterance ? props.utterance : props.data.request_service_label,
    props.data.only_show_available_providers
  );
  const { handleBookAppointment } = useBookAppointment(
    providerNameList,
    props.utterance ? props.utterance : props.data.request_service_label
  );
  const providerLocations = useSelector(
    (state: any) => state.providersInfo.providersLocations.locations
  );
  const patientId = useSelector(
    (state: any) => state.isLoggedIn.patientDemog.Patient_ID
  );

  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const createAppointmentPayloadWithReason = (
    visitReason: string | undefined | null
  ) => {
    let forcedSlotInfo = {};
    let comments = "";
    let providerToBeBooked: Provider = {
      appointment_date_time: new Date(),
    };

    // Set Provider Info for payload
    if (
      bookAssociate &&
      associateProviderInfo &&
      Object.keys(associateProviderInfo).length &&
      moment(associateProviderInfo?.appointment_date_time).isValid()
    ) {
      providerToBeBooked = {
        provider: associateProviderInfo.provider,
        appointment_date_time:
          associateProviderInfo.appointment_date_time as Date,
        appointment_type: associateProviderInfo.appointment_type,
        department: associateProviderInfo.department,
        duration: associateProviderInfo.duration,
        location: associateProviderInfo.location,
        forced: associateProviderInfo.forced,
        saveAsAppointment_type: associateProviderInfo.saveAsAppointment_type,
      };
    }
    // Set forced slot info
    if (providerToBeBooked.saveAsAppointment_type) {
      if (
        !providerToBeBooked?.appointment_type?.includes(
          providerToBeBooked?.saveAsAppointment_type
        )
      ) {
        forcedSlotInfo = {
          forced: true,
        };
      }
    }
    // // Set visit reason comments
    // if (props.data.entity.VisitReasonRequired && visitReason) {
    //   comments = visitReason;
    // } else if (!props.data.entity.VisitReasonRequired) {
    //   comments = props.utterance;
    // }
    //create payload
    const payload = {
      ...providerToBeBooked,
      ...forcedSlotInfo,
      comments: props.utterance
        ? props.utterance
        : props.data.request_service_label,
      cost_estimate: COST_ESTIMATES,
      refer_dr: REFERING_DOCTOR,
      coverage_type: COVERAGE_TYPE,
      patient_id: patientId,
      service_id: props.data.service.id,
    };
    const payloadWithIsoDate = convertISOdatetime(payload);
    // console.log(payloadWithIsoDate);
    hideModal();
    handleBookAppointment(payloadWithIsoDate);
  };

  const handleBookAppointmentModal = (providerType: string) => {
    if (providerType === "associateProvider") {
      setBookAssociate(true);
      showModal();
      return;
    }
    setBookAssociate(false);
    showModal();
  };

  const onValueChange = useCallback(
    (event: any, field: string, item?: any) => {
      if (field === "appointment_date_time") {
        setAssociateProviderInfo({
          ...associateProviderInfo,
          ...item,
        });
      }
    },
    [associateProviderInfo]
  );

  useEffect(() => {
    Mixpanel.track("Find-Slot", {
      outcome: "No Usual Provider Slot",
      email: patientDetails.email,
      mrn: patientDetails["custom:mrn"],
      provider: props.data.primary_provider.Abbreviation,
      searchTerm: props.utterance
        ? props.utterance
        : props.data.request_service_label,
    });

    // Email, , Response Message, Earliest Slot, #Providers, #Slots
    // Adding Associated Providers to form data
    if (
      props.data.associated_providers &&
      props.data.associated_providers.length
    ) {
      const associatedProviders: any = props.data.associated_providers
        .filter((provider: any) => provider.TimeSlotData.length)
        .sort((a: any, b: any) => {
          let da: any = new Date(a.TimeSlotData[0].Available_Date),
            db: any = new Date(b.TimeSlotData[0].Available_Date);
          return da - db;
        });
      const numberOfAssociatedProviders =
        props.data.associated_providers.length;
      let noslotsFound = true;
      for (let i = 0; i < numberOfAssociatedProviders; i++) {
        if (associatedProviders[i].TimeSlotData.length) {
          noslotsFound = false;
          let formatedDate =
            moment(
              new Date(associatedProviders[i].TimeSlotData[0].Available_Date)
            ).format(MMDDYYYY_FORMAT) +
            " " +
            convertDateTimeTohhmmampm(
              associatedProviders[i].TimeSlotData[0].Start_Time
            );
          setAssociateProviderInfo((prev) => ({
            ...prev,
            appointment_type: associatedProviders[i].AppointmentType,
            saveAsAppointment_type:
              associatedProviders[i].ForcedAppointmentType,
            provider: associatedProviders[i].Abbreviation,
            firstName: associatedProviders[i].FirstName,
            lastName: associatedProviders[i].LastName,
            duration: associatedProviders[i].Duration,
            appointment_date_time: new Date(formatedDate),
            department:
              associatedProviders[
                i
              ].TimeSlotData[0].Scheduling_Department.trim(),
            location:
              associatedProviders[i].TimeSlotData[0].Scheduling_Location.trim(),
            forced: associatedProviders[i].TimeSlotData[0].Forced,
            suffix: associatedProviders[i].Suffix,
            title: associatedProviders[i].Title,
          }));
          Mixpanel.track("Find-Slot", {
            outcome: "Associate Provider Slot Shown",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
            provider: associatedProviders[i].Abbreviation,
            earliestSlot: moment(new Date(formatedDate)).format(
              MMMMDoYYYYhmma_FORMAT
            ),
            searchTerm: props.utterance
              ? props.utterance
              : props.data.request_service_label,
          });
          const key1 = associatedProviders[i].Abbreviation;
          const beginDate = props.data.from_date;
          const endDate = moment(
            new Date(associatedProviders[i].to_date)
          ).format(MMDDYYYY_FORMAT);
          const data = associatedProviders[i].TimeSlotData;
          grossData.current[key1] = { beginDate, endDate, data };
          break;
        }
      }
    }

    const providersWithSpeciality = [
      ...props.data.other_providers,
      ...props.data.associated_providers,
      props.data.primary_provider,
    ];
    setProviderNameList(providersWithSpeciality);
  }, []);

  const associateAppointmentDateTime = useMemo(() => {
    if (associateProviderInfo) {
      return moment(new Date(associateProviderInfo.appointment_date_time));
    }
  }, [associateProviderInfo?.appointment_date_time]);

  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      <div className="drug_description">
        {`
      We could not find a suitable slot for "${
        props.utterance ? props.utterance : props.data.request_service_label
      }" with 
      ${props.data.primary_provider.FirstName} ${
          props.data.primary_provider.LastName
        }, before ${moment(
          new Date(props.data.primary_provider.to_date)
        ).format(MMDD_FORMAT)}.
       To see your primary provider call ${props.data.primary_provider.Phone}`}
        {associateProviderInfo &&
          Object.keys(associateProviderInfo).length &&
          `. ${associateProviderInfo.firstName} 
              ${associateProviderInfo.lastName}, ${checkGender(
            props.data.primary_provider.Gender
          )} ${associateProviderInfo.title}, can see you on 
              ${associateAppointmentDateTime?.format(
                MMDDhmma_FORMAT
              )} in ${formatLocations(
            associateProviderInfo.location,
            providerLocations
          )}`}
      </div>

      <div className="formContainer">
        <FormContainer className="appointment-button-container">
          {associateProviderInfo &&
          Object.keys(associateProviderInfo).length &&
          associateProviderInfo.firstName ? (
            <>
              <FormField
                label={`Book ${associateProviderInfo.firstName.slice(0, 1)}. ${
                  associateProviderInfo.lastName
                } ${associateAppointmentDateTime?.format(MMDDhmma_FORMAT)}`}
                className="book-appointment-button"
                onClick={() => handleBookAppointmentModal("associateProvider")}
              />
              <FormField
                label={`View all ${associateProviderInfo.firstName.slice(
                  0,
                  1
                )}. ${associateProviderInfo.lastName} slots`}
                className={
                  isChangeAssociateSlotActive
                    ? "toggle_button_onclick"
                    : "book-appointment-button toggle_button"
                }
                onClick={() => {
                  associateProviderInfo &&
                    associateProviderInfo.provider &&
                    handleChangeAssociateSlot(associateProviderInfo.provider);
                }}
              />
            </>
          ) : null}

          {providerNameList && providerNameList.length ? (
            <FindAlertnativeProviderWithLoc
              providerLocData={createProviderLocData(providerNameList)}
              providerList={providerNameList}
              utterance={
                props.utterance
                  ? props.utterance
                  : props.data.request_service_label
              }
              serviceId={props.data.service.id}
              // visitReasonRequired={props.data.entity.VisitReasonRequired}
              dontFetchMoreSlots={props.data.only_show_available_providers}
            />
          ) : null}

          {isChangeAssociateSlotActive &&
          associateProviderInfo &&
          Object.keys(associateProviderInfo).length ? (
            <AnotherTime
              onDismiss={onDismissSlots}
              onValueChange={onValueChange}
              providerName={associateProviderInfo.provider}
              appointment_type={associateProviderInfo.appointment_type}
              duration={associateProviderInfo.duration}
              providerList={providerNameList}
              changeWeek={changeWeek}
              isLoading={isLoading}
              availableDateTimeSlots={availableDateTimeSlots}
              nextDisable={nextDisable}
              prevDisable={prevDisable}
            />
          ) : null}
        </FormContainer>
        {isModalOpen &&
        associateProviderInfo &&
        Object.keys(associateProviderInfo).length ? (
          <ConfirmBookingModal
            isModalOpen={isModalOpen}
            // askForVisitReason={props.data.entity.VisitReasonRequired}
            handleModalSubmit={createAppointmentPayloadWithReason}
            closeModal={hideModal}
            selectedSlot={associateProviderInfo.appointment_date_time}
            providerInfo={{
              FirstName: associateProviderInfo.firstName,
              LastName: associateProviderInfo.lastName,
              Suffix: associateProviderInfo.suffix,
            }}
            location={formatLocations(
              associateProviderInfo.location,
              providerLocations
            )}
            userUtternace={props.utterance}
          />
        ) : null}
      </div>
    </BotResponse>
  );
};

export const PrimaryProviderUnavailiable = React.memo(
  PrimaryProviderUnavailiableBase
);
