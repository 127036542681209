import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useBoolean } from "@fluentui/react-hooks";
import { withLoaderAndNotifyOnApiLoad } from "../../hoc/withLoaderAndNotifyOnApiLoad";
import { useUpcomingAppointment } from "../../hooks/upcomingAppointment/useUpcomingAppointment";
import { IServerAppointments, IServerTimeBlock } from "./types";
import UpcomingCard from "./upcomingCard/UpcomingCard";
import { RescheduleAppointmentsModal } from "../modals/reschedule-appointment/RescheduleAppointment";
import { CancelAppointmentsModal } from "../modals/cancel-appointment/CancelAppointment";
import { DataLoader } from "../dataLoader/DataLoader";
import {
  DAYS,
  HHmm_FORMAT,
  MMDDYYYY_FORMAT,
  MMDoYYYYHHmmss_FORMAT,
  NO_SLOT_FOUND_ERROR,
} from "../../constants/general-const";
import { Mixpanel } from "../../utils/mixpanel";
var moment = require("moment");
const UpcomingAppointmentList = (props: any) => {
  const userData = useSelector((state: any) => state.isLoggedIn.patientDemog);
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const cache = useRef<IServerTimeBlock>({});
  const { ListOfUpcomingAppointments, getTimeSlots } = useUpcomingAppointment();

  const [upcomingAppointments, setUpcomingAppointments] = useState<
    IServerAppointments[]
  >([]);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [updateAppointment, setUpdateAppointment] = useState({});
  const [isResheduleModal, setIsResheduleModal] = useState(false);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [changeAppointment, setChangeAppointment] = useState({});
  const [availableDateTimeSlots, setAvailableDateTimeSlots] = useState<object>(
    {}
  );
  const [currentFromDate, setCurrentFromDate] = useState<any>();

  const closeModal = () => {
    setIsResheduleModal(false);
    setIsCancelModal(false);
    hideModal();
    setChangeAppointment({});
  };

  const handleUpdateAppointmentData = (data: any) => {
    setUpdateAppointment({ ...data });
  };

  const handleDeleteAppointment = (data: any) => {
    setUpdateAppointment({ ...data });
  };

  const getAnotherTimeSlots = useCallback(
    async (
      date?: any,
      provider?: any,
      duration?: any,
      appointmentType?: any,
      appointmentId?: any
    ) => {
      setCurrentFromDate(date);
      let toDate = moment(date).add(7, DAYS).format(MMDDYYYY_FORMAT);
      let payloadFromDate = moment(date).format(MMDDYYYY_FORMAT);
      const key = provider + payloadFromDate + toDate;
      let timeblock: any;
      if (cache.current[key]) {
        timeblock = cache.current[key];
        setLoading(false);
      } else {
        const payload = {
          provider: provider,
          from_date: payloadFromDate,
          to_date: toDate,
          time: moment(new Date()).format(HHmm_FORMAT),
          current_date: moment(new Date()).format(MMDDYYYY_FORMAT),
          appointment_type: appointmentType,
          appointment_duration: duration ? duration : 15,
          appointment_id: appointmentId,
          patient_id: userData.Patient_ID,
        };
        try {
          Mixpanel.track("More-Slots", {
            outcome: "More slots asked for Provider",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
            provider: provider,
            appointmentType: appointmentType,
            from_date: payloadFromDate,
            to_date: toDate,
          });
          timeblock = await getTimeSlots(payload);
          cache.current[key] = timeblock;
          setLoading(false);
          Mixpanel.track("More-Slots-Confirm", {
            outcome: "More slots Provided",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
            provider: provider,
            appointmentType: appointmentType,
            from_date: payloadFromDate,
            to_date: toDate,
            noOfSlotsProvided: timeblock.length,
          });
        } catch (error: any) {
          Mixpanel.track("More-Slots-Failed", {
            outcome:
              error.response && error.response.data.mp_outcome
                ? error.response.data.mp_outcome
                : "Error Occured without message",
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
          setAvailableDateTimeSlots({
            noSlot:
              error.response && error.response.data.message
                ? error.response.data.message
                : "Error while fetching slots, Please try again",
          });
          setLoading(false);
        }
      }

      if (timeblock && timeblock.length) {
        createTimeBlockGroups(timeblock);
      } else {
        setAvailableDateTimeSlots({
          noSlot: `There is no slot available for the week from ${payloadFromDate} to ${toDate} . 
          Please choose another week`,
        });
      }
    },
    [getTimeSlots]
  );

  const createTimeBlockGroups = (timeblock: any) => {
    const groups = timeblock.reduce((groups: any, item: any) => {
      let key = item.Available_Date + `|` + item.Scheduling_Location.trim();
      const group = groups[key] || [];
      group.push(item);
      groups[key] = group;
      return groups;
    }, {});
    if (Object.keys(groups).length === 0) {
      setAvailableDateTimeSlots({ noSlot: NO_SLOT_FOUND_ERROR });
    } else {
      setAvailableDateTimeSlots(groups);
    }
  };

  const changeWeek = useCallback(
    (
      selectedOption: string,
      provider: string,
      duration: any,
      appointment_type: any,
      appointmentId: any
    ) => {
      setLoading(true);
      if (selectedOption === "prevWeek") {
        let previous = moment(currentFromDate).subtract(7, DAYS);
        getAnotherTimeSlots(
          new Date(previous),
          provider,
          duration,
          appointment_type,
          appointmentId
        );
      } else if (selectedOption === "nextWeek") {
        let nextWeek = moment(currentFromDate).add(7, DAYS);
        getAnotherTimeSlots(
          new Date(nextWeek),
          provider,
          duration,
          appointment_type,
          appointmentId
        );
      }
    },
    [currentFromDate, getAnotherTimeSlots]
  );

  const handleSchedule = (item: any) => {
    setLoading(true);
    setChangeAppointment(item);
    getAnotherTimeSlots(
      item.appointment_date_time,
      item.provider_abbr,
      item.duration,
      item.appointment_type,
      item.allscripts_appointment_id
    );
    setIsResheduleModal(true);
    showModal();
  };

  const handleCancel = (item: any) => {
    setChangeAppointment(item);
    setIsCancelModal(true);
    showModal();
  };

  useEffect(() => {
    let cancel = false;
    props.handleLoading(true);

    ListOfUpcomingAppointments(
      `${userData.Patient_ID}?filter_string=upcoming&date_time=${moment(
        new Date()
      ).format(MMDoYYYYHHmmss_FORMAT)}`
    )
      .then((response) => {
        if (cancel) return;
        if (response && response.items && response.items.length) {
          response.items.sort((a: any, b: any) => {
            let da: any = new Date(a.appointment_date_time),
              db: any = new Date(b.appointment_date_time);
            return da - db;
          });
          setUpcomingAppointments(response.items);
          Mixpanel.track("Upcoming-Appointment-Success", {
            outcome: "Upcoming Appointment Shown",
            numberOfAppointments: response.items.length,
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
        } else {
          Mixpanel.track("Upcoming-Appointment-Success", {
            outcome: "Upcoming Appointment Empty",
            numberOfAppointments: response.items.length,
            email: patientDetails.email,
            mrn: patientDetails["custom:mrn"],
          });
          setUpcomingAppointments([]);
        }
      })
      .catch((error) => {
        Mixpanel.track("Upcoming-Appointment-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
        });
        setUpcomingAppointments([]);
        props.handleNotification({
          type: "warning",
          message:
            error.response && error.response.data.message
              ? error.response.data.message
              : "Error Occured while fecthing the records please try again",
        });
      })
      .finally(() => props.handleLoading(false));
    return () => {
      cancel = true;
    };
  }, [updateAppointment]);

  return (
    <div>
      {upcomingAppointments && upcomingAppointments.length ? (
        upcomingAppointments.map((item: any) => {
          return (
            <UpcomingCard
              key={item.allscripts_appointment_id}
              item={item}
              handleCancel={handleCancel}
              handleSchedule={handleSchedule}
            />
          );
        })
      ) : (
        <span className="noAppointmentMessage">{`You currently don't have any Upcoming Appointments.`}</span>
      )}
      {isLoading ? <DataLoader /> : null}
      {isResheduleModal &&
      Object.keys(availableDateTimeSlots).length &&
      changeAppointment &&
      Object.keys(changeAppointment).length ? (
        <RescheduleAppointmentsModal
          isModalOpen={isModalOpen}
          appointmentData={changeAppointment}
          handleUpdated={handleUpdateAppointmentData}
          // providersDetails={providerDetails}
          closeModal={closeModal}
          availableDateTimeSlots={availableDateTimeSlots}
          currentFromDate={currentFromDate}
          changeWeek={changeWeek}
        />
      ) : null}
      {isCancelModal &&
      changeAppointment &&
      Object.keys(changeAppointment).length ? (
        <CancelAppointmentsModal
          isModalOpen={isModalOpen}
          appointmentData={changeAppointment}
          handleDeleted={handleDeleteAppointment}
          closeModal={closeModal}
        />
      ) : null}
    </div>
  );
};

export const UpcomingAppointments = withLoaderAndNotifyOnApiLoad(
  UpcomingAppointmentList
);
