import axios, { AxiosRequestConfig } from "axios";
import { IClientOperationResponse } from "./types";
import { IAppointmentBotMessage } from "../hooks/appointmentBot/bot/types";

const URL = process.env.REACT_APP_API_ENDPOINT;
const OPEN_URL = process.env.REACT_APP_OPEN_API_ENDPOINT;
const url = "https://brightvista-api.dev-ioteractions.org";

export class Client {
  private request(config: AxiosRequestConfig): IClientOperationResponse {
    let cancelTokenSource = axios.CancelToken.source();
    config.cancelToken = cancelTokenSource.token;
    return {
      axiosPromise: axios(config).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log("Request canceled", thrown.message);
        } else {
          // handle error
          throw thrown;
        }
      }),
      cancelToken: cancelTokenSource,
    };
  }

  GetPatientData(dataID: string, endpoint: string): IClientOperationResponse {
    let config: AxiosRequestConfig = {
      method: "GET",
      url: `${URL}${endpoint}${dataID}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "X-token-pm": `${sessionStorage.getItem("PRO_PM_TOKEN")}`,
        "X-token-ehr": `${sessionStorage.getItem("PRO_EHR_TOKEN")}`,
      },
    };
    let request = this.request(config);
    request.axiosPromise = request.axiosPromise.then((res) => {
      return res.data;
    });
    return request;
  }

  ListData(userID: string, endpoint: string): IClientOperationResponse {
    let config: AxiosRequestConfig = {
      method: "GET",
      url: `${URL}${endpoint}${userID}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "X-token-pm": `${sessionStorage.getItem("PRO_PM_TOKEN")}`,
        "X-token-ehr": `${sessionStorage.getItem("PRO_EHR_TOKEN")}`,
      },
    };
    let request = this.request(config);
    request.axiosPromise = request.axiosPromise.then((res) => {
      return res.data;
    });
    return request;
  }

  GetListDataWithTokens(endpoint: string): IClientOperationResponse {
    let config: AxiosRequestConfig = {
      method: "GET",
      url: `${URL}${endpoint}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "X-token-pm": `${sessionStorage.getItem("PRO_PM_TOKEN")}`,
        "X-token-ehr": `${sessionStorage.getItem("PRO_EHR_TOKEN")}`,
      },
    };
    let request = this.request(config);
    request.axiosPromise = request.axiosPromise.then((res) => {
      return res.data;
    });
    return request;
  }

  storeServerData(payload: any, endpoint: string): IClientOperationResponse {
    var config: AxiosRequestConfig = {
      method: "POST",
      url: `${URL}${endpoint}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "X-token-pm": `${sessionStorage.getItem("PRO_PM_TOKEN")}`,
        "X-token-ehr": `${sessionStorage.getItem("PRO_EHR_TOKEN")}`,
      },
      data: payload,
    };
    let request = this.request(config);
    request.axiosPromise = request.axiosPromise.then((res) => {
      return res;
    });
    return request;
  }

  updateServerData(
    payload: any,
    endpoint: string,
    dataID: string
  ): IClientOperationResponse {
    // //console.log("payload", payload);
    var config: AxiosRequestConfig = {
      method: "PUT",
      url: `${URL}${endpoint}${dataID}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "X-token-pm": `${sessionStorage.getItem("PRO_PM_TOKEN")}`,
        "X-token-ehr": `${sessionStorage.getItem("PRO_EHR_TOKEN")}`,
      },
      data: payload,
    };
    let request = this.request(config);
    // //console.log("request", request);
    request.axiosPromise = request.axiosPromise.then((res) => {
      // //console.log("res", res);
      return res;
    });
    return request;
  }

  userLoginRegister(
    payload: object,
    endpoint: string
  ): IClientOperationResponse<IAppointmentBotMessage> {
    let config: AxiosRequestConfig = {
      method: "POST",
      url: `${OPEN_URL}${endpoint}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let request = this.request(config);
    return request;
  }
}
