import React from "react";
import { useSelector } from "react-redux";

const UserAvatar = () => {
  const patientDetails = useSelector((state: any) => state.isLoggedIn.patientDemog);

  const getInitials = (fullName: String) => {
    const allNames = fullName.trim().split(" ");
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
    return initials;
  };
  return (
    <div className="chat-image">
      <p className="chat-image-initials">
        {getInitials(
          `${patientDetails.First_Name} ${patientDetails.Last_Name}`
        )}
      </p>
    </div>
  );
};

export default UserAvatar;
