import React, { useState, useCallback, useEffect } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Microphone } from "./Microphone";

const SearchBase = ({
  dispatchData,
}: {
  dispatchData: (
    utterance: string | null,
    request_type: string,
    service_type: string | null,
    ask_visit_reason: boolean | null,
    service?: any
  ) => void;
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [serchValue, setSerchValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  // const [showTextField, setShowTextField] = useState(false);

  const dispatchSearchData = useCallback(
    (value: any) => {
      dispatchData(value, "query", null , null);
      setSerchValue("");
      // setShowTextField(false);
    },
    [dispatchData]
  );

  const onInputKeyPress = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Enter") {
        if (serchValue) {
          dispatchSearchData(serchValue);
        }
      }
    },
    [dispatchSearchData, serchValue]
  );

  const onValueChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string | undefined
  ) => {
    if (value !== undefined) {
      value.length < 25
        ? setErrorMsg("")
        : setErrorMsg("*Input value length must be less than 25");
      setSerchValue(value);
    }
  };
  const onListen = useCallback(
    (transcript: string, isRecognizing: boolean) => {
      setSerchValue(transcript);
      if (!isRecognizing && !!transcript && transcript !== "") {
        dispatchSearchData(transcript);
      }
    },
    [dispatchSearchData]
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (serchValue) {
      dispatchSearchData(serchValue);
    }
  };

  // const handleClickOnLink = () => {
  //   setShowTextField(true);
  // };

  useEffect(() => {
    const width = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", width);
    return () => window.removeEventListener("resize", width);
  }, []);

  return (
    <>
      <div className={"search-container"}>
        {/* {showTextField ? ( */}
        <>
          <Microphone onListen={onListen} />
          <div className="search-input-container">
            <TextField
              // placeholder={
              //   windowWidth > 1100
              //     ? "Enter the reason for you visit (symptoms, medical conditions, procedures, medications, vaccines)"
              //     : "Enter the reason for you visit"
              // }
              placeholder="e.g. fever, high cholesterol etc. (max 25 characters)"
              className="search-input"
              value={serchValue}
              maxLength={25}
              onChange={onValueChange}
              onKeyDown={onInputKeyPress}
              autoComplete="off"
              // onGetErrorMessage={getErrorMessage}
            />
          </div>
          <DefaultButton
            text="Send"
            allowDisabledFocus
            className="search-send-button"
            onClick={handleSubmit}
          />
        </>
        {/* //) : (
      //   <DefaultButton
      //     text={"Another Reason"}
      //     allowDisabledFocus
      //     className="book-appointment-button another-reason-button"
      //     onClick={handleClickOnLink}
      //   />
      // )} */}
      </div>
      {errorMsg ? <p className="form-error-text">{errorMsg}</p> : null}
    </>
  );
};

export const Search = React.memo(SearchBase);
