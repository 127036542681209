import React from "react";
import { IAppointmentBotMessage } from "../../../hooks/appointmentBot/bot/types";
import { BotResponse } from "../botResponse/BotResponse";
// import ReactHtmlParser from 'react-html-parser';

export const FormSubmittedMessage = (
  props: IAppointmentBotMessage & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      <div className="drug_description">
        `We're all set for now. We'll send you a reminder of your visit and
        intake form three days prior to your visit.` `If you have any questions
        till then please call us at `
        <span className="drug_description_number">`(970)926-6340`</span>
      </div>
    </BotResponse>
  );
};
