import React from "react";
import { useSelector } from "react-redux";
import { CustomizeBotMessages } from "./CustomizeBotMessages";
import { AppointmentBotMessageTypes } from "../../hooks/appointmentBot/bot/types";
import { BotResponseLoading } from "../botResponseLoading/BotResponseLoading";

const ConversationBase = ({
  dispatchData,
}: {
  dispatchData: (
    utterance: string | null,
    request_type: string,
    service_type: string | null,
    ask_visit_reason: boolean | null,
    service?: any
  ) => void;
}) => {
  const messages = useSelector((state: any) => {
    return state.botMessages;
  });
  let finalMessage =
    messages.length > 0 ? messages[messages.length - 1] : undefined;
  let isLoadingResponse =
    !!finalMessage && finalMessage.type === AppointmentBotMessageTypes.USER;

  return (
    <div className="appointmentBot">
      {messages?.map((message: any) =>
        CustomizeBotMessages(
          message,
          messages.length - 1,
          undefined,
          dispatchData
        )
      )}
      {/* <div className="botResponseloading" style={{ display: "block" }}>
        Searching for Slots
      </div> */}
      {isLoadingResponse ? <BotResponseLoading /> : null}
    </div>
  );
};

export const Conversation = React.memo(ConversationBase);
