import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  hmma_FORMAT,
  MMMMDoYYYYhmma_FORMAT,
  MMMMDoYYYY_FORMAT,
} from "../../../constants/general-const";
import {
  AppointmentBotMessageTypes,
  IAppointmentBotMessage,
} from "../../../hooks/appointmentBot/bot/types";
import { useUpcomingAppointment } from "../../../hooks/upcomingAppointment/useUpcomingAppointment";
import { addAppointment } from "../../../store/appointments/actions";
import { addMessageResponse } from "../../../store/chatBot/actions";
import { findProvider } from "../../../utils/chatMessages";
import { convertPayloadDataFormat, uuidv4 } from "../../../utils/helpers";
import { Mixpanel } from "../../../utils/mixpanel";
import { structureBotMessages } from "../../../utils/structureBotMessages";

export function useBookAppointment(
  providerNameList: any,
  actuallUtterance: string
) {
  const { storeUpcomingAppointment } = useUpcomingAppointment();
  const dispatch = useDispatch();
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const handleBookAppointment = (payload: any) => {
    const convertedPayload = convertPayloadDataFormat(payload);
    const providerInfo = findProvider(payload.provider, providerNameList);
    let DateTime = new Date(payload.appointment_date_time);
    DateTime = new Date(
      DateTime.getTime() + DateTime.getTimezoneOffset() * 60000
    );
    const userUtterance = `Book appointment with ${providerInfo.FirstName} ${
      providerInfo.LastName
    }, ${providerInfo.Suffix} (${providerInfo.SpecialtyName}) on ${moment(
      new Date(DateTime)
    ).format(MMMMDoYYYYhmma_FORMAT)}`;
    dispatch(
      addMessageResponse({
        id: uuidv4(),
        Message: userUtterance,
        type: AppointmentBotMessageTypes.USER,
      })
    );
    storeUpcomingAppointment(convertedPayload)
      .then((res) => {
        let utterance = "";
        let queryType = "";
        utterance = `Your appointment with ${providerInfo.FirstName} ${
          providerInfo.LastName
        }, ${providerInfo.Suffix} (${
          providerInfo.SpecialtyName
        }) has been booked for ${moment(new Date(DateTime)).format(
          MMMMDoYYYY_FORMAT
        )} at ${moment(new Date(DateTime)).format(hmma_FORMAT)} for ${
          payload.duration
        } minutes
          Your visit is to address "${actuallUtterance}"`;
        queryType = AppointmentBotMessageTypes.APPOINTMENT_BOOKED;
        dispatch(addAppointment({ ...convertedPayload }));

        Mixpanel.track("Book-Appointment-Confirm", {
          outcome: "Appointment Booked",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: payload.provider,
          location: payload.location,
          dateTime: moment(new Date(DateTime)).format(MMMMDoYYYYhmma_FORMAT),
          visitReason: payload.reason,
          appointmentType: payload.saveAsAppointment_type,
        });
        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages("", utterance, {
            queryType: queryType,
          });
        if (messageResponse) {
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      })
      .catch((error) => {
        const data = {};
        const messageResponse: IAppointmentBotMessage | undefined =
          structureBotMessages(
            data,
            error.response && error.response.data.message
              ? error.response.data.message
              : "Something Wrong Happened Please Try Again",
            {
              queryType: AppointmentBotMessageTypes.ERROR_MESSAGE,
            }
          );
        Mixpanel.track("Book-Appointment-Failed", {
          outcome:
            error.response && error.response.data.mp_outcome
              ? error.response.data.mp_outcome
              : "Error Occured without message",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: payload.provider,
          location: payload.location,
          dateTime: moment(new Date(DateTime)).format(MMMMDoYYYYhmma_FORMAT),
          visitReason: payload.reason,
          appointmentType: payload.saveAsAppointment_type,
        });
        if (messageResponse) {
          dispatch(addMessageResponse({ id: uuidv4(), ...messageResponse }));
        }
      });
  };
  return { handleBookAppointment };
}
