import React from "react";
import { BotResponse } from "../botResponse/BotResponse";
import { useBoolean } from "@fluentui/react-hooks";
import { DEFAULT_CONTACT } from "../../../constants/general-const";
import { AskForVisitReasonModal } from "../../modals/askForVisitReason/AskForVisitReasonModal";

const AskForVisitReasonBase = (props: any) => {
  console.log(props);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(true);
  const handleVisitReasonModalSubmit = (
    visitReason: string | undefined | null
  ) => {
    hideModal();
    props.dispatchData(
      visitReason,
      "query",
      null,
      props.data.data.entity &&
        Object.keys(props.data.data.entity).length &&
        props.data.data.entity.hasOwnProperty("AskVisitReason")
        ? props.data.data.entity.AskVisitReason
        : null,
      props.data.data.service
    );
  };
  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      {props.data.data.service &&
      Object.keys(props.data.data.service).length ? (
        <>
          <div className="drug_description">
            {`Can you describe visit Reason for "${props.data.data.service.label}" ?`}

            {isModalOpen ? (
              <AskForVisitReasonModal
                isModalOpen={isModalOpen}
                handleModalSubmit={handleVisitReasonModalSubmit}
                closeModal={hideModal}
                userUtternace={
                  props.utterance
                    ? props.utterance
                    : props.data.request_service_label
                }
              />
            ) : null}
          </div>
        </>
      ) : (
        <div className="drug_description">{`We were unable to determine the right appointment for "${
          props.utterance ? props.utterance : props.request_service_label
        }". Please call ${DEFAULT_CONTACT} to book your appointment`}</div>
      )}
    </BotResponse>
  );
};

export const AskForVisitReason = React.memo(AskForVisitReasonBase);
