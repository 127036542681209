let payload: object = {
  status: false,
  id: "",
  info: {},
  patientDemog: {},
  loggedInUserDemog: {},
  verificationSent: false,
};

let userSensitivePayload: object = {
  verificationSent: {
    email: "",
    password: "",
    verificationCodeSent: false,
    sentDestination: "",
  },
};

const isLoggedIn = (state = { ...payload }, action: any) => {
  switch (action.type) {
    case "UPDATE_USER_STATUS":
      //console.log(action.type);
      return { ...state, status: action.payload };
    case "UPDATE_USER_INFO":
      return { ...state, info: action.payload };
    case "UPDATE_USER_ID":
      return { ...state, id: action.payload };
    case "UPDATE_USER_DEMOGRAPHICS":
      return { ...state, patientDemog: action.payload };
    case "UPDATE_LOGGEDINUSER_DEMOGRAPHICS":
      return { ...state, loggedInUserDemog: action.payload };
    case "LOGOUT_USER":
      return { ...payload };
    default:
      return state;
  }
};
export const userSensitiveInfo = (
  state = { ...userSensitivePayload },
  action: any
) => {
  switch (action.type) {
    case "UPDATE_VERIFICATION_SENT":
      return { ...state, verificationSent: { ...action.payload } };
    default:
      return state;
  }
};
export default isLoggedIn;
