import React from "react";
import { FormContainer } from "./FormContainer";
import { FormField } from "./FormField";
import ModalClose from "../../image/popup-close.png";
import { IUserProfileProps } from "./types";
import { IconButton, IButtonStyles } from "@fluentui/react/lib/Button";
import { getTheme, IIconProps } from "@fluentui/react";

const theme = getTheme();
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    // marginLeft: "auto",
    // marginTop: "4px",
    // marginRight: "2px",
    height: "18px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const cancelIcon: IIconProps = { iconName: "Cancel" };

export const UserProfile = (props: IUserProfileProps) => {
  const { onDismissModal } = props;
  return (
    <>
      <div className="register-modal-close-icon-container">
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismissModal}
          className="register-modal-close-icon"
        />
        {/* <img
          src={ModalClose}
          onClick={onDismissModal}
          alt="modal-close-icon"
          height="18"
          className="register-modal-close-icon"
        /> */}
      </div>
      <div className="register-modal-container">
        <div className="register-modal-header">
          <p>I already have a Concierge account:</p>
          <input type="button" value="Sign in to Concierge" />
        </div>
        <p className="credit-card-modal-table-caption">
          Profile settings for your private eHealth Journal
        </p>
        <div className="credit-card-modal-table-container">
          <table className="credit-card-modal-table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>
                  <input type="text" />
                </th>
                <th>Last Name</th>
                <th>
                  <input type="text" />
                </th>
              </tr>
              <tr>
                <th>Date of Birth</th>
                <th>
                  <input type="text" />
                </th>
                <th>Last 4 Digits od SSN</th>
                <th>
                  <input type="text" />
                </th>
              </tr>
              <tr>
                <th>Mobile Phone</th>
                <th>
                  <input type="text" />
                </th>
                <th>Home Phone</th>
                <th>
                  <input type="text" />
                </th>
              </tr>
              <tr>
                <th>Email</th>
                <th>
                  <input type="text" />
                </th>
                <th>Second Email</th>
                <th>
                  <input type="text" />
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="modal_ehr_container">
          <p className="ehr_header">I found in the EHR</p>
          <div className="ehr_row">
            <p>Have you visited Vail Health before?</p>
            <FormContainer>
              <FormField
                label="Yes"
                utterance={"Yes"}
                className="book-appointment-button"
              />
              <FormField
                label="No"
                utterance={"No"}
                className="book-appointment-button"
              />
            </FormContainer>
          </div>
        </div>

        <div className="modal_pcp_container">
          <p className="pcp_header">If yes and they have a PCP</p>
          <div className="pcp_row">
            <p>Who is your Primary Care Provider?</p>
            <input type="text" />
          </div>
        </div>

        <div className="modal_pcp2_container">
          <p className="pcp2_header">If yes and they have a PCP</p>
          <div className="pcp2_row">
            <p>Who is your Primary Care Provider?</p>
            <input type="text" />
          </div>
        </div>

        <div className="verification_row">
          <p>
            We need to verify your email or phone number by sending you a code:
          </p>
          <FormContainer>
            <FormField
              label="Send to text"
              utterance={"Send to text"}
              className="book-appointment-button"
            />
            <FormField
              label="Send to email"
              utterance={"Send to email"}
              className="book-appointment-button"
            />
          </FormContainer>
        </div>

        <div className="verification_code_row">
          <p>
            We need to verify your email or phone number by sending you a code:
          </p>
          <input className="verification_input" type="text" />
          <FormContainer>
            <FormField
              label="Verify"
              utterance={"Verify"}
              className="book-appointment-button"
            />
            <FormField
              label="Send again"
              utterance={"Send again"}
              className="book-appointment-button"
            />
          </FormContainer>
        </div>

        <div className="conversation_row">
          <p>
            We need to verify your email or phone number by sending you a code:
          </p>
          <FormContainer>
            <FormField
              label="Yes"
              utterance={"Yes"}
              className="book-appointment-button"
            />
            <FormField
              label="No"
              utterance={"No"}
              className="book-appointment-button"
            />
          </FormContainer>
        </div>

        <div className="social_row">
          <p>
            Would you like to dentify yourself through another application, or
            create a unique Concierge user identity?
          </p>
          <div className="social_icon_row">
            <FormContainer>
              <FormField
                label="Create Account"
                utterance={"Create Account"}
                className="book-appointment-button"
              />
            </FormContainer>
          </div>
        </div>

        <div className="modal_bottom_row">
          <p>
            You can capture and store your Concierge conversations, as well as
            records of your healthcare encounters at Veil Health, in a Secure
            Health Journal. Concierge can keep your journal, or you can choose
            to store and protect it yourself. When Concierge keeps your journal
            it can only access the content when you authorize it through you
            authentication.
          </p>
          <FormContainer>
            <FormField
              label="No Journal"
              utterance={"No Journal"}
              className="book-appointment-button"
            />
            <FormField
              label="I'll Keep My Journal"
              utterance={"I'll Keep My Journal"}
              className="book-appointment-button"
            />
            <FormField
              label="Let Concierge Keep My Journal"
              utterance={"Let Concierge Keep My Journal"}
              className="book-appointment-button"
            />
          </FormContainer>
        </div>
      </div>
    </>
  );
};
