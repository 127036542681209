export const setProvidersInfo = (payload: any) => {
  if (payload) {
    return {
      type: "ADD_PROVIDERS_INFO",
      payload: payload,
    };
  }
};

export const removeProvidersInfo = () => {
  return {
    type: "REMOVE_PROVIDERS_INFO",
  };
};

export const setProvidersLocations = (payload: any) => {
  if (payload) {
    return {
      type: "ADD_PROVIDERS_LOCATIONS",
      payload: payload,
    };
  }
};
