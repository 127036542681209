import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import validator from "validator";
import { TextField, DefaultButton } from "@fluentui/react";
import { usePatientProfile } from "../../hooks/patientProfile/usePatientProfile";
import {
  setLoggedInUserDemog,
  setUserInfo,
  userLogout,
} from "../../store/user/actions";
import { USER_NOT_CONFIRMED } from "../../constants/general-const";
import { Mixpanel } from "../../utils/mixpanel";

function SignIn({ handleLoading, handleAskForUserProfile }: any) {
  const dispatch = useDispatch();
  const {
    getPatientDemography,
    userLoginInfo,
    userForgotPassword,
    userConfirmForgotPassword,
    userConfirmSignUP,
  } = usePatientProfile();
  const initialState = {
    confirmPassword: "",
    code: "",
    email: "",
    newPassword: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialState);
  const unmounted = useRef(false);
  const [errorMsg, setErrorMsg] = useState("");
  const onValueChange = (event: any) => {
    const values = { ...formData, [event.target.name]: event.target.value };
    setFormData(values);
  };
  const [isActiveforgotPass, setIsActiveForgotPass] = useState(false);
  const [verification, setVerification] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("signin");
  const [codeGen, setCodeGen] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState({
    minutes: 0,
    seconds: 59,
  });

  const handleSubmit = () => {
    switch (submitStatus) {
      case "signin":
        if (validator.isEmail(formData.email) && formData.password) {
          handleLoading(true);
          Mixpanel.track("Sign-in", {
            outcome: "Login clicked",
            email: formData?.email?.toLowerCase(),
          });
          userLogin();
        } else {
          if (!validator.isEmail(formData?.email)) {
            setErrorMsg("Please enter Valid Email");
            Mixpanel.track("Sign-in-Failed", {
              outcome: "Please enter Valid Email",
              email: formData?.email?.toLowerCase(),
            });
            return;
          }
          setErrorMsg("Please fill out all fields");
          Mixpanel.track("Sign-in-Failed", {
            outcome: "Please fill out all fields",
            email: formData?.email?.toLowerCase(),
          });
        }
        break;
      case "forgot":
        if (validator.isEmail(formData.email)) {
          handleLoading(true);
          Mixpanel.track("Forgot-Password", {
            outcome: "Forgot Password clicked",
            email: formData?.email?.toLowerCase(),
          });
          forgotPassword();
        } else {
          setErrorMsg("Please fill valid email");
          Mixpanel.track("Forgot-Password-Failed", {
            outcome: "Email not Valid",
            email: formData?.email?.toLowerCase(),
          });
        }
        break;
      case "verify":
        if (
          formData.code &&
          formData.newPassword.length > 7 &&
          formData.newPassword === formData.confirmPassword
        ) {
          handleLoading(true);
          Mixpanel.track("Change-Password", {
            outcome: "Change Password clicked",
            email: formData?.email?.toLowerCase(),
          });
          userChangePassword();
        } else {
          if (!formData.code) {
            setErrorMsg(
              "Please check your code or passwords length should be greater than 7"
            );
            Mixpanel.track("Change-Password-Failed", {
              outcome: "Code not provided",
              email: formData?.email?.toLowerCase(),
            });
            return;
          }
          if (!(formData.newPassword.length > 7)) {
            setErrorMsg("Passwords length should be greater than 7");
            Mixpanel.track("Change-Password-Failed", {
              outcome: "Password Too Weak",
              email: formData?.email?.toLowerCase(),
            });
            return;
          }
          setErrorMsg("Password and Confirm Password needs to same");
          Mixpanel.track("Change-Password-Failed", {
            outcome: "Password and Confirm Password not same",
            email: formData?.email?.toLowerCase(),
          });
        }
        break;
    }
  };

  const userChangePassword = async () => {
    try {
      setErrorMsg("");
      await userConfirmForgotPassword({
        email: { S: formData.email.toLowerCase() },
        password: { S: formData.newPassword },
        code: { S: formData.code },
      });
      handleLoading(false);
      setFormData(initialState);
      setCodeGen(false);
      setIsActiveForgotPass(false);
      setSubmitStatus("signin");
      Mixpanel.track("Change-Password-Success", {
        outcome: "Success",
        email: formData?.email?.toLowerCase(),
      });
    } catch (error: any) {
      error.response && error.response.data.message
        ? setErrorMsg(error.response.data.message)
        : setErrorMsg("Please Try Again");
      Mixpanel.track("Change-Password-Failed", {
        outcome:
          error.response && error.response.data.mp_outcome
            ? error.response.data.mp_outcome
            : "Error Occured without message",
        email: formData?.email?.toLowerCase(),
      });
      handleLoading(false);
    }
  };

  const userLogin = async () => {
    let token;
    let securityTokenProPM;
    let securityTokenProEHR;
    try {
      const userLoggedIn = await userLoginInfo({
        email: { S: formData?.email?.toLowerCase() },
        password: { S: formData?.password },
      });
      token = userLoggedIn.data?.authorization?.AuthenticationResult?.IdToken;
      securityTokenProPM = userLoggedIn.data?.tokens?.practice_management;
      securityTokenProEHR = userLoggedIn.data?.tokens?.professional_ehr;

      if (token && securityTokenProPM) {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("PRO_PM_TOKEN", securityTokenProPM);
        sessionStorage.setItem("PRO_EHR_TOKEN", securityTokenProEHR);
        setPatientDemog(token);
      } else {
        Auth.signOut({ global: true }).then(() => {
          Mixpanel.track("Sign-Out", {
            outcome: "User Sign Out Missing Tokens",
            email: formData?.email?.toLowerCase(),
          });
          window.location.reload();
          sessionStorage.removeItem("token");
          dispatch(userLogout());
        });
      }
    } catch (error: any) {
      console.log(error.response.status);
      if (error.response && error.response.data.message) {
        if (error.response.data.status_code === "UserNotConfirmedException") {
          setVerification(true);
        }
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("Something wrong happened please try again.");
      }
      Mixpanel.track("Sign-in-Failed", {
        outcome:
          error.response && error.response.data.mp_outcome
            ? error.response.data.mp_outcome
            : "Error Occured without message",
        email: formData?.email?.toLowerCase(),
        status: error.response.status,
      });
      handleLoading(false);
    }
  };

  const setPatientDemog = (token: any) => {
    if (token) {
      let decodeUser: any = jwt_decode(token);
      Mixpanel.identify(decodeUser["custom:mrn"]);
      Mixpanel.track("Sign-in-Sucess", {
        outcome: "Success Sign in",
        email: formData?.email?.toLowerCase(),
        mrn: decodeUser["custom:mrn"],
      });
      getPatientDemography(decodeUser["custom:mrn"])
        .then((response) => {
          dispatch(setLoggedInUserDemog({ ...response.pm }));
          if (decodeUser) {
            dispatch(setUserInfo(decodeUser));
          }
          handleAskForUserProfile();
        })
        .catch((error) => {
          Mixpanel.track("PatientDemography-Failed", {
            outcome:
              error.response && error.response.data.mp_outcome
                ? error.response.data.mp_outcome
                : "Error Occured without message",
            email: formData?.email?.toLowerCase(),
            mrn: decodeUser["custom:mrn"],
          });
          setErrorMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : "We encountered an issue Please Retry"
          );
          handleLoading(false);
        });
    } else {
      Auth.signOut({ global: true }).then(() => {
        Mixpanel.track("Sign-Out", {
          outcome: "User Sign Out Missing Token",
          email: formData?.email?.toLowerCase(),
        });
        window.location.reload();
        sessionStorage.removeItem("token");
        dispatch(userLogout());
      });
    }
  };

  const forgotPassword = async () => {
    try {
      const user = await userForgotPassword({
        email: { S: formData.email.toLowerCase() },
      });
      setCodeGen(true);
      handleLoading(false);
      setSubmitStatus("verify");
      setErrorMsg(
        `Verification code has been sent to ${user.data.CodeDeliveryDetails.Destination}`
      );
      Mixpanel.track("Forgot-Password", {
        outcome: "Verification code sent",
        email: formData?.email?.toLowerCase(),
      });
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        if (error.response.data.status_code === "UserNotConfirmedException") {
          setVerification(true);
        }
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("Something wrong happened please try again.");
      }
      Mixpanel.track("Forgot-Password-Failed", {
        outcome:
          error.response && error.response.data.mp_outcome
            ? error.response.data.mp_outcome
            : "Error Occured without message",
        email: formData?.email?.toLowerCase(),
      });

      handleLoading(false);
    }
  };

  const resendConfirmationCode = async () => {
    try {
      handleLoading(true);
      const user = await userForgotPassword({
        email: { S: formData.email.toLowerCase() },
      });
      handleLoading(false);
      setIsTimerActive(true);
      setTimeout(() => {
        if (unmounted.current) return;
        setIsTimerActive(false);
        setTimer({
          minutes: 0,
          seconds: 59,
        });
      }, 60000);
      setErrorMsg(
        `Code resent successfully to ${user.data.CodeDeliveryDetails.Destination}`
      );
      Mixpanel.track("Forgot-Password-Resend", {
        outcome: "Verification code resent",
        email: formData?.email?.toLowerCase(),
      });
    } catch (err: any) {
      Mixpanel.track("Forgot-Password-Resend-Failed", {
        outcome:
          err.response && err.response.data.mp_outcome
            ? err.response.data.mp_outcome
            : "Error Occured without message",
        email: formData?.email?.toLowerCase(),
      });
      setErrorMsg(
        err.response && err.response.data.message
          ? err.response.data.message
          : "Please try again"
      );
      handleLoading(false);
    }
  };

  const handleUserConfirmCode = async () => {
    try {
      if (validator.isEmail(formData.email)) {
        handleLoading(true);
        const user = await Auth.resendSignUp(formData.email);
        handleLoading(false);
        setIsTimerActive(true);
        setTimeout(() => {
          setIsTimerActive(false);
          setTimer({
            minutes: 0,
            seconds: 59,
          });
        }, 60000);
        setErrorMsg(
          `Code resent successfully to ${user.CodeDeliveryDetails.Destination}`
        );
        Mixpanel.track("Sign-In-User-UnConfirmed", {
          outcome: "Verification code resent",
          email: formData?.email?.toLowerCase(),
        });
      }
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        setErrorMsg(error.response.data.message);
        Mixpanel.track("Sign-In-User-UnConfirmed-Failed", {
          outcome: error.response.data.mp_outcome
            ? error.response.data.mp_outcome
            : "Error Occured without message",
          email: formData?.email?.toLowerCase(),
        });
      } else {
        setErrorMsg("please try again");
        Mixpanel.track("Sign-In-User-UnConfirmed-Failed", {
          outcome: "Error Occured without message",
          email: formData?.email?.toLowerCase(),
        });
      }
      handleLoading(false);
    }
  };

  const handleVerification = async () => {
    handleLoading(true);
    if (validator.isEmail(formData.email) && formData?.code) {
      try {
        setErrorMsg("");
        await userConfirmSignUP({
          email: { S: formData.email.toLowerCase() },
          code: { S: formData.code },
        });
        Mixpanel.track("User-Confirmed", {
          outcome: "User Confirmed now",
          email: formData?.email?.toLowerCase(),
        });
        if (submitStatus === "signin") {
          userLogin();
        } else if (submitStatus === "forgot") {
          forgotPassword();
        }
      } catch (err: any) {
        err.response && err.response.data.message
          ? setErrorMsg(err.response.data.message)
          : setErrorMsg("Something wrong happened please try again");
        Mixpanel.track("User-Confirmed-Failed", {
          outcome:
            err.response && err.response.data.mp_outcome
              ? err.response.data.mp_outcome
              : "Error Occured without message",
          email: formData?.email?.toLowerCase(),
        });
        handleLoading(false);
        return;
      }
    } else {
      setErrorMsg("Please Validate the data provided");
      Mixpanel.track("User-Confirmed-Failed", {
        outcome: "Email or code mising",
        email: formData?.email?.toLowerCase(),
      });
      handleLoading(false);
    }
  };

  useEffect(() => {
    if (isTimerActive) {
      let myInterval = setInterval(() => {
        if (timer.seconds > 0) {
          setTimer({
            ...timer,
            seconds: timer.seconds - 1,
          });
        }
        if (timer.seconds === 0) {
          if (timer.minutes === 0) {
            clearInterval(myInterval);
          } else {
            setTimer({
              minutes: timer.minutes - 1,
              seconds: 59,
            });
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [isTimerActive, timer]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <>
      <div className="signIn-container">
        {errorMsg ? <p className="form-error-text">{errorMsg}</p> : null}
        <form className="signIn-form">
          <div className="email-field-container">
            <TextField
              label="Email"
              className="email-field"
              placeholder="Enter email address"
              name="email"
              value={formData.email}
              onChange={onValueChange}
            />
          </div>
          {!isActiveforgotPass && !verification ? (
            <>
              <div className="password-field-container">
                <TextField
                  label="Password"
                  type="password"
                  canRevealPassword
                  revealPasswordAriaLabel="Show password"
                  placeholder="Enter Password"
                  className="password-field"
                  name="password"
                  value={formData.password}
                  onChange={onValueChange}
                />
              </div>
              <div className="forgot-password-container">
                <p
                  className="forgot-password-text"
                  onClick={() => {
                    setIsActiveForgotPass(true);
                    setSubmitStatus("forgot");
                    setErrorMsg("");
                  }}
                >
                  Forgot Password ?
                </p>
              </div>
            </>
          ) : null}
          {codeGen ? (
            <>
              <div className="verification-fields-container">
                <TextField
                  label="Verification Code"
                  className="verification-code-field"
                  placeholder="Verification Code"
                  name="code"
                  value={formData.code}
                  onChange={onValueChange}
                />
                <DefaultButton
                  disabled={isTimerActive}
                  text={
                    isTimerActive
                      ? `${
                          timer.minutes >= 10
                            ? timer.minutes
                            : `0${timer.minutes}`
                        } : ${
                          timer.seconds >= 10
                            ? timer.seconds
                            : `0${timer.seconds}`
                        }`
                      : "Resend Code"
                  }
                  className="verification-button"
                  onClick={resendConfirmationCode}
                />
              </div>
              <div className="reset-pass-fields-container">
                <TextField
                  label="New Password"
                  type="password"
                  canRevealPassword
                  revealPasswordAriaLabel="Show password"
                  className="newPass-field"
                  placeholder="New Password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={onValueChange}
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  canRevealPassword
                  revealPasswordAriaLabel="Show password"
                  className="confirmPass-field"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={onValueChange}
                />
              </div>
            </>
          ) : null}
          {verification ? (
            <>
              <div className="verification-fields-container">
                <TextField
                  placeholder="Enter verification code"
                  className="verification-code-field"
                  name="code"
                  value={formData?.code}
                  onChange={onValueChange}
                />
                <DefaultButton
                  disabled={isTimerActive}
                  text={
                    isTimerActive
                      ? `${
                          timer.minutes >= 10
                            ? timer.minutes
                            : `0${timer.minutes}`
                        } : ${
                          timer.seconds >= 10
                            ? timer.seconds
                            : `0${timer.seconds}`
                        }`
                      : "Resend Code"
                  }
                  className="verification-button"
                  onClick={handleUserConfirmCode}
                />
              </div>
            </>
          ) : null}
          <div className="signIn-button-container">
            <DefaultButton
              text={
                !isActiveforgotPass
                  ? verification
                    ? "Verify"
                    : "Login"
                  : codeGen
                  ? "Submit"
                  : "Reset"
              }
              className="signIn-button"
              onClick={verification ? handleVerification : handleSubmit}
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default SignIn;
