import React, { FunctionComponent } from "react";
import { withScrollAndFocus } from "../../hoc/withScrollAndFocus";
import { IMessageProps } from "./types";

export const BaseChatMessages: FunctionComponent<IMessageProps> = ({
  children,
  className,
  rootRef,
}) => {
  return (
    <div className={className} ref={rootRef}>
      {children}
    </div>
  );
};

export const ChatMessage = withScrollAndFocus(BaseChatMessages);
