import { Client } from "../../clients/Client";
import { IClientOperationResponse } from "../../clients/types";
import {
  URL_LIST_APPOINTMENTS,
  URL_POST_APPOINTMENT,
  URL_PUT_APPOINTMENT,
  URL_GET_LOCATION_LIST,
  URL_GET_TIME_SLOTS,
  URL_GET_PROVIDER_SLOTS,
} from "../../constants/url";

class UpcomingAppointment {
  public Client: Client;

  constructor() {
    this.Client = new Client();
  }

  public ListOfUpcomingAppointments = async (userId: string) => {
    let request: IClientOperationResponse;
    request = this.Client.ListData(userId, URL_LIST_APPOINTMENTS);
    return await request.axiosPromise;
  };
  public storeUpcomingAppointment = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.storeServerData(payload, URL_POST_APPOINTMENT);
    return await request.axiosPromise;
  };
  public rescheduleAppointment = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.updateServerData(
      payload,
      URL_PUT_APPOINTMENT,
      `${payload.id.S}/rescheduled`
    );
    return await request.axiosPromise;
  };
  public cancelAppointment = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.updateServerData(
      payload,
      URL_PUT_APPOINTMENT,
      `${payload.id.S}/cancelled`
    );
    return await request.axiosPromise;
  };

  public getProviderSlotsInfo = async (payload: any) => {
    let request: IClientOperationResponse;

    var queryWithoutNull = Object.keys(payload)
      .filter((k) => payload[k] != null)
      .reduce((a, k) => ({ ...a, [k]: payload[k] }), {});
    var queryString = Object.keys(queryWithoutNull)
      .map((key) => key + "=" + payload[key])
      .join("&");
    request = this.Client.GetListDataWithTokens(
      `${URL_GET_PROVIDER_SLOTS}?${queryString}`
    );
    return await request.axiosPromise;
  };

  public getLocationList = async () => {
    let request: IClientOperationResponse;
    request = this.Client.GetListDataWithTokens(URL_GET_LOCATION_LIST);
    return await request.axiosPromise;
  };
  public getTimeSlots = async (payload: any) => {
    let request: IClientOperationResponse;
    var queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    request = this.Client.GetListDataWithTokens(
      `${URL_GET_TIME_SLOTS}?${queryString}`
    );
    return await request.axiosPromise;
  };
}

const appointment = new UpcomingAppointment();

export const useUpcomingAppointment = () => {
  return {
    ListOfUpcomingAppointments: appointment.ListOfUpcomingAppointments,
    storeUpcomingAppointment: appointment.storeUpcomingAppointment,
    rescheduleAppointment: appointment.rescheduleAppointment,
    cancelAppointment: appointment.cancelAppointment,
    getLocationList: appointment.getLocationList,
    getTimeSlots: appointment.getTimeSlots,
    getProviderSlotsInfo: appointment.getProviderSlotsInfo,
  };
};
