import { IDropdownOption } from "@fluentui/react";

export const PRO_PM_TOKEN_NAME = "PRO_PM_TOKEN";
export const PRO_EHR_TOKEN_NAME = "PRO_EHR_TOKEN";
//
export const COUNTRY_CODE = "+1";
export const DEFAULT_CONTACT = "970-926-6340";
export const IS_COMPLEX_PATIENT = "True";
export const FETCH_PROVIDER_DATA_DAYS = 120;

//Book Appointment constants
export const COST_ESTIMATES = "200";
export const REFERING_DOCTOR = "";
export const COVERAGE_TYPE = "M";
export const COMMENTS = "test";

export const BOOK_APPOINTMENT_STATUS = "upcoming";

//RESCHEDULE appointment constants
export const RESCHEDULE_REASON = "Reschedule Reason is testing";
export const RESCHEDULE_REASON_FOR_STATUS_CHANGE = 1;
export const RESCHEDULE_APPOINTMENT_STATUS = "rescheduled";
export const RESCHEDULE_ALLSCRIPTS_APPOINTMENT_STATUS = "S";

//CANCEL

export const CANCEL_APPOINTMENT_COMMENT = "I have canceled it for testing ";
export const CANCEL_REASON_FOR_STATUS_CHANGE = 3;
export const ALLSCRIPTS_APPOINTMENT_STATUS = "X";
export const CANCEL_REASON: IDropdownOption[] = [
  { key: "1", text: "I need to reschedule at a future time" },
  { key: "2", text: "Booked in Error" },
  { key: "3", text: "I no longer need this appointment" },
];

//PROVIDER PAYLOAD
export const PROVIDER_PAYLOAD_ACTION = "GetResources";
export const PROVIDER_PAYLOAD_APPNAME = "Confirm";
//DATE TIME Format
export const MMDDYYYY_FORMAT = "MM/DD/YYYY";
export const MMDDYYYY_WITH_HYPHEN_FORMAT = "MM-DD-YYYY";
export const YYYYMMDD_FORMAT = "YYYY/MM/DD";
export const MMDoYYYYHHmmss_FORMAT = "MM/DD/YYYY HH:mm:ss";
export const MMMMDoYYYYhhmmssa_FORMAT = "MMMM Do YYYY, h:mm:ss a";
export const MMMMDoYYYYhmma_FORMAT = "MMMM Do YYYY, h:mm a";
export const MMMMDoYYYY_FORMAT = "MMMM Do YYYY";
export const MMMMDo_FORMAT = "MMMM Do";
export const MMMDo_FORMAT = "MMM Do";
export const ddddMMMMDoYYYY_FORMAT = "dddd MMMM Do, YYYY";
export const hmma_FORMAT = "h:mm a";
export const HHmm_FORMAT = "HH:mm";
export const MMDDhmma_FORMAT = "MM/DD h:mm a";
export const MMDD_FORMAT = "MM/DD";

export const DAYS = "days";
export const MONTHS = "months";
export const YEARS = "years";

//Messages
export const ALREADY_BOOKED_ERROR =
  "This slot is already booked. Please try booking for any other slot.";
export const NO_SLOT_FOUND_ERROR = "No Time Slot Found";
// export const NO_SLOT_PICK_ANOTHER_MESSAGE =
//   "There is no slot available for the mentioned provider in this Week, choose another Week or Provider";
export const NO_SLOT_PICK_ANOTHER_MESSAGE =
  "There is no slot available for the mentioned provider, choose another Provider";
export const NO_SLOT_24_HOUR_ERROR =
  "No slot available within 24 hrs for any provider, Please choose another slot.";
export const PICK_ANOTHER_PROVIDER = "Pick From Available Providers";
export const SELECT_ANOTHER_TIME = "Select From Available Slots to visit";

//LOGIN ERROR
export const USER_NOT_CONFIRMED = "user is not confirmed";
export const USER_NOT_EXIST = "user does not exist";
export const USER_INCORRECT_PASSWORD = "incorrect username or password";

// //504 Timeout Errors
// export const SIGN_UP_504 = `The verification system isn’t responding. This may be an intermittent issue. Please try again in one minute`;
// export const LOGIN_504 = `The verification system isn’t responding. This may be an intermittent issue. Please try again in one minute`;
// export const VISITS_504 = `The appointment system isn’t responding. This may be an intermittent issue. Please try again in one minute.`;
// export const FIND_SLOTS_504 = `The scheduling system isn’t responding. This may be an intermittent issue. Please try again in one minute.`;
// export const BOOK_APPOINTMENT_504 = `The scheduling system isn’t responding. This may be an intermittent issue. Please try again in one minute.`;
// export const CANCEL_RESCHEDULE_APPOINTMENT_504 = `The scheduling system isn’t responding. This may be an intermittent issue. Please try again in one minute.`;
