import { Client } from "../../clients/Client";
import { IClientOperationResponse } from "../../clients/types";
import {
  URL_GET_PATIENT_DEMOGRAPHY,
  URL_USER_LOGIN,
  URL_USER_REGISTER,
  URL_USER_FORGOT_PASSWORD,
  URL_USER_CONFIRM_SIGNUP,
  URL_USER_CONFIRM_FORGOT_PASSWORD,
  URL_GET_PROFILE_INFO,
} from "../../constants/url";

class PatientProfile {
  public Client: Client;

  constructor() {
    this.Client = new Client();
  }

  public getPatientDemography = async (patient_number: string) => {
    let request: IClientOperationResponse;
    request = this.Client.GetPatientData(
      `?patient_number=${patient_number}`,
      URL_GET_PATIENT_DEMOGRAPHY
    );
    return await request.axiosPromise;
  };

  public userRegister = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.userLoginRegister(payload, URL_USER_REGISTER);
    return await request.axiosPromise;
  };

  public userLoginInfo = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.userLoginRegister(payload, URL_USER_LOGIN);
    return await request.axiosPromise;
  };

  public getUserProfileInfo = async (payload: any) => {
    let request: IClientOperationResponse;
    var queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    request = this.Client.GetListDataWithTokens(
      `${URL_GET_PROFILE_INFO}?${queryString}`
    );
    return await request.axiosPromise;
  };

  public userForgotPassword = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.userLoginRegister(payload, URL_USER_FORGOT_PASSWORD);
    return await request.axiosPromise;
  };
  public userConfirmForgotPassword = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.userLoginRegister(
      payload,
      URL_USER_CONFIRM_FORGOT_PASSWORD
    );
    return await request.axiosPromise;
  };
  public userConfirmSignUP = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.userLoginRegister(payload, URL_USER_CONFIRM_SIGNUP);
    return await request.axiosPromise;
  };
}

const profile = new PatientProfile();

export const usePatientProfile = () => {
  return {
    getPatientDemography: profile.getPatientDemography,
    userRegister: profile.userRegister,
    userLoginInfo: profile.userLoginInfo,
    userForgotPassword: profile.userForgotPassword,
    userConfirmForgotPassword: profile.userConfirmForgotPassword,
    userConfirmSignUP: profile.userConfirmSignUP,
    getUserProfileInfo: profile.getUserProfileInfo,
  };
};
