let messages: object[] = [];

const appointments = (state = [...messages], action: any) => {
  switch (action.type) {
    case "ADD_APPOINTMENT":
      //console.log(action.type);
      return [...state, action.payload];
    case "REMOVE_APPOINTMENT":
      return [];
    default:
      return state;
  }
};
export default appointments;
