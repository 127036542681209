import React, { FunctionComponent } from "react";

export const FormContainer: FunctionComponent<{ className?: string }> = (
  props
) => {
  const { className } = props;

  return (
    <div className={`${className || ""} formContainer`}>{props.children}</div>
  );
};
