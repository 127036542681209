import axios from "axios";
import { Auth } from "aws-amplify";
import { userLogout } from "../store/user/actions";
import store from "../store";
import { Mixpanel } from "../utils/mixpanel";

// intercepting responses
const { dispatch } = store;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      await Auth.signOut({ global: true });
      window.location.reload();
      sessionStorage.removeItem("token");
      dispatch(userLogout());
      Mixpanel.track("Sign-Out", {
        outcome: "User Sign Out Due to Error",
      });
    }
    return Promise.reject(error);
  }
);
