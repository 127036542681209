import React, { useCallback, useState } from "react";
import { DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import {
  DAYS,
  ddddMMMMDoYYYY_FORMAT,
  MMDDYYYY_FORMAT,
  NO_SLOT_PICK_ANOTHER_MESSAGE,
  YYYYMMDD_FORMAT,
} from "../../../constants/general-const";
import { convertDateTimeTohhmmampm } from "../../../utils/helpers";
import { useSelector } from "react-redux";
const moment = require("moment");

export default function TimeSlot(props: any) {
  const { onDismiss, onValueChange, availableDateTimeSlots, changeWeek } =
    props;

  //console.log(availableDateTimeSlots);

  //console.log(props.appointmentType);
  const [activeTabs, setActiveTabs] = useState<string[]>([]);
  const providerLocations = useSelector(
    (state: any) => state.providersInfo.providersLocations.locations
  );
  const toggleShowSlot = (key: string) => {
    activeTabs.includes(key)
      ? setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== key))
      : setActiveTabs((oldArray) => [...oldArray, key]);
  };

  const handleClick = (e: any, date: any, item: any) => {
    e.preventDefault();
    setActiveTabs([]);
    //console.log(e.target.textContent);
    //console.log(date);
    let formatedDate = moment(date).format(YYYYMMDD_FORMAT);
    formatedDate = formatedDate + " " + e.target.textContent;
    let datetime = new Date(formatedDate);
    //console.log(datetime);
    onValueChange("", "appointment_date_time", {
      appointment_date_time: datetime,
      department: item.Scheduling_Department.trim(),
      location: item.Scheduling_Location.trim(),
      forced: item.Forced,
      // location_details: item.SchedLocName.trim(),
    });
    onDismiss && onDismiss();
  };

  const formatLocations = useCallback(
    (loc: any) => {
      const foundLoc =
        providerLocations &&
        providerLocations.length &&
        providerLocations.find((itm: any) => itm.Abbreviation === loc);
      let locationDetails = "";
      if (foundLoc) {
        locationDetails = foundLoc.Description;
      } else {
        locationDetails = loc;
      }
      return locationDetails;
    },
    [providerLocations]
  );

  const renderDateTime = (dateLoc: any) => {
    const datelocsplit = dateLoc.split("|");
    const date = datelocsplit[0];
    const loc = datelocsplit[1];
    return (
      <div className="dateTimeContainer" key={dateLoc}>
        <div className="dateContainer" onClick={() => toggleShowSlot(dateLoc)}>
          <IconButton
            iconProps={{
              iconName: activeTabs.includes(dateLoc)
                ? "CollapseContentSingle"
                : "ExploreContentSingle",
            }}
            ariaLabel="Toggle Show slots"
          />

          <h5 className="date">{`${moment(date).format(
            ddddMMMMDoYYYY_FORMAT
          )} (in ${formatLocations(loc)})`}</h5>
        </div>
        {activeTabs.includes(dateLoc) ? (
          <div className="timeContainer">
            {availableDateTimeSlots[dateLoc].map((item: any, index1: any) => {
              const formatedStartTime = convertDateTimeTohhmmampm(
                item.Start_Time
              );
              return (
                <button
                  id="time"
                  key={`${index1}${item.Start_Time}-${dateLoc}`}
                  className="timeBtn"
                  onClick={(e) => handleClick(e, date, item)}
                >
                  {formatedStartTime}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div className="timeSlotContainer">
      {Object.keys(availableDateTimeSlots).includes("noSlot") ? (
        <p>{availableDateTimeSlots.noSlot}</p>
      ) : (
        Object.keys(availableDateTimeSlots).map((date: any, index) =>
          renderDateTime(date)
        )
      )}
      <div className="timeSlotFooter">
        <DefaultButton
          text="<Prev Week"
          className="weekButton"
          onClick={() => changeWeek("prevWeek")}
          disabled={
            Object.keys(availableDateTimeSlots)[0] !== "noSlot" &&
            moment(
              Object.keys(availableDateTimeSlots)[0],
              MMDDYYYY_FORMAT
            ).diff(moment(new Date(), MMDDYYYY_FORMAT), DAYS) <= 0
              ? true
              : false
          }
        />
        <DefaultButton
          text="Next Week>"
          onClick={() => changeWeek("nextWeek")}
          className="weekButton"
        />
      </div>
    </div>
  );
}
