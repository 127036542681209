import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useBoolean } from "@fluentui/react-hooks";
import { BotResponse } from "../botResponse/BotResponse";
import { AnotherTime } from "../book-cancel-appointment/anotherTime/AnotherTime";
import { Iappointemet } from "./types";
import {
  COST_ESTIMATES,
  COVERAGE_TYPE,
  MMDDYYYY_FORMAT,
  MMMMDoYYYYhmma_FORMAT,
  REFERING_DOCTOR,
} from "../../../constants/general-const";
import { Mixpanel } from "../../../utils/mixpanel";
import { useBookAppointment } from "../hooks/useBookAppointment";
import { useHandleSlots } from "../hooks/usehandleSlots";
import {
  convertISOdatetime,
  formatLocations,
  findProvider,
} from "../../../utils/chatMessages";
import { ConfirmBookingModal } from "../../modals/confirmBooking/ConfirmBookingModal";

const FindMoreSlotBase = (
  props: any & {
    messageIdx: number;
    conversationIdx?: number;
  }
) => {
  const patientId = useSelector(
    (state: any) => state.isLoggedIn.patientDemog.Patient_ID
  );
  const patientDetails = useSelector((state: any) => state.isLoggedIn.info);
  const unmounted = useRef(false);
  const [formData, setFormData] = useState<Iappointemet | undefined>();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const providerLocations = useSelector(
    (state: any) => state.providersInfo.providersLocations.locations
  );
  const { handleBookAppointment } = useBookAppointment(
    props.data.provider_list,
    props.utterance
  );
  const {
    grossData,
    availableDateTimeSlots,
    prevDisable,
    nextDisable,
    isLoading,
    changeWeek,
    firstWeekData,
  } = useHandleSlots(
    props.data.provider_list,
    props.utterance,
    props.data.dontFetchMoreSlots
  );

  //Function called when Visit reason is Required
  const createAppointmentPayloadWithReason = (
    visitReason: string | undefined | null
  ) => {
    let comments = "";
    let forcedSlotInfo = {};
    // Set forced slot info
    if (formData?.saveAsAppointment_type) {
      if (
        !formData?.appointment_type?.includes(formData?.saveAsAppointment_type)
      ) {
        forcedSlotInfo = {
          forced: true,
        };
      }
    }
    // // Set visit reason comments
    // if (props.data.visitReasonRequired && visitReason) {
    //   comments = visitReason;
    // } else if (!props.data.visitReasonRequired) {
    //   comments = props.utterance;
    // }

    //create payload
    const payload = {
      ...forcedSlotInfo,
      comments: props.utterance,
      provider: formData?.provider,
      appointment_date_time: formData?.appointment_date_time as Date,
      appointment_type: formData?.appointment_type,
      department: formData?.department,
      duration: formData?.duration,
      location: formData?.location,
      forced: formData?.forced,
      saveAsAppointment_type: formData?.saveAsAppointment_type,
      cost_estimate: COST_ESTIMATES,
      refer_dr: REFERING_DOCTOR,
      coverage_type: COVERAGE_TYPE,
      patient_id: patientId,
      service_id: props.data.service_id,
    };
    const payloadWithIsoDate = convertISOdatetime(payload);
    hideModal();
    handleBookAppointment(payloadWithIsoDate);
  };

  const providerInfo: any = useMemo(
    () => findProvider(formData?.provider, props.data.provider_list),
    [formData?.provider, findProvider]
  );

  const onValueChange = useCallback(
    (event: any, field: string, item?: any) => {
      if (field === "appointment_date_time") {
        setFormData({
          ...formData,
          ...item,
        });
        Mixpanel.track("Book-Appointment", {
          outcome: "Book Appointment Clicked",
          email: patientDetails.email,
          mrn: patientDetails["custom:mrn"],
          provider: formData?.provider,
          location: formData?.location,
          dateTime: formData?.appointment_date_time
            ? moment(new Date(formData?.appointment_date_time)).format(
                MMMMDoYYYYhmma_FORMAT
              )
            : "",
          visitReason: formData?.reason,
          appointmentType: formData?.saveAsAppointment_type,
        });
        showModal();
      } else if (item && field !== "appointment_date_time") {
        const values = { ...formData, [field]: item.key };
        setFormData(values);
      }
    },
    [formData]
  );

  useEffect(() => {
    if (
      props.data.provider_info &&
      props.data.timeSlots &&
      props.data.timeSlots.length
    ) {
      const key1 = props.data.provider_info.Abbreviation;
      const beginDate = props.data.from_date;
      const endDate = moment(new Date(props.data.to_date)).format(
        MMDDYYYY_FORMAT
      );
      const data = props.data.timeSlots;
      grossData.current[key1] = { beginDate, endDate, data };
      setFormData((prev) => ({
        ...prev,
        appointment_type: props.data.provider_info.AppointmentType,
        saveAsAppointment_type: props.data.provider_info.ForcedAppointmentType,
        provider: props.data.provider_info.Abbreviation,
        duration: props.data.provider_info.Duration,
      }));
      firstWeekData(props.data.provider_info.Abbreviation);
    }
  }, []);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <BotResponse
      className={props.additionalClasses}
      message={props.Message}
      description={props.SubpromptHTML}
    >
      <div className="drug_description">
        {providerInfo
          ? `Doctor ${providerInfo.FirstName} ${providerInfo.LastName}, ${providerInfo.Suffix} (${providerInfo.SpecialtyName})
             has following slots available to address "${props.utterance}"`
          : null}
      </div>
      <div className="formContainer">
        <AnotherTime
          onValueChange={onValueChange}
          providerName={formData?.provider}
          providerList={props.data.provider_list}
          appointment_type={formData?.appointment_type}
          duration={formData?.duration}
          changeWeek={changeWeek}
          isLoading={isLoading}
          availableDateTimeSlots={availableDateTimeSlots}
          nextDisable={nextDisable}
          prevDisable={prevDisable}
        />
      </div>
      {isModalOpen ? (
        <ConfirmBookingModal
          isModalOpen={isModalOpen}
          // askForVisitReason={props.data.visitReasonRequired}
          handleModalSubmit={createAppointmentPayloadWithReason}
          closeModal={hideModal}
          selectedSlot={formData?.appointment_date_time}
          providerInfo={providerInfo}
          location={formatLocations(formData?.location, providerLocations)}
          userUtternace={props.utterance}
        />
      ) : null}
    </BotResponse>
  );
};

export const FindMoreSlots = React.memo(FindMoreSlotBase);
