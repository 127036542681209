import React, { useState } from "react";
import { BotResponse } from "../botResponse/BotResponse";
import { FormContainer } from "../appointmentForms/FormContainer";
import { FormField } from "../appointmentForms/FormField";
import { IAppointmentBotMessage } from "../../../hooks/appointmentBot/bot/types";
import { Modal } from "@fluentui/react/lib/Modal";
import { UserProfile } from "../appointmentForms/userProfile";

export const CreditCard = (
  props: IAppointmentBotMessage & { messageIdx: number }
) => {
  // const [modal, setModal] = useState<AuthModalPaneTypes>(AuthModalPaneTypes.DEFAULT)
  const [isModalOpen, setIsOpen] = useState<boolean>(false);
  const onClick = () => {
    setIsOpen(false);
  };
  return (
    <BotResponse message={props.Message} description={props.description}>
      <div className="formContainer">
        <Modal
          // onDismiss={dismissModal}
          isOpen={isModalOpen}
          containerClassName="register_modal"
        >
          <UserProfile onDismissModal={onClick} />
        </Modal>
        <p className="credit-card-table-caption">
          To complete booking your appointment can you please fill in the
          following:
        </p>
        <div className="credit-card-table-container">
          <table className="credit-card-table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>
                  <input type="text" />
                </th>
                <th>Last Name</th>
                <th>
                  <input type="text" />
                </th>
              </tr>
              <tr>
                <th>Mobile Phone</th>
                <th>
                  <input type="text" />
                </th>
                <th>Home Phone</th>
                <th>
                  <input type="text" />
                </th>
              </tr>
              <tr>
                <th>Email</th>
                <th colSpan={2}>
                  <input type="text" />
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <FormContainer className="credit-card-form-container">
          <FormField
            label="Submit"
            utterance={"Contact Information Submitted"}
            option={"contactFormSubmitted"}
            className="book-appointment-button"
          />
          <input
            type="button"
            value="Register"
            onClick={() => setIsOpen(!isModalOpen)}
            className="book-appointment-button"
          />
        </FormContainer>
      </div>
    </BotResponse>
  );
};
